.regiter-button {
    background: #ffffff;
    color: #262626;
    border-radius: 50px;
    font-size: 12px;
    width: 180px;
    height: 36px;
    outline: none;
    border: none;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 600;
}

.regiter-button:hover {
    outline: none;
    background: #262626;
    color: #ffff;
}

.regiter-button:active {
    outline: none;
}

.regiter-button-wrap {
    text-align: center;
    /* margin-top: 4rem; */
}

@media (max-width: 768px) {
    img {
        width: 72%;
    }
    .grid-aliment-wrap {
        margin-left: 0;
    }
}

.text-prize {
    line-height: 60px;
    letter-spacing: -0.02em;
    font-size: 35px;
    font-weight: 600;
}

.faq-wrap {
    margin: 150px 0 100px;
    font-family: 'Poppins', sans-serif !important;
}

.accordion-uderline-wrap {
    font-weight: 700;
    font-size: 25px;
    color: white;
}

.accordion {
    background-color: transparent;
    color: #777777;
    cursor: pointer;
    width: 100%;
    border: 1px dashed #cc1d2a;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
    margin: 10px 0 10px;
    text-indent: 10px;
    padding: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.active-accordion,
.accordion:hover {
    border-style: dashed;
    border-color: #cc1d2a;
    outline: none !important;
}

button:focus {
    outline: none !important;
}

.accordion:after {
    content: '\002B';
    color: #cc1d2a;
    font-weight: normal;
    float: right;
    margin-right: 10px;
    font-size: 25px;
}

.active-accordion:after {
    content: '\00D7';
}

.panel {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    font-family: Poppins;
    color: #efefef;
}

.panel a {
    color: #efefef;
}

.sub-heading {
    padding: 20px 0 20px;
}

.accordion-link {
    color: #f9af32 !important;
    text-decoration: none;
}

.messenger {
    text-align: center;
}

.faq {
    font-size: 1.25rem;
}