.timer-container {
    width: 100%;
    background-image: url('/images/timer-banner.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
    .black-overlay {
      // background-color: rgba(255, 255, 255, 0.5);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
    }
    .timer {
      padding: 30px 0 30px;
      margin: 0 auto;
      font-family: 'Poppins';
      max-width: 960px;
      text-align: center;
      .counting {
        color: rgb(243, 243, 243);
        font-size: 2.8em;
        margin-bottom: 40px;
      }
      .counting-wrap {
        margin-bottom:5px;
      }
      .counting-color-wrap {
        color:black;
      }
      .timer-block {
        .timer-p {
          font-size: 2.2em;
          color: rgb(5, 5, 5);
          font-weight: 800;
        }
        .timer-p1 {
          font-size: 2.2em;
          color: rgb(8, 8, 8);
          font-weight: 500;
        }
      }
    }
  }
