@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
.regiter-button {
    background: #ffffff;
    color: #262626;
    border-radius: 50px;
    font-size: 12px;
    width: 180px;
    height: 36px;
    outline: none;
    border: none;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 600;
}

.regiter-button:hover {
    outline: none;
    background: #262626;
    color: #ffff;
}

.regiter-button:active {
    outline: none;
}

.regiter-button-wrap {
    text-align: center;
    /* margin-top: 4rem; */
}

.animated {
    font-size: 100px;
    font-family: Helvetica, Arial, sans-serif;
}

.text-prize {
    line-height: 60px;
    letter-spacing: 3px;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Roboto Mono';
}

.mono {
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
}

.single-post .post-item .post-item-description {
    text-align: center;
    padding: 26px 5rem 1rem 5rem;
}

.giflogo {
    width: 40%;
    height: 50%;
}

.dashed-broder-wrap {
    border-style: dashed;
    border-color: rgba(204, 29, 42, 1);
    padding: 10px 45px;
}

.banner-broder-wrap {
    border-right: 3px dashed rgba(204, 29, 42, 1);
    padding-right: 1rem;
}

.text-tag {
    padding-left: 1rem;
}

.body-inner-hed {
    font-weight: bold;
    color: #212529;
    padding: 1rem 0 1rem 0;
}

.register-button {
    padding-top: 2.5rem;
}

.button-wrap {
    background: linear-gradient(90deg, #cc1d2a 15%, #911467 30%, #690f92 45%, #3907c3 60%, blue 80%);
    text-transform: uppercase;
    font-family: 'Bebas Neue';
    font-size: 24px;
    color: white;
    text-align: center;
    width: 21rem;
    height: 4rem;
    border: 0;
    letter-spacing: 1px;
    font-weight: 600;
}

.animated {
    font-family: 'Helvetica';
}

.cyberdome-wrap {
    padding-top: 2.5rem;
}

.product .product-image>a,
.product .product-image img .product-hover-wrap:hover {
    opacity: o;
}

.gray-sponsor {
    filter: none;
    -webkit-filter: grayscale(0%);
}

.gray-sponsor:hover {
    filter: grayscale(100%);
}

.text-rotator {
    font-family: Helvetica, Arial, sans-serif;
}


/*new style*/

.glitch::before {
    left: 3px;
    text-shadow: -2px 0 red;
    animation-name: glitch-animation-1;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: reverse-alternate;
}

.glitch::after {
    left: -3px;
    text-shadow: -2px 0 blue;
    animation-name: glitch-animation-2;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: reverse-alternate;
}

@media (max-width: 768px) {
    img {
        width: 72%;
    }
    .grid-aliment-wrap {
        margin-left: 0;
    }
    .dashed-broder-wrap {
        border-style: none;
        border-color: none;
        padding: 0;
    }
    .single-post .post-item .post-item-description {
        padding: 26px 1rem 1rem 1rem;
    }
}

@keyframes glitch-animation-1 {
    0% {
        clip: rect(112px, 350px, 111px, 30px);
    }
    5% {
        clip: rect(57px, 350px, 127px, 30px);
    }
    10% {
        clip: rect(28px, 350px, 146px, 30px);
    }
    15% {
        clip: rect(77px, 350px, 13px, 30px);
    }
    20% {
        clip: rect(144px, 350px, 135px, 30px);
    }
    25% {
        clip: rect(20px, 350px, 24px, 30px);
    }
    30% {
        clip: rect(147px, 350px, 130px, 30px);
    }
    35% {
        clip: rect(29px, 350px, 34px, 30px);
    }
    40% {
        clip: rect(74px, 350px, 20px, 30px);
    }
    45% {
        clip: rect(117px, 350px, 140px, 30px);
    }
    50% {
        clip: rect(148px, 350px, 128px, 30px);
    }
    55% {
        clip: rect(145px, 350px, 34px, 30px);
    }
    60% {
        clip: rect(79px, 350px, 128px, 30px);
    }
    65% {
        clip: rect(18px, 350px, 17px, 30px);
    }
    70% {
        clip: rect(61px, 350px, 95px, 30px);
    }
    75% {
        clip: rect(99px, 350px, 46px, 30px);
    }
    80% {
        clip: rect(28px, 350px, 63px, 30px);
    }
    85% {
        clip: rect(26px, 350px, 64px, 30px);
    }
    90% {
        clip: rect(66px, 350px, 133px, 30px);
    }
    95% {
        clip: rect(73px, 350px, 107px, 30px);
    }
    100% {
        clip: rect(37px, 350px, 123px, 30px);
    }
}

@keyframes glitch-animation-2 {
    0% {
        clip: rect(61px, 350px, 24px, 30px);
    }
    5% {
        clip: rect(98px, 350px, 6px, 30px);
    }
    10% {
        clip: rect(74px, 350px, 94px, 30px);
    }
    15% {
        clip: rect(14px, 350px, 38px, 30px);
    }
    20% {
        clip: rect(54px, 350px, 87px, 30px);
    }
    25% {
        clip: rect(20px, 350px, 136px, 30px);
    }
    30% {
        clip: rect(33px, 350px, 110px, 30px);
    }
    35% {
        clip: rect(2px, 350px, 27px, 30px);
    }
    40% {
        clip: rect(127px, 350px, 135px, 30px);
    }
    45% {
        clip: rect(108px, 350px, 137px, 30px);
    }
    50% {
        clip: rect(86px, 350px, 36px, 30px);
    }
    55% {
        clip: rect(115px, 350px, 148px, 30px);
    }
    60% {
        clip: rect(137px, 350px, 6px, 30px);
    }
    65% {
        clip: rect(11px, 350px, 79px, 30px);
    }
    70% {
        clip: rect(142px, 350px, 9px, 30px);
    }
    75% {
        clip: rect(18px, 350px, 45px, 30px);
    }
    80% {
        clip: rect(34px, 350px, 61px, 30px);
    }
    85% {
        clip: rect(63px, 350px, 109px, 30px);
    }
    90% {
        clip: rect(1px, 350px, 72px, 30px);
    }
    95% {
        clip: rect(22px, 350px, 100px, 30px);
    }
    100% {
        clip: rect(108px, 350px, 129px, 30px);
    }
}