@charset "UTF-8";

/*  Mixins */


/*  Fonts */


/* --------------------------------------------------------------- 
   Google Fonts
-----------------------------------------------------------------*/

@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,400,500,600,700,800|Nunito:300,400,600,700,800");

/*  Base */


/*  Content & Layout */


/* ----------------------------------------------------------------
    BODY
-----------------------------------------------------------------*/

html {
    overflow-x: hidden;
}

body .body-inner {
    padding: 0;
    margin: 0;
}

body .container-wide {
    width: 100%;
    margin: 0 auto;
    padding: 0 60px;
}

@media (max-width: 1024px) {
    body .body-inner {
        width: 100%;
        margin: 0;
    }
    body .container-wide {
        padding: 0 30px;
    }
    #topbar .top-menu {
        width: 100% !important;
        float: none !important;
        padding-top: 0px !important;
        margin-top: 0px !important;
        text-align: center !important;
    }
}

@media (min-width: 1024px) {
    body.boxed .body-inner {
        float: none;
        width: 1200px;
        margin: 0 auto;
    }
    body.modern .body-inner .container {
        max-width: 1500px;
    }
    body.frame:before,
    body.frame:after {
        border: 10px solid #fff;
        content: "";
        position: fixed;
        width: auto;
        height: 20px;
        z-index: 200;
        box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
        left: 0;
        right: 0;
    }
    body.frame:after {
        bottom: 0;
    }
    body.frame .body-inner {
        padding: 20px;
    }
    body.frame .body-inner:before,
    body.frame .body-inner:after {
        border: 10px solid #fff;
        content: "";
        position: fixed;
        width: 20px;
        z-index: 200;
        box-shadow: 0px 10px 14px 0 rgba(0, 0, 0, 0.1);
        top: 20px;
        bottom: 20px;
        left: 0;
        right: auto;
    }
    body.frame .body-inner:after {
        left: auto;
        right: 0;
        box-shadow: 0px 10px 14px 0 rgba(0, 0, 0, 0.1);
    }
    body.frame #scrollTop {
        right: 46px;
    }
}

.container-fullwidth {
    margin: 0 auto;
}

.container-fullwidth .row {
    padding-left: 15px;
    padding-right: 15px;
}


/* Sections */

section {
    padding: 80px 0;
    position: relative;
    width: 100%;
    overflow: hidden;
    z-index: 1;
    background-color: #ffffff;
}

@media (max-width: 768px) {
    section {
        padding: 40px 0;
    }
}

@media (max-width: 1024px) {
    .container {
        padding: 0 30px;
    }
    .m_center {
        text-align: center !important;
        float: none !important;
    }
    .m_center ul {
        text-align: center !important;
    }
}

body.dark {
    background-color: #181818;
    color: #999999;
}

section.dark,
.dark section {
    background-color: #181818;
    color: #999999;
}


/* ----------------------------------------------------------------------
    Page title
-------------------------------------------------------------------------*/

#page-title {
    clear: both;
    padding: 80px 0;
    background-color: #f8f9fa;
    position: relative;
    /* page header left*/
    /* page header right*/
}

#page-title .page-title {
    text-align: center;
    padding: 0;
}

#page-title .page-title>h1 {
    font-family: "Poppins", sans-serif;
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: 400;
    margin-bottom: 1rem;
}

#page-title .page-title>span {
    font-weight: 400;
    font-size: 14px;
}

#page-title .breadcrumb {
    text-align: center;
    margin-bottom: 0;
    padding: 0;
}

#page-title.page-title-left .page-title {
    float: left;
    text-align: left;
    clear: both;
}

#page-title.page-title-left .breadcrumb {
    float: left;
    text-align: left;
    display: block;
}

#page-title.page-title-right .page-title {
    float: right;
    text-align: right;
    clear: both;
}

#page-title.page-title-right .breadcrumb {
    float: right;
    text-align: right;
}

#page-title[data-bg-parallax] .page-title>h1 {
    color: #ffffff;
}

#page-title[data-bg-parallax] .page-title>span {
    color: #ffffff;
}

#page-title[data-bg-parallax] .breadcrumb ul li+li:before {
    color: #ffffff;
}

#page-title[data-bg-parallax] .breadcrumb ul li a {
    color: #ffffff;
}

#page-title[data-bg-parallax],
#page-title[data-bg-video] {
    background-color: #1f1f1f;
}

#header[data-transparent="true"]+#page-title {
    top: -120px;
    margin-bottom: -124px;
    position: relative;
    padding: 220px 0 160px 0;
}

.dark #page-title,
#page-title.dark {
    background-color: #181818;
}

.dark #page-title .page-title>h1,
#page-title.dark .page-title>h1 {
    color: #1f1f1f;
}

.dark #page-title .page-title>span,
#page-title.dark .page-title>span {
    color: #1f1f1f;
}

.dark #page-title .breadcrumb ul li+li:before,
#page-title.dark .breadcrumb ul li+li:before {
    color: #1f1f1f;
}

.dark #page-title .breadcrumb ul li a,
#page-title.dark .breadcrumb ul li a {
    color: #1f1f1f;
}

@media (max-width: 1024px) {
    #page-title .page-title>h1 {
        font-size: 26px;
        line-height: 34px;
    }
    #header[data-transparent="true"]+#page-title {
        top: -120px;
        margin-bottom: -124px;
        padding: 160px 0 80px 0;
    }
}


/*--------------------------------------------------------
     Content
---------------------------------------------------------*/

#page-content:not(.no-sidebar) {
    padding: 40px 0 40px 0;
}

#page-content:not(.no-sidebar).sidebar-left>.container>.row,
#page-content:not(.no-sidebar).sidebar-left>.container-wide>.row,
#page-content:not(.no-sidebar).sidebar-left>.container-fluid>.row {
    flex-direction: row-reverse;
}

#page-content:not(.no-sidebar).sidebar-right>.container>.row,
#page-content:not(.no-sidebar).sidebar-right>.container-wide>.row,
#page-content:not(.no-sidebar).sidebar-right>.container-fluid>.row {
    flex-direction: row;
}

#page-content:not(.no-sidebar).sidebar-right>.container>.row,
#page-content:not(.no-sidebar).sidebar-right>.container-wide>.row,
#page-content:not(.no-sidebar).sidebar-right>.container-fluid>.row {
    flex-direction: row;
}

#page-content:not(.no-sidebar).sidebar-both>.container>.row,
#page-content:not(.no-sidebar).sidebar-both>.container-wide>.row,
#page-content:not(.no-sidebar).sidebar-both>.container-fluid>.row {
    flex-direction: row;
}

#content {
    overflow: hidden;
    padding: 60px 0;
    position: relative;
}

#content .container,
#content .container-wide,
#content .container-fluid {
    position: relative;
}

.page-content-shadow {
    box-shadow: 0 82px 53px 10px rgba(0, 0, 0, 0.3);
}

.content {
    padding-right: 25px;
    margin-bottom: 26px;
}

.sidebar {
    padding-left: 25px;
}

.sidebar .content {
    padding-right: 25px;
    padding-left: 15px;
}

.sidebar .sidebar {
    padding-left: 25px;
    padding-right: 15px;
}

.sidebar.sidebar-modern {
    padding-left: 15px;
    padding-right: 15px;
}

.sidebar.sidebar-modern:before {
    background-color: #f8f9fa;
    width: 100%;
    content: "";
    position: absolute;
    z-index: -10;
    bottom: -10px;
    left: 0;
    right: 0;
    top: -20px;
    border-radius: 4px;
}

.sidebar-left .content {
    padding-left: 25px;
    padding-right: 15px;
}

.sidebar-left .sidebar {
    padding-right: 25px;
    padding-left: 15px;
}

.sidebar-left .sidebar.sidebar-modern {
    padding-left: 15px;
    padding-right: 15px;
}

.sidebar-both .sidebar {
    padding-left: 15px;
    padding-right: 25px;
}

.sidebar-both .sidebar.sidebar-modern {
    padding-left: 15px;
    padding-right: 15px;
}

.sidebar-both .content {
    padding-right: 25px;
    padding-left: 25px;
}

.sidebar-both .content+.sidebar {
    padding-left: 25px;
    border-right-width: 0;
    padding-right: 15px;
}

.sidebar-both .content+.sidebar.sidebar-modern {
    padding-left: 15px;
    padding-right: 15px;
}

@media (max-width: 1024px) {
    #page-content:not(.no-sidebar).sidebar-both>.container>.row .content,
    #page-content:not(.no-sidebar).sidebar-both>.container-wide>.row .content,
    #page-content:not(.no-sidebar).sidebar-both>.container-fluid>.row .content {
        order: -1;
    }
    .content,
    .sidebar {
        width: 100% !important;
        padding-left: 15px;
        padding-right: 15px;
    }
    .sidebar-right .sidebar,
    .sidebar-right .content,
    .sidebar-both .content,
    .sidebar-both .content+.sidebar {
        padding-right: 15px;
        padding-left: 15px;
    }
    .sidebar-both .sidebar:before,
    .sidebar-right .sidebar:before,
    .sidebar:before {
        right: 0;
        border-right-width: 0;
        border-left-width: 0;
        left: 0;
    }
    .sidebar-both .sidebar .widgets,
    .sidebar-right .sidebar .widgets,
    .sidebar .widgets {
        float: left;
    }
    .sidebar-both .content+.sidebar {
        border-right-width: 0;
    }
    .sidebar-both .content+.sidebar:before {
        border-right-width: 0;
        border-left-width: 0;
    }
}

@media (max-width: 1024px) {
    #page-content {
        padding: 20px 0 20px 0;
    }
}

.grid-layout {
    overflow: hidden;
    margin-bottom: -2px !important;
}

.grid-layout>* {
    opacity: 0;
    transition: opacity .5s ease;
}

.grid-layout .grid-loader {
    transition: opacity .1s ease;
    opacity: 1;
    z-index: -1;
}

.grid-layout.grid-loaded>* {
    opacity: 1;
}

.grid-layout.grid-loaded .grid-loader {
    opacity: 0;
}

.grid-layout,
.portfolioo,
.posts {
    position: relative;
    display: block;
    clear: both;
    padding: 0;
}

.grid-mix-size .portfolio-item:nth-child(2n) .new-badge {
    right: 70px !important;
}

.grid-mix-size .portfolio-item:nth-child(2n)>.portfolio-item-wrap {
    -webkit-transform: scale(0.75);
    transform: scale(0.8);
}


/*Grid columns: 5*/

.grid-6-columns .grid-item,
.post-6-columns .post-item,
.portfolio-6-columns .portfolio-item {
    width: 16.6666666666%;
}

.grid-6-columns .grid-item.large-width,
.post-6-columns .post-item.large-width,
.portfolio-6-columns .portfolio-item.large-width {
    width: 33.333333333%;
}


/*Grid columns: 5*/

.grid-5-columns .grid-item,
.post-5-columns .post-item,
.portfolio-5-columns .portfolio-item {
    width: 20%;
}

.grid-5-columns .grid-item.large-width,
.post-5-columns .post-item.large-width,
.portfolio-5-columns .portfolio-item.large-width {
    width: 40%;
}


/*Grid columns: 4*/

.grid-4-columns .grid-item,
.post-4-columns .post-item,
.portfolio-4-columns .portfolio-item {
    width: 25%;
}

.grid-4-columns .grid-item.large-width,
.post-4-columns .post-item.large-width,
.portfolio-4-columns .portfolio-item.large-width {
    width: 50%;
}


/*Grid columns: 3*/

.grid-3-columns .grid-item,
.post-3-columns .post-item,
.portfolio-3-columns .portfolio-item {
    width: 33.333333333%;
}

.grid-3-columns .grid-item.large-width,
.post-3-columns .post-item.large-width,
.portfolio-3-columns .portfolio-item.large-width {
    width: 66.6666666666%;
}


/*Grid columns: 2*/

.grid-2-columns .grid-item,
.post-2-columns .post-item,
.portfolio-2-columns .portfolio-item {
    width: 50.00%;
}

.grid-2-columns .grid-item.large-width,
.post-2-columns .post-item.large-width,
.portfolio-2-columns .portfolio-item.large-width {
    width: 100%;
}


/*Grid columns: 1*/

.grid-1-columns .grid-item,
.post-1-columns .post-item,
.portfolio-1-columns .portfolio-item {
    width: 100%;
}

.grid-1-columns .grid-item.large-width,
.post-1-columns .post-item.large-width,
.portfolio-1-columns .portfolio-item.large-width {
    width: 100%;
}

@media (max-width: 1380px) {
    .grid-5-columns .grid-item,
    .post-5-columns .post-item,
    .portfolio-5-columns .portfolio-item {
        width: 25%;
    }
    .grid-5-columns .grid-item.large-width,
    .post-5-columns .post-item.large-width,
    .portfolio-5-columns .portfolio-item.large-width {
        width: 50%;
    }
}


/*Tablet Landscape*/

@media (max-width: 1024px) {
    /*Grid columns: 6,5,4*/
    .grid-6-columns .grid-item,
    .post-6-columns .post-item,
    .portfolio-6-columns .portfolio-item,
    .grid-5-columns .grid-item,
    .post-5-columns .post-item,
    .portfolio-5-columns .portfolio-item,
    .grid-4-columns .grid-item,
    .post-4-columns .post-item,
    .portfolio-4-columns .portfolio-item {
        width: 33.3333333333%;
    }
    .grid-6-columns .grid-item.large-width,
    .post-6-columns .post-item.large-width,
    .portfolio-6-columns .portfolio-item.large-width,
    .grid-5-columns .grid-item.large-width,
    .post-5-columns .post-item.large-width,
    .portfolio-5-columns .portfolio-item.large-width,
    .grid-4-columns .grid-item.large-width,
    .post-4-columns .post-item.large-width,
    .portfolio-4-columns .portfolio-item.large-width {
        width: 66.6666666666%;
    }
    /*Grid columns: 3,2*/
    .grid-3-columns .grid-item,
    .post-3-columns .post-item,
    .portfolio-3-columns .portfolio-item,
    .grid-2-columns .grid-item,
    .post-2-columns .post-item,
    .portfolio-2-columns .portfolio-item {
        width: 50%;
    }
    .grid-3-columns .grid-item.large-width,
    .post-3-columns .post-item.large-width,
    .portfolio-3-columns .portfolio-item.large-width,
    .grid-2-columns .grid-item.large-width,
    .post-2-columns .post-item.large-width,
    .portfolio-2-columns .portfolio-item.large-width {
        width: 100%;
    }
}


/*Tablet Portrait*/

@media (max-width: 767px) {
    /*Grid columns: 6,5,4*/
    .grid-6-columns .grid-item,
    .post-6-columns .post-item,
    .portfolio-6-columns .portfolio-item,
    .grid-5-columns .grid-item,
    .post-5-columns .post-item,
    .portfolio-5-columns .portfolio-item,
    .grid-4-columns .grid-item,
    .post-4-columns .post-item,
    .portfolio-4-columns .portfolio-item {
        width: 50%;
    }
    .grid-6-columns .grid-item.large-width,
    .post-6-columns .post-item.large-width,
    .portfolio-6-columns .portfolio-item.large-width,
    .grid-5-columns .grid-item.large-width,
    .post-5-columns .post-item.large-width,
    .portfolio-5-columns .portfolio-item.large-width,
    .grid-4-columns .grid-item.large-width,
    .post-4-columns .post-item.large-width,
    .portfolio-4-columns .portfolio-item.large-width {
        width: 100%;
    }
    /*Grid columns: 3,2*/
    .grid-3-columns .grid-item,
    .post-3-columns .post-item,
    .portfolio-3-columns .portfolio-item,
    .grid-2-columns .grid-item,
    .post-2-columns .post-item,
    .portfolio-2-columns .portfolio-item {
        width: 50%;
    }
    .grid-3-columns .grid-item.large-width,
    .post-3-columns .post-item.large-width,
    .portfolio-3-columns .portfolio-item.large-width,
    .grid-2-columns .grid-item.large-width,
    .post-2-columns .post-item.large-width,
    .portfolio-2-columns .portfolio-item.large-width {
        width: 100%;
    }
}


/*Tablet Portrait*/

@media (max-width: 480px) {
    /*Grid columns: 6,5,4,3,2*/
    .grid-6-columns .grid-item,
    .post-6-columns .post-item,
    .portfolio-6-columns .portfolio-item,
    .grid-5-columns .grid-item,
    .post-5-columns .post-item,
    .portfolio-5-columns .portfolio-item,
    .grid-4-columns .grid-item,
    .post-4-columns .post-item,
    .portfolio-4-columns .portfolio-item,
    .grid-3-columns .grid-item,
    .post-3-columns .post-item,
    .portfolio-3-columns .portfolio-item,
    .grid-2-columns .grid-item,
    .post-2-columns .post-item,
    .portfolio-2-columns .portfolio-item {
        width: 100%;
    }
    .grid-6-columns .grid-item.large-width,
    .post-6-columns .post-item.large-width,
    .portfolio-6-columns .portfolio-item.large-width,
    .grid-5-columns .grid-item.large-width,
    .post-5-columns .post-item.large-width,
    .portfolio-5-columns .portfolio-item.large-width,
    .grid-4-columns .grid-item.large-width,
    .post-4-columns .post-item.large-width,
    .portfolio-4-columns .portfolio-item.large-width,
    .grid-3-columns .grid-item.large-width,
    .post-3-columns .post-item.large-width,
    .portfolio-3-columns .portfolio-item.large-width,
    .grid-2-columns .grid-item.large-width,
    .post-2-columns .post-item.large-width,
    .portfolio-2-columns .portfolio-item.large-width {
        width: 100%;
    }
    .grid-xs-2-columns .grid-item,
    .post-xs-2-columns .post-item,
    .portfolio-xs-2-columns .portfolio-item {
        width: 50%;
    }
    .grid-xs-2-columns .grid-item.large-width,
    .post-xs-2-columns .post-item.large-width,
    .portfolio-xs-2-columns .portfolio-item.large-width {
        width: 100%;
    }
    .grid-xs-3-columns .grid-item,
    .post-xs-3-columns .post-item,
    .portfolio-xs-3-columns .portfolio-item {
        width: 33.3333333333%;
    }
    .grid-xs-3-columns .grid-item.large-width,
    .post-xs-3-columns .post-item.large-width,
    .portfolio-xs-3-columns .portfolio-item.large-width {
        width: 66.6666666666%;
    }
    .grid-xs-4-columns .grid-item,
    .post-xs-4-columns .post-item,
    .portfolio-xs-4-columns .portfolio-item {
        width: 25%;
    }
    .grid-xs-4-columns .grid-item.large-width,
    .post-xs-4-columns .post-item.large-width,
    .portfolio-xs-4-columns .portfolio-item.large-width {
        width: 25%;
    }
}


/*Grid item */

.grid-item .post-item {
    width: 100%;
    padding: 0;
}

.grid-item>img,
.grid-item>a>img {
    width: 100%;
    height: auto;
}

.grid-item>.widget {
    margin: 0;
}

.grid-item {
    height: auto !important;
}


/* ----------------------------------------------------------------
	Grid Filter
-----------------------------------------------------------------*/

.grid-filter {
    clear: both;
    display: block;
    width: 100%;
    margin-bottom: 26px;
    position: relative;
}

.grid-filter ul {
    list-style: none;
    padding: 0;
    position: relative;
    height: auto;
}

.grid-filter li {
    position: relative;
    margin-right: 6px;
    border: 0;
    margin-bottom: -1px;
    display: inline-block;
}

.grid-filter li a {
    font-weight: 600;
    display: block;
    position: relative;
    padding: 8px 10px;
    font-size: 13px;
    line-height: 15px;
    color: #484848;
    border-radius: 50px;
    transition: all .3s ease;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
}

.grid-filter li:hover a,
.grid-filter li:hover a:hover,
.grid-filter li:hover a:active,
.grid-filter li:hover a:focus,
.grid-filter li.active a,
.grid-filter li.active a:hover,
.grid-filter li.active a:active,
.grid-filter li.active a:focus {
    background-color: #1290c6;
    color: #ffffff;
}

.grid-filter li.active {
    cursor: pointer;
    pointer-events: none;
}

.grid-filter li.active a {
    color: #ffffff;
    pointer-events: none;
}

.grid-filter.gf-outline li:hover a,
.grid-filter.gf-outline li.active a {
    border-color: #1290c6;
    color: #1290c6;
    background-color: transparent;
}

.grid-filter.gf-lines li a {
    border-radius: 0;
    border: none;
    background-color: transparent !important;
}

.grid-filter.gf-lines li a:after {
    content: '';
    position: absolute;
    top: auto;
    bottom: -2px;
    left: 50%;
    width: 0;
    height: 2px;
    transition: all 0.3s ease;
}

.grid-filter.gf-lines li:hover a,
.grid-filter.gf-lines li.active a {
    color: #1290c6;
}

.grid-filter.gf-lines li:hover a:after,
.grid-filter.gf-lines li.active a:after {
    width: 100%;
    left: 0%;
    background-color: #1290c6;
}

.grid-filter.gf-line-bottom {
    border-bottom: 2px solid #ebebeb;
}

.grid-filter.gf-classic li a {
    color: #484848;
    border-radius: 4px;
}

.grid-filter.gf-classic li:hover a,
.grid-filter.gf-classic li.active a {
    background-color: #1290c6;
    color: #ffffff;
}

.grid-filter.gf-light li a {
    color: #ffffff;
    background-color: transparent;
}

.grid-filter.gf-light li:hover a,
.grid-filter.gf-light li.active a {
    color: #ffffff;
    border-color: #ffffff;
    background-color: transparent;
}

.grid-filter.gf-dark li a {
    color: #1f1f1f;
    background-color: transparent;
}

.grid-filter.gf-dark li:hover,
.grid-filter.gf-dark li.active {
    background-color: transparent;
}

.grid-filter.gf-dark li:hover a,
.grid-filter.gf-dark li.active a {
    background-color: transparent;
    color: #1f1f1f;
    border-color: #1f1f1f;
}

.grid-filter.gf-default li a {
    color: none;
    border-radius: 0;
    background-color: transparent;
}

.grid-filter.gf-default li:hover a,
.grid-filter.gf-default li.active a {
    background-color: transparent;
    color: #1290c6;
}

.grid-filter.gf-creative li a {
    border-radius: 0;
    border: none;
}

.grid-filter.gf-creative li a:after {
    background: #1f1f1f;
    content: "";
    height: 2px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 100%;
    transform: translateY(10px);
    transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
    width: 100%;
}

.grid-filter.gf-creative li:hover,
.grid-filter.gf-creative li.active {
    background-color: transparent;
}

.grid-filter.gf-creative li:hover a,
.grid-filter.gf-creative li.active a {
    color: #484848;
    background-color: transparent;
}

.grid-filter.gf-creative li:hover a:after,
.grid-filter.gf-creative li.active a:after {
    opacity: 1;
    transform: translateY(0px);
}

.grid-filter.gf-list li {
    clear: both;
    margin-bottom: 3px;
}

.grid-filter.center li {
    text-align: center;
    float: none;
}

.grid-active-title {
    font-size: 3rem;
    font-weight: 100;
    opacity: 0.1;
    position: absolute;
    right: 0;
    top: -6px;
}

.content .grid-active-title {
    font-size: 42px;
}

.sidebar-both .grid-active-title {
    display: none;
}

@media (max-width: 1024px) {
    .grid-active-title {
        display: none;
    }
    .grid-filter li {
        margin-right: 0;
        margin-bottom: 4px;
    }
    .grid-filter li a {
        text-align: center;
        transition: none;
    }
}

@media (max-width: 479px) {
    .grid-filter li {
        margin-right: 0;
    }
}


/* ----------------------------------------------------------------
	Extras
-----------------------------------------------------------------*/

.infinite-scroll-message,
#showMore {
    height: 80px;
    padding-top: 36px;
    text-align: center;
}

.infinite-scroll-message p,
#showMore p {
    margin: 0;
}

.grid-loader {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
    position: absolute;
    top: 60px;
    left: 48%;
    color: #dadada;
    font-size: 6px;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.grid-loader:before,
.grid-loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
}

.grid-loader:before,
.grid-loader:after {
    content: '';
    position: absolute;
    top: 0;
}

.grid-loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.grid-loader:after {
    left: 3.5em;
}

@-webkit-keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

@keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}


/*  Typography */


/* ----------------------------------------------------------------
	Typography : Reset
-----------------------------------------------------------------*/

*,
h1,
h2,
h3,
h4,
h5,
h6,
a {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}


/* ----------------------------------------------------------------
	Typography : Font size
-----------------------------------------------------------------*/

html {
    font-size: 88%;
}

@media all and (max-width: 768px) {
    html {
        font-size: 81.25%;
    }
}

body {
    font-size: 1rem;
    line-height: 1.65714286em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Poppins", sans-serif;
    color: #484848;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: "Poppins", sans-serif;
    color: #1f1f1f;
    margin: 0;
    letter-spacing: 0.5px;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small {
    font-weight: 100;
}

h1,
.h1 {
    font-size: 3.14285714em;
    line-height: 1.31818182em;
}

h1:not(:last-child),
.h1:not(:last-child) {
    margin-bottom: 20px;
}

h2,
.h2 {
    font-size: 2.35714286em;
    line-height: 1.36363636em;
    font-weight: 500;
}

h2:not(:last-child),
.h2:not(:last-child) {
    margin-bottom: 16px;
}

h3,
.h3 {
    font-size: 1.78571429em;
    line-height: 1.5em;
    font-weight: 500;
}

h3:not(:last-child),
.h3:not(:last-child) {
    margin-bottom: 12px;
}

h4,
.h4 {
    font-size: 1.35714286em;
    line-height: 1.68421053em;
    font-weight: 600;
}

h4:not(:last-child),
.h4:not(:last-child) {
    margin-bottom: 8px;
}

h5,
.h5 {
    font-size: 1em;
    line-height: 1.85714286em;
    font-weight: 500;
}

h5:not(:last-child),
.h5:not(:last-child) {
    margin-bottom: 6px;
}

h6,
.h6 {
    font-size: 0.85714286em;
    line-height: 2.16666667em;
    font-weight: 500;
}

h6:not(:last-child),
.h6:not(:last-child) {
    margin-bottom: 6px;
}

.lead {
    font-weight: 400;
}

@media all and (max-width: 767px) {
    h1,
    .h1 {
        font-size: 2.35714286em;
        line-height: 1.36363636em;
    }
    h2,
    .h2 {
        font-size: 1.78571429em;
        line-height: 1.5em;
    }
    h3,
    .h3 {
        font-size: 1.35714286em;
        line-height: 1.85714286em;
    }
    .lead {
        font-size: 1.35714286em;
        line-height: 1.68421053em;
    }
}

.display-4 {
    font-weight: 400;
}

p {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    line-height: 1.7;
    letter-spacing: 0;
    color: #777777;
    font-family: "Poppins", sans-serif;
}

ul.bullets {
    list-style: inside;
}

strong {
    font-weight: 600;
}

del {
    text-decoration-line: line-through;
}

a:not(.btn):not(.badge):hover,
a:not(.btn):not(.badge):focus,
a:not(.btn):not(.badge):active {
    text-decoration: none;
    outline: none;
    color: #1290c6;
}

a:not(.btn):not(.badge):not(.btn):not([href]):not([tabindex]) {
    color: #484848;
}

.dark h1,
.dark h2,
.dark h3,
.dark h4,
.dark h5,
.dark h6,
.dark .h1,
.dark .h2,
.dark .h3,
.dark .h4,
.dark .h5,
.dark .h6 {
    color: #999999;
}

.font-pacifico {
    font-family: 'Dancing Script' !important;
}

.font-nothing-you-could-do,
.font-nothing-you-could-do a,
.font-nothing-you-could-do h1,
.font-nothing-you-could-do h2,
.font-nothing-you-could-do h3,
.font-nothing-you-could-do h4,
.font-nothing-you-could-do h5,
.font-nothing-you-could-do h6,
h1.font-nothing-you-could-do,
h2.font-nothing-you-could-do,
h3.font-nothing-you-could-do,
h4.font-nothing-you-could-do,
h5.font-nothing-you-could-do,
h6.font-nothing-you-could-do {
    font-family: 'Nothing You Could Do', cursive !important;
    font-weight: normal !important;
}

.font-herr-von-muellerhoff,
.font-herr-von-muellerhoff a,
.font-herr-von-muellerhoff h1,
.font-herr-von-muellerhoff h2,
.font-herr-von-muellerhoff h3,
.font-herr-von-muellerhoff h4,
.font-herr-von-muellerhoff h5,
.font-herr-von-muellerhoff h6,
h1.font-herr-von-muellerhoff,
h2.font-herr-von-muellerhoff,
h3.font-herr-von-muellerhoff,
h4.font-herr-von-muellerhoff,
h5.font-herr-von-muellerhoff,
h6.font-herr-von-muellerhoff {
    font-family: 'Herr Von Muellerhoff', cursive !important;
    font-weight: normal !important;
}

.font-cedarville,
.font-cedarville a,
.font-cedarville h1,
.font-cedarville h2,
.font-cedarville h3,
.font-cedarville h4,
.font-cedarville h5,
.font-cedarville h6,
h1.font-cedarville,
h2.font-cedarville,
h3.font-cedarville,
h4.font-cedarville,
h5.font-cedarville,
h6.font-cedarville {
    font-family: 'Cedarville Cursive', cursive;
}

.font-parisienne,
.font-parisienne a,
.font-parisienne h1,
.font-parisienne h2,
.font-parisienne h3,
.font-parisienne h4,
.font-parisienne h5,
.font-parisienne h6,
h1.font-parisienne,
h2.font-parisienne,
h3.font-parisienne,
h4.font-parisienne,
h5.font-parisienne,
h6.font-parisienne {
    font-family: 'Parisienne', cursive;
}


/*  Sliders */


/* ----------------------------------------------------------------
    SLIDER
-----------------------------------------------------------------*/

.inspiro-slider {
    width: 100%;
    height: 72vh;
    overflow: hidden;
    transition: opacity .3s ease;
    min-height: 100%;
    width: 100%;
}

.inspiro-slider.slider-halfscreen {
    height: 72vh;
}

.inspiro-slider.slider-fullscreen {
    height: 100vh;
}

.inspiro-slider.is-fade .slide {
    opacity: 0 !important;
    transition: opacity 0.5s ease !important;
}

.inspiro-slider.is-fade .slide.is-selected {
    opacity: 1 !important;
}

.inspiro-slider .slide {
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 20%;
    z-index: 1;
    width: 100%;
    display: flex !important;
}

.inspiro-slider .slide .container,
.inspiro-slider .slide .container-wide {
    /* padding-top: 120px; */
    padding-bottom: 100px;
    align-self: center !important;
}

.inspiro-slider .slide .timer-container .container {
    /* padding-top: 12px;
    padding-bottom: 10px; */
}

.inspiro-slider .slide .slide-captions {
    position: relative;
    text-align: left;
}

.inspiro-slider .slide .slide-captions h1 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 80px;
    /* margin-bottom: 30px; */
    color: #ffffff;
}

.inspiro-slider .slide .slide-captions h2 {
    font-size: 60px;
    font-weight: 700;
    line-height: 68px;
    margin-bottom: 30px;
    color: #ffffff;
}

.inspiro-slider .slide .slide-captions h3,
.inspiro-slider .slide .slide-captions h4,
.inspiro-slider .slide .slide-captions h5,
.inspiro-slider .slide .slide-captions h6 {
    color: #ffffff;
}

.inspiro-slider .slide .slide-captions .strong {
    color: #ffffff;
    display: block;
    font-size: 12px !important;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 18px;
    margin-bottom: 20px;
    position: relative;
    text-transform: uppercase;
}

.inspiro-slider .slide .slide-captions .strong::after {
    border-top: 3px solid #fff;
    content: "";
    display: block;
    margin-bottom: 0;
    margin-top: 8px;
    width: 26px;
}

.inspiro-slider .slide .slide-captions>p {
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 40px;
}

.inspiro-slider .slide .slide-captions .text-dark.strong::after,
.inspiro-slider .slide .slide-captions.text-dark .strong::after {
    border-top-color: #111;
}

.inspiro-slider .slide .slide-captions.text-left .strong::after {
    margin-left: 0;
    margin-right: auto;
}

.inspiro-slider .slide .slide-captions.text-center .strong::after {
    margin-left: auto;
    margin-right: auto;
}

.inspiro-slider .slide .slide-captions.text-right .strong::after {
    margin-left: auto;
    margin-right: 0;
}

.inspiro-slider .slide .slide-captions .slide-caption-hide {
    opacity: 0;
    visibility: hidden;
}

.inspiro-slider .slide .slide-captions img {
    width: inherit !important;
}

.inspiro-slider .slide.is-selected {
    z-index: 2;
}

.inspiro-slider.slider-loaded,
.inspiro-slider.flickity-enabled {
    opacity: 1;
}

.inspiro-slider .slide-link {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
}

.inspiro-slider .flickity-button {
    width: 78px;
    height: 78px;
    line-height: 78px;
    background: transparent;
    color: #ffffff;
    box-shadow: none;
    padding: 0;
}

.inspiro-slider .flickity-button::before {
    line-height: 78px;
    font-size: 28px;
    color: #ffffff;
}

.inspiro-slider .flickity-page-dots {
    bottom: 30px;
}

.inspiro-slider .flickity-page-dots .dot {
    box-shadow: inset 0 0 0 2px #ffffff;
}

.inspiro-slider .flickity-page-dots .dot:after {
    background-color: #ffffff;
    box-shadow: 0 0 1px #ffffff;
}

.inspiro-slider .slide-captions>* {
    opacity: 0;
}

#slider {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 0px;
    background-color: #fff;
    z-index: 1;
}

#home {
    width: 100%;
    height: 100%;
    color: #FFF;
}


/* ----------------------------------------------------------------
    BOXED SLIDER
-----------------------------------------------------------------*/

.boxed-slider {
    height: 600px;
    width: 100%;
}

.boxed-slider .polo-carousel-item {
    height: 600px;
}

.boxed-slider .owl-bg-img {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.polo-carousel-item img {
    height: 100%;
}


/* ----------------------------------------------------------------
    Inspiro Slider - Responsive Classes
-----------------------------------------------------------------*/

@media (max-width: 1024px) {
    .inspiro-slider .flickity-button:hover {
        background-color: transparent;
    }
    .inspiro-slider .flickity-button.next,
    .inspiro-slider .flickity-button.next:hover {
        right: -5px !important;
        opacity: 1;
    }
    .inspiro-slider .flickity-button.previous,
    .inspiro-slider .flickity-button.previous:hover {
        left: -5px !important;
        opacity: 1;
    }
    .inspiro-slider .container {
        padding-left: 40px;
        padding-right: 40px;
    }
    .inspiro-slider .slide .slide-captions {
        text-align: center;
    }
    .inspiro-slider .slide .slide-captions .strong::after {
        margin-left: auto;
        margin-right: auto;
    }
    .inspiro-slider .slide .slide-captions h1,
    .inspiro-slider .slide .slide-captions h2 {
        font-size: 3rem;
        line-height: 40px;
        /* margin-bottom: 14px; */
    }
    .inspiro-slider .slide .slide-captions>p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 16px;
    }
    .inspiro-slider .slide .slide-captions h3,
    .inspiro-slider .slide .slide-captions h4,
    .inspiro-slider .slide .slide-captions h5,
    .inspiro-slider .slide .slide-captions h6 {
        color: #ffffff;
    }
}

.fullscreen {
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-height: 100vh;
}

.fullscreen .container,
.fullscreen .container-wide {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-top: 80px;
    margin-bottom: 40px;
}

.fullscreen .container-fullscreen {
    width: 100%;
}

.halfscreen {
    width: 100%;
    height: 72%;
    overflow: hidden;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-height: 72vh;
}

.halfscreen .container,
.halfscreen .container-wide {
    margin-top: 2%;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.halfscreen .container-fullscreen {
    width: 100%;
}

@media (max-width: 1024px) {
    #header[data-transparent="true"]+main {
        top: -120px;
        margin-bottom: -124px;
        position: relative;
    }
    #header[data-transparent="true"]+main>#page-title {
        padding: 220px 0 160px 0;
    }
    #header[data-transparent="true"]+.fullscreen {
        top: -80px;
        margin-bottom: -80px;
    }
}


/*  Portfolio */


/* ----------------------------------------------------------------
Portfolio
-----------------------------------------------------------------*/

.portfolio-item,
.grid-item {
    width: 100%;
    float: left;
    height: auto;
    padding: 0 0 20px 0;
    cursor: pointer;
    /*hover state*/
    /*Portfolio Effects*/
    /*Styles*/
}

.portfolio-item a:not(.btn),
.grid-item a:not(.btn) {
    color: #484848;
}

.portfolio-item .portfolio-item-wrap,
.portfolio-item .grid-item-wrap,
.grid-item .portfolio-item-wrap,
.grid-item .grid-item-wrap {
    position: relative;
    overflow: hidden;
}

.portfolio-item .portfolio-item-wrap>a,
.portfolio-item .grid-item-wrap>a,
.grid-item .portfolio-item-wrap>a,
.grid-item .grid-item-wrap>a {
    z-index: 6;
    width: 100%;
    height: 100%;
    position: absolute;
}

.portfolio-item .portfolio-image,
.portfolio-item .portfolio-video,
.portfolio-item .grid-image,
.portfolio-item .grid-video,
.portfolio-item .flickity-viewport,
.grid-item .portfolio-image,
.grid-item .portfolio-video,
.grid-item .grid-image,
.grid-item .grid-video,
.grid-item .flickity-viewport {
    position: relative;
    overflow: hidden;
}

.portfolio-item .portfolio-image:after,
.portfolio-item .portfolio-video:after,
.portfolio-item .grid-image:after,
.portfolio-item .grid-video:after,
.portfolio-item .flickity-viewport:after,
.grid-item .portfolio-image:after,
.grid-item .portfolio-video:after,
.grid-item .grid-image:after,
.grid-item .grid-video:after,
.grid-item .flickity-viewport:after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000000;
    transition: all 0.3s ease;
    opacity: 0;
    content: ' ';
    z-index: 2;
}

.portfolio-item .portfolio-image img,
.portfolio-item .portfolio-video img,
.portfolio-item .grid-image img,
.portfolio-item .grid-video img,
.portfolio-item .flickity-viewport img,
.grid-item .portfolio-image img,
.grid-item .portfolio-video img,
.grid-item .grid-image img,
.grid-item .grid-video img,
.grid-item .flickity-viewport img {
    position: relative;
    transition: all 0.5s ease-out;
    width: 100%;
    z-index: 1;
    display: block;
    height: auto;
}

.portfolio-item .portfolio-slider .flickity-page-dots,
.portfolio-item .grid-slider .flickity-page-dots,
.grid-item .portfolio-slider .flickity-page-dots,
.grid-item .grid-slider .flickity-page-dots {
    bottom: 30px;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.portfolio-item .portfolio-video,
.portfolio-item .grid-video,
.grid-item .portfolio-video,
.grid-item .grid-video {
    margin-bottom: -7px;
}

.portfolio-item .portfolio-video video,
.portfolio-item .portfolio-video iframe,
.portfolio-item .grid-video video,
.portfolio-item .grid-video iframe,
.grid-item .portfolio-video video,
.grid-item .portfolio-video iframe,
.grid-item .grid-video video,
.grid-item .grid-video iframe {
    width: 100%;
    background-color: #000000;
}

.portfolio-item .portfolio-description,
.portfolio-item .grid-description,
.portfolio-item .portfolio-links,
.grid-item .portfolio-description,
.grid-item .grid-description,
.grid-item .portfolio-links,
.portfolio-item .team_details_link {
    left: 0;
    margin: 0 auto;
    padding: 20px;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 48%;
    transform: translate3d(0px, -38%, 0px);
    transition: all 250ms ease-in-out 0s;
    visibility: hidden;
    width: 70%;
    z-index: 3;
}

.portfolio-item .portfolio-description h3,
.portfolio-item .grid-description h3,
.portfolio-item .portfolio-links h3,
.grid-item .portfolio-description h3,
.grid-item .grid-description h3,
.grid-item .portfolio-links h3,
.portfolio-item .team_details_link h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 4px;
}

.portfolio-item .portfolio-description h3,
.portfolio-item .portfolio-description p,
.portfolio-item .portfolio-description span,
.portfolio-item .grid-description h3,
.portfolio-item .grid-description p,
.portfolio-item .grid-description span,
.portfolio-item .portfolio-links h3,
.portfolio-item .portfolio-links p,
.portfolio-item .portfolio-links span,
.grid-item .portfolio-description h3,
.grid-item .portfolio-description p,
.grid-item .portfolio-description span,
.grid-item .grid-description h3,
.grid-item .grid-description p,
.grid-item .grid-description span,
.grid-item .portfolio-links h3,
.grid-item .portfolio-links p,
.grid-item .portfolio-links span,
.portfolio-item .team_details_link h3,
.portfolio-item .team_details_link span,
.portfolio-item .team_details_link p {
    color: #ffffff;
}

.portfolio-item .portfolio-description a,
.portfolio-item .grid-description a,
.portfolio-item .portfolio-links a,
.grid-item .portfolio-description a,
.grid-item .grid-description a,
.grid-item .portfolio-links a,
.portfolio-item .team_details_link a {
    margin: 3px;
}

.portfolio-item .portfolio-description a.btn,
.portfolio-item .grid-description a.btn,
.portfolio-item .portfolio-links a.btn,
.grid-item .portfolio-description a.btn,
.grid-item .grid-description a.btn,
.grid-item .portfolio-links a.btn,
.portfolio-item .team_details_link a.btn {
    margin-top: 10px;
}

.portfolio-item .portfolio-description a i,
.portfolio-item .grid-description a i,
.portfolio-item .portfolio-links a i,
.grid-item .portfolio-description a i,
.grid-item .grid-description a i,
.grid-item .portfolio-links a i,
.portfolio-item .team_details_link a i {
    background-color: #ffffff;
    color: #484848;
    border-radius: 50%;
    color: #444;
    font-size: 18px;
    height: 40px;
    line-height: 40px !important;
    width: 40px;
    transition: all 250ms ease-in-out 0s;
    border: 1px solid #ededed;
}

.portfolio-item .portfolio-description a:hover i,
.portfolio-item .grid-description a:hover i,
.portfolio-item .portfolio-links a:hover i,
.grid-item .portfolio-description a:hover i,
.grid-item .grid-description a:hover i,
.grid-item .portfolio-links a:hover i,
.portfolio-item .team_details_link a:hover i {
    background-color: #1290c6;
    color: #ffffff;
    border: 1px solid #1290c6;
}

.portfolio-item .portfolio-description span,
.portfolio-item .portfolio-description p,
.portfolio-item .grid-description span,
.portfolio-item .grid-description p,
.portfolio-item .portfolio-links span,
.portfolio-item .portfolio-links p,
.grid-item .portfolio-description span,
.grid-item .portfolio-description p,
.grid-item .grid-description span,
.grid-item .grid-description p,
.grid-item .portfolio-links span,
.grid-item .portfolio-links p,
.portfolio-item .team_details_link span,
.portfolio-item .team_details_link p {
    opacity: 0;
    transition: all 0.8s ease;
    transition-delay: 0.1s;
    margin-bottom: 0;
    padding-bottom: 0;
}

.portfolio-item .portfolio-description a>i,
.portfolio-item .grid-description a>i,
.portfolio-item .portfolio-links a>i,
.grid-item .portfolio-description a>i,
.grid-item .grid-description a>i,
.grid-item .portfolio-links a>i,
.portfolio-item .team_details_link a>i {
    font-size: 14px !important;
}

.portfolio-item .portfolio-meta,
.portfolio-item .grid-meta,
.grid-item .portfolio-meta,
.grid-item .grid-meta {
    padding-top: 20px;
}

.portfolio-item:not(.no-overlay):hover .portfolio-slider .flickity-viewport:after,
.portfolio-item:not(.no-overlay):hover .portfolio-image:after,
.portfolio-item:not(.no-overlay):hover .portfolio-video:after,
.portfolio-item:not(.no-overlay):hover .grid-slider .flickity-viewport:after,
.portfolio-item:not(.no-overlay):hover .grid-image:after,
.portfolio-item:not(.no-overlay):hover .grid-video:after,
.grid-item:not(.no-overlay):hover .portfolio-slider .flickity-viewport:after,
.grid-item:not(.no-overlay):hover .portfolio-image:after,
.grid-item:not(.no-overlay):hover .portfolio-video:after,
.grid-item:not(.no-overlay):hover .grid-slider .flickity-viewport:after,
.grid-item:not(.no-overlay):hover .grid-image:after,
.grid-item:not(.no-overlay):hover .grid-video:after,
.team-member:not(.no-overlay):hover .team-image:after {
    opacity: 0.5;
}

.portfolio-item:not(.no-overlay):hover .portfolio-description,
.portfolio-item:not(.no-overlay):hover .grid-description,
.portfolio-item:not(.no-overlay):hover .portfolio-links,
.grid-item:not(.no-overlay):hover .portfolio-description,
.grid-item:not(.no-overlay):hover .grid-description,
.grid-item:not(.no-overlay):hover .portfolio-links,
.portfolio-item:not(.no-overlay):hover .team_details_link {
    transform: translate3d(0, -50%, 0);
    opacity: 1;
    visibility: visible;
}

.portfolio-item:not(.no-overlay):hover .portfolio-description h3,
.portfolio-item:not(.no-overlay):hover .grid-description h3,
.portfolio-item:not(.no-overlay):hover .portfolio-links h3,
.grid-item:not(.no-overlay):hover .portfolio-description h3,
.grid-item:not(.no-overlay):hover .grid-description h3,
.grid-item:not(.no-overlay):hover .portfolio-links h3,
.portfolio-item:not(.no-overlay):hover .team_details_link h3 {
    transform: translateY(0px);
}

.portfolio-item:not(.no-overlay):hover .portfolio-description span,
.portfolio-item:not(.no-overlay):hover .portfolio-description p,
.portfolio-item:not(.no-overlay):hover .grid-description span,
.portfolio-item:not(.no-overlay):hover .grid-description p,
.portfolio-item:not(.no-overlay):hover .portfolio-links span,
.portfolio-item:not(.no-overlay):hover .portfolio-links p,
.grid-item:not(.no-overlay):hover .portfolio-description span,
.grid-item:not(.no-overlay):hover .portfolio-description p,
.grid-item:not(.no-overlay):hover .grid-description span,
.grid-item:not(.no-overlay):hover .grid-description p,
.grid-item:not(.no-overlay):hover .portfolio-links span,
.grid-item:not(.no-overlay):hover .portfolio-links p,
.portfolio-item:not(.no-overlay):hover .team_details_link p,
.portfolio-item:not(.no-overlay):hover .team_details_link span {
    opacity: 1;
}

.portfolio-item.classic-style .portfolio-item-wrap,
.portfolio-item.classic-style .grid-item-wrap,
.grid-item.classic-style .portfolio-item-wrap,
.grid-item.classic-style .grid-item-wrap {
    position: relative;
    overflow: initial !important;
}

.portfolio-item.classic-style .portfolio-image,
.portfolio-item.classic-style .portfolio-video,
.portfolio-item.classic-style .grid-image,
.portfolio-item.classic-style .grid-video,
.portfolio-item.classic-style .flickity-viewport,
.grid-item.classic-style .portfolio-image,
.grid-item.classic-style .portfolio-video,
.grid-item.classic-style .grid-image,
.grid-item.classic-style .grid-video,
.grid-item.classic-style .flickity-viewport {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transition: all 0.3s ease;
    position: relative;
    /* 	img {
  	}  */
}

.portfolio-item.classic-style .portfolio-image:after,
.portfolio-item.classic-style .portfolio-video:after,
.portfolio-item.classic-style .grid-image:after,
.portfolio-item.classic-style .grid-video:after,
.portfolio-item.classic-style .flickity-viewport:after,
.grid-item.classic-style .portfolio-image:after,
.grid-item.classic-style .portfolio-video:after,
.grid-item.classic-style .grid-image:after,
.grid-item.classic-style .grid-video:after,
.grid-item.classic-style .flickity-viewport:after {
    display: none;
}

.portfolio-item.classic-style:hover .portfolio-image,
.portfolio-item.classic-style:hover .portfolio-video,
.portfolio-item.classic-style:hover .grid-image,
.portfolio-item.classic-style:hover .grid-video,
.portfolio-item.classic-style:hover .flickity-viewport,
.grid-item.classic-style:hover .portfolio-image,
.grid-item.classic-style:hover .portfolio-video,
.grid-item.classic-style:hover .grid-image,
.grid-item.classic-style:hover .grid-video,
.grid-item.classic-style:hover .flickity-viewport {
    box-shadow: 0 32px 58px rgba(0, 0, 0, 0.19);
    transform: translateY(-6px);
}

.portfolio-item.classic-style:hover .portfolio-description,
.portfolio-item.classic-style:hover .grid-description,
.grid-item.classic-style:hover .portfolio-description,
.grid-item.classic-style:hover .grid-description {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible;
}

.portfolio-item.text-bottom .portfolio-description,
.portfolio-item.text-bottom .grid-description,
.grid-item.text-bottom .portfolio-description,
.grid-item.text-bottom .grid-description {
    bottom: 0;
    left: 0;
    top: auto;
    text-align: left;
    transform: translate3d(0, 0, 0);
    width: 100%;
}

.portfolio-item.text-bottom:hover .portfolio-description,
.portfolio-item.text-bottom:hover .grid-description,
.grid-item.text-bottom:hover .portfolio-description,
.grid-item.text-bottom:hover .grid-description {
    transform: translate3d(0, -4%, 0);
    opacity: 1;
    visibility: visible;
}

.portfolio-item.img-zoom-out .portfolio-image img,
.portfolio-item.img-zoom-out .grid-image img,
.grid-item.img-zoom-out .portfolio-image img,
.grid-item.img-zoom-out .grid-image img {
    transform: scale(1.1);
}

.portfolio-item.img-zoom-out:hover .portfolio-image img,
.portfolio-item.img-zoom-out:hover .grid-image img,
.grid-item.img-zoom-out:hover .portfolio-image img,
.grid-item.img-zoom-out:hover .grid-image img {
    transform: scale(1);
}

.portfolio-item.reveal-effect .portfolio-item-wrap,
.portfolio-item.reveal-effect .grid-item-wrap,
.grid-item.reveal-effect .portfolio-item-wrap,
.grid-item.reveal-effect .grid-item-wrap {
    position: relative;
}

.portfolio-item.reveal-effect .portfolio-item-wrap .portfolio-image,
.portfolio-item.reveal-effect .portfolio-item-wrap .portfolio-video,
.portfolio-item.reveal-effect .portfolio-item-wrap .grid-image,
.portfolio-item.reveal-effect .portfolio-item-wrap .grid-video,
.portfolio-item.reveal-effect .grid-item-wrap .portfolio-image,
.portfolio-item.reveal-effect .grid-item-wrap .portfolio-video,
.portfolio-item.reveal-effect .grid-item-wrap .grid-image,
.portfolio-item.reveal-effect .grid-item-wrap .grid-video,
.grid-item.reveal-effect .portfolio-item-wrap .portfolio-image,
.grid-item.reveal-effect .portfolio-item-wrap .portfolio-video,
.grid-item.reveal-effect .portfolio-item-wrap .grid-image,
.grid-item.reveal-effect .portfolio-item-wrap .grid-video,
.grid-item.reveal-effect .grid-item-wrap .portfolio-image,
.grid-item.reveal-effect .grid-item-wrap .portfolio-video,
.grid-item.reveal-effect .grid-item-wrap .grid-image,
.grid-item.reveal-effect .grid-item-wrap .grid-video {
    width: 100%;
    max-width: 100%;
    display: block;
    z-index: 1;
    position: relative;
}

.portfolio-item.reveal-effect .portfolio-item-wrap .portfolio-image:after,
.portfolio-item.reveal-effect .portfolio-item-wrap .portfolio-video:after,
.portfolio-item.reveal-effect .portfolio-item-wrap .grid-image:after,
.portfolio-item.reveal-effect .portfolio-item-wrap .grid-video:after,
.portfolio-item.reveal-effect .grid-item-wrap .portfolio-image:after,
.portfolio-item.reveal-effect .grid-item-wrap .portfolio-video:after,
.portfolio-item.reveal-effect .grid-item-wrap .grid-image:after,
.portfolio-item.reveal-effect .grid-item-wrap .grid-video:after,
.grid-item.reveal-effect .portfolio-item-wrap .portfolio-image:after,
.grid-item.reveal-effect .portfolio-item-wrap .portfolio-video:after,
.grid-item.reveal-effect .portfolio-item-wrap .grid-image:after,
.grid-item.reveal-effect .portfolio-item-wrap .grid-video:after,
.grid-item.reveal-effect .grid-item-wrap .portfolio-image:after,
.grid-item.reveal-effect .grid-item-wrap .portfolio-video:after,
.grid-item.reveal-effect .grid-item-wrap .grid-image:after,
.grid-item.reveal-effect .grid-item-wrap .grid-video:after {
    opacity: 0.3;
}

.portfolio-item.reveal-effect .portfolio-item-wrap .portfolio-description,
.portfolio-item.reveal-effect .portfolio-item-wrap .grid-description,
.portfolio-item.reveal-effect .grid-item-wrap .portfolio-description,
.portfolio-item.reveal-effect .grid-item-wrap .grid-description,
.grid-item.reveal-effect .portfolio-item-wrap .portfolio-description,
.grid-item.reveal-effect .portfolio-item-wrap .grid-description,
.grid-item.reveal-effect .grid-item-wrap .portfolio-description,
.grid-item.reveal-effect .grid-item-wrap .grid-description {
    pointer-events: none;
}

.portfolio-item.reveal-effect:hover .portfolio-slider .flickity-viewport:after,
.portfolio-item.reveal-effect:hover .portfolio-image:after,
.portfolio-item.reveal-effect:hover .portfolio-video:after,
.portfolio-item.reveal-effect:hover .grid-slider .flickity-viewport:after,
.portfolio-item.reveal-effect:hover .grid-image:after,
.portfolio-item.reveal-effect:hover .grid-video:after,
.grid-item.reveal-effect:hover .portfolio-slider .flickity-viewport:after,
.grid-item.reveal-effect:hover .portfolio-image:after,
.grid-item.reveal-effect:hover .portfolio-video:after,
.grid-item.reveal-effect:hover .grid-slider .flickity-viewport:after,
.grid-item.reveal-effect:hover .grid-image:after,
.grid-item.reveal-effect:hover .grid-video:after {
    opacity: 0.2;
    z-index: 8;
}

.portfolio-item.reveal-effect:hover .portfolio-description,
.portfolio-item.reveal-effect:hover .grid-description,
.grid-item.reveal-effect:hover .portfolio-description,
.grid-item.reveal-effect:hover .grid-description {
    opacity: 1;
    transform: translate3d(0, -33%, 0);
}

.portfolio-item.img-rotate .portfolio-image img,
.portfolio-item.img-rotate .grid-image img,
.grid-item.img-rotate .portfolio-image img,
.grid-item.img-rotate .grid-image img {
    transform: rotate(0deg) scale(1);
    transition: transform 0.7s ease 0s;
}

.portfolio-item.img-rotate:hover .portfolio-image img,
.portfolio-item.img-rotate:hover .grid-image img,
.grid-item.img-rotate:hover .portfolio-image img,
.grid-item.img-rotate:hover .grid-image img {
    transform: rotate(-9deg) scale(1.2);
}

.portfolio-item.img-zoom .portfolio-image img,
.portfolio-item.img-zoom .grid-image img,
.grid-item.img-zoom .portfolio-image img,
.grid-item.img-zoom .grid-image img {
    transform: scale(1);
}

.portfolio-item.img-zoom:hover .portfolio-image img,
.portfolio-item.img-zoom:hover .grid-image img,
.grid-item.img-zoom:hover .portfolio-image img,
.grid-item.img-zoom:hover .grid-image img {
    transform: scale(1.1);
}

.portfolio-item.overlay-light .portfolio-image:after,
.portfolio-item.overlay-light .portfolio-slider .flickity-viewport:after,
.portfolio-item.overlay-light .grid-image:after,
.portfolio-item.overlay-light .grid-slider .flickity-viewport:after,
.grid-item.overlay-light .portfolio-image:after,
.grid-item.overlay-light .portfolio-slider .flickity-viewport:after,
.grid-item.overlay-light .grid-image:after,
.grid-item.overlay-light .grid-slider .flickity-viewport:after {
    background-color: #ffffff;
}

.portfolio-item.overlay-light .portfolio-description h3,
.portfolio-item.overlay-light .grid-description h3,
.grid-item.overlay-light .portfolio-description h3,
.grid-item.overlay-light .grid-description h3 {
    color: #000000;
}

.portfolio-item.overlay-light .portfolio-description p,
.portfolio-item.overlay-light .portfolio-description span,
.portfolio-item.overlay-light .grid-description p,
.portfolio-item.overlay-light .grid-description span,
.grid-item.overlay-light .portfolio-description p,
.grid-item.overlay-light .portfolio-description span,
.grid-item.overlay-light .grid-description p,
.grid-item.overlay-light .grid-description span {
    color: #434343;
}

.portfolio-item.overlay-light:hover .portfolio-image:after,
.portfolio-item.overlay-light:hover .portfolio-slider .flickity-viewport:after,
.portfolio-item.overlay-light:hover .grid-image:after,
.portfolio-item.overlay-light:hover .grid-slider .flickity-viewport:after,
.grid-item.overlay-light:hover .portfolio-image:after,
.grid-item.overlay-light:hover .portfolio-slider .flickity-viewport:after,
.grid-item.overlay-light:hover .grid-image:after,
.grid-item.overlay-light:hover .grid-slider .flickity-viewport:after {
    opacity: 0.9;
}

.portfolio-item.overlay-grey .portfolio-image:after,
.portfolio-item.overlay-grey .portfolio-slider .flickity-viewport:after,
.portfolio-item.overlay-grey .grid-image:after,
.portfolio-item.overlay-grey .grid-slider .flickity-viewport:after,
.grid-item.overlay-grey .portfolio-image:after,
.grid-item.overlay-grey .portfolio-slider .flickity-viewport:after,
.grid-item.overlay-grey .grid-image:after,
.grid-item.overlay-grey .grid-slider .flickity-viewport:after {
    background-color: #F7F9FB;
}

.portfolio-item.overlay-grey .portfolio-description h3,
.portfolio-item.overlay-grey .grid-description h3,
.grid-item.overlay-grey .portfolio-description h3,
.grid-item.overlay-grey .grid-description h3 {
    color: #484848;
}

.portfolio-item.overlay-grey .portfolio-description p,
.portfolio-item.overlay-grey .portfolio-description span,
.portfolio-item.overlay-grey .grid-description p,
.portfolio-item.overlay-grey .grid-description span,
.grid-item.overlay-grey .portfolio-description p,
.grid-item.overlay-grey .portfolio-description span,
.grid-item.overlay-grey .grid-description p,
.grid-item.overlay-grey .grid-description span {
    color: #a8a8a8;
}

.portfolio-item.overlay-grey:hover .portfolio-image:after,
.portfolio-item.overlay-grey:hover .portfolio-slider .flickity-viewport:after,
.portfolio-item.overlay-grey:hover .grid-image:after,
.portfolio-item.overlay-grey:hover .grid-slider .flickity-viewport:after,
.grid-item.overlay-grey:hover .portfolio-image:after,
.grid-item.overlay-grey:hover .portfolio-slider .flickity-viewport:after,
.grid-item.overlay-grey:hover .grid-image:after,
.grid-item.overlay-grey:hover .grid-slider .flickity-viewport:after {
    opacity: 1;
}

.portfolio-item.overlay-white .portfolio-image:after,
.portfolio-item.overlay-white .portfolio-slider .flickity-viewport:after,
.portfolio-item.overlay-white .grid-image:after,
.portfolio-item.overlay-white .grid-slider .flickity-viewport:after,
.grid-item.overlay-white .portfolio-image:after,
.grid-item.overlay-white .portfolio-slider .flickity-viewport:after,
.grid-item.overlay-white .grid-image:after,
.grid-item.overlay-white .grid-slider .flickity-viewport:after {
    background-color: #ffffff;
}

.portfolio-item.overlay-white .portfolio-description h3,
.portfolio-item.overlay-white .grid-description h3,
.grid-item.overlay-white .portfolio-description h3,
.grid-item.overlay-white .grid-description h3 {
    color: #484848;
}

.portfolio-item.overlay-white .portfolio-description p,
.portfolio-item.overlay-white .portfolio-description span,
.portfolio-item.overlay-white .grid-description p,
.portfolio-item.overlay-white .grid-description span,
.grid-item.overlay-white .portfolio-description p,
.grid-item.overlay-white .portfolio-description span,
.grid-item.overlay-white .grid-description p,
.grid-item.overlay-white .grid-description span {
    color: #a8a8a8;
}

.portfolio-item.overlay-white:hover .portfolio-image:after,
.portfolio-item.overlay-white:hover .portfolio-slider .flickity-viewport:after,
.portfolio-item.overlay-white:hover .grid-image:after,
.portfolio-item.overlay-white:hover .grid-slider .flickity-viewport:after,
.grid-item.overlay-white:hover .portfolio-image:after,
.grid-item.overlay-white:hover .portfolio-slider .flickity-viewport:after,
.grid-item.overlay-white:hover .grid-image:after,
.grid-item.overlay-white:hover .grid-slider .flickity-viewport:after {
    opacity: 1;
}

.portfolio-item.overlay-dark .portfolio-image:after,
.portfolio-item.overlay-dark .portfolio-slider .flickity-viewport:after,
.portfolio-item.overlay-dark .grid-image:after,
.portfolio-item.overlay-dark .grid-slider .flickity-viewport:after,
.grid-item.overlay-dark .portfolio-image:after,
.grid-item.overlay-dark .portfolio-slider .flickity-viewport:after,
.grid-item.overlay-dark .grid-image:after,
.grid-item.overlay-dark .grid-slider .flickity-viewport:after {
    background-color: #000000;
}

.portfolio-item.overlay-dark .portfolio-description h3,
.portfolio-item.overlay-dark .grid-description h3,
.grid-item.overlay-dark .portfolio-description h3,
.grid-item.overlay-dark .grid-description h3 {
    color: #ffffff;
}

.portfolio-item.overlay-dark .portfolio-description p,
.portfolio-item.overlay-dark .portfolio-description span,
.portfolio-item.overlay-dark .grid-description p,
.portfolio-item.overlay-dark .grid-description span,
.grid-item.overlay-dark .portfolio-description p,
.grid-item.overlay-dark .portfolio-description span,
.grid-item.overlay-dark .grid-description p,
.grid-item.overlay-dark .grid-description span {
    color: #a8a8a8;
}

.portfolio-item.overlay-dark:hover .portfolio-image:after,
.portfolio-item.overlay-dark:hover .portfolio-slider .flickity-viewport:after,
.portfolio-item.overlay-dark:hover .grid-image:after,
.portfolio-item.overlay-dark:hover .grid-slider .flickity-viewport:after,
.grid-item.overlay-dark:hover .portfolio-image:after,
.grid-item.overlay-dark:hover .portfolio-slider .flickity-viewport:after,
.grid-item.overlay-dark:hover .grid-image:after,
.grid-item.overlay-dark:hover .grid-slider .flickity-viewport:after {
    opacity: 1;
}

.portfolio-item.overlay-padding .portfolio-image:after,
.portfolio-item.overlay-padding .grid-image:after,
.grid-item.overlay-padding .portfolio-image:after,
.grid-item.overlay-padding .grid-image:after {
    bottom: 15px;
    height: auto;
    left: 15px;
    right: 15px;
    top: 15px;
    width: auto;
}

.portfolio-item.overlay-border .portfolio-image:after,
.portfolio-item.overlay-border .grid-image:after,
.grid-item.overlay-border .portfolio-image:after,
.grid-item.overlay-border .grid-image:after {
    opacity: 1;
    background-color: transparent;
    position: absolute;
}

.portfolio-item.overlay-border .portfolio-description,
.grid-item.overlay-border .portfolio-description {
    display: flex;
    position: absolute;
    left: 30px;
    right: 30px;
    top: 30px;
    bottom: 30px;
    border: 0 solid #00BCD4;
    transition: border .4s ease;
}

.portfolio-item.overlay-border:hover .portfolio-description,
.grid-item.overlay-border:hover .portfolio-description {
    border-width: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
}

.portfolio-item.open-cursor .portfolio-wrap>a,
.portfolio-item.open-cursor .grid-wrap>a,
.grid-item.open-cursor .portfolio-wrap>a,
.grid-item.open-cursor .grid-wrap>a {
    cursor: crosshair;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
}

.portfolio-item.no-overlay .portfolio-slider .flickity-viewport:after,
.portfolio-item.no-overlay .grid-slider .flickity-viewport:after,
.grid-item.no-overlay .portfolio-slider .flickity-viewport:after,
.grid-item.no-overlay .grid-slider .flickity-viewport:after {
    display: none;
}

.portfolio-item.no-overlay .portfolio-image,
.portfolio-item.no-overlay .grid-image,
.grid-item.no-overlay .portfolio-image,
.grid-item.no-overlay .grid-image {
    position: relative;
    overflow: hidden;
}

.portfolio-item.no-overlay .portfolio-image:after,
.portfolio-item.no-overlay .grid-image:after,
.grid-item.no-overlay .portfolio-image:after,
.grid-item.no-overlay .grid-image:after {
    display: none;
}

.portfolio-item.no-overlay .portfolio-description,
.portfolio-item.no-overlay .grid-description,
.grid-item.no-overlay .portfolio-description,
.grid-item.no-overlay .grid-description {
    opacity: 1;
    padding: 6px;
    position: relative;
    transform: translateY(-10px);
    visibility: inherit;
    width: 100%;
}

.portfolio-item.no-overlay .portfolio-description h3,
.portfolio-item.no-overlay .grid-description h3,
.grid-item.no-overlay .portfolio-description h3,
.grid-item.no-overlay .grid-description h3 {
    color: #484848;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 18px;
    margin-bottom: 4px;
    text-transform: uppercase;
}

.portfolio-item.no-overlay .portfolio-description span,
.portfolio-item.no-overlay .grid-description span,
.grid-item.no-overlay .portfolio-description span,
.grid-item.no-overlay .grid-description span {
    color: #484848;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 15px;
    text-transform: capitalize;
    opacity: 1;
}

.portfolio-item.no-overlay .portfolio-description p,
.portfolio-item.no-overlay .grid-description p,
.grid-item.no-overlay .portfolio-description p,
.grid-item.no-overlay .grid-description p {
    color: #484848;
    font-weight: 500;
    font-size: 14px;
    opacity: 1;
}

.portfolio-item.no-overlay:hover .portfolio-description,
.portfolio-item.no-overlay:hover .grid-description,
.grid-item.no-overlay:hover .portfolio-description,
.grid-item.no-overlay:hover .grid-description {
    transform: translate3d(0, -10px, 0);
    opacity: 1;
    visibility: visible;
}

.portfolio-item.no-overlay:hover .portfolio-description h3,
.portfolio-item.no-overlay:hover .grid-description h3,
.grid-item.no-overlay:hover .portfolio-description h3,
.grid-item.no-overlay:hover .grid-description h3 {
    transform: translateY(0px);
}

.portfolio-item.no-overlay:hover .portfolio-description span,
.portfolio-item.no-overlay:hover .portfolio-description p,
.portfolio-item.no-overlay:hover .grid-description span,
.portfolio-item.no-overlay:hover .grid-description p,
.grid-item.no-overlay:hover .portfolio-description span,
.grid-item.no-overlay:hover .portfolio-description p,
.grid-item.no-overlay:hover .grid-description span,
.grid-item.no-overlay:hover .grid-description p {
    opacity: 1;
}

.portfolio-item.overlay-links:hover .portfolio-description,
.portfolio-item.overlay-links:hover .grid-description,
.grid-item.overlay-links:hover .portfolio-description,
.grid-item.overlay-links:hover .grid-description {
    transform: none;
    opacity: 1;
    visibility: visible;
}

.portfolio-item.overlay-links:hover .portfolio-description h3,
.portfolio-item.overlay-links:hover .grid-description h3,
.grid-item.overlay-links:hover .portfolio-description h3,
.grid-item.overlay-links:hover .grid-description h3 {
    transform: none;
}

.portfolio-item.overlay-links:hover .portfolio-description span,
.portfolio-item.overlay-links:hover .portfolio-description p,
.portfolio-item.overlay-links:hover .grid-description span,
.portfolio-item.overlay-links:hover .grid-description p,
.grid-item.overlay-links:hover .portfolio-description span,
.grid-item.overlay-links:hover .portfolio-description p,
.grid-item.overlay-links:hover .grid-description span,
.grid-item.overlay-links:hover .grid-description p {
    opacity: 1;
}

.portfolio-item.overlay-links .portfolio-description,
.portfolio-item.overlay-links .grid-description,
.grid-item.overlay-links .portfolio-description,
.grid-item.overlay-links .grid-description {
    opacity: 1;
    padding: 20px;
    position: relative;
    transform: none;
    visibility: inherit;
    width: 100%;
}

.portfolio-item.overlay-links .portfolio-description a,
.portfolio-item.overlay-links .grid-description a,
.grid-item.overlay-links .portfolio-description a,
.grid-item.overlay-links .grid-description a {
    margin: 0px;
}

.portfolio-item.overlay-links .portfolio-description h3,
.portfolio-item.overlay-links .grid-description h3,
.grid-item.overlay-links .portfolio-description h3,
.grid-item.overlay-links .grid-description h3 {
    color: #484848;
}

.portfolio-item.overlay-links .portfolio-description span,
.portfolio-item.overlay-links .grid-description span,
.grid-item.overlay-links .portfolio-description span,
.grid-item.overlay-links .grid-description span {
    color: #484848;
    opacity: 1;
}

.portfolio-item.overlay-links .portfolio-description p,
.portfolio-item.overlay-links .grid-description p,
.grid-item.overlay-links .portfolio-description p,
.grid-item.overlay-links .grid-description p {
    color: #484848;
    opacity: 1;
}

.portfolio-item.revert .portfolio-slider .flickity-viewport:after,
.portfolio-item.revert .portfolio-image:after,
.portfolio-item.revert .portfolio-video:after,
.portfolio-item.revert .grid-slider .flickity-viewport:after,
.portfolio-item.revert .grid-image:after,
.portfolio-item.revert .grid-video:after,
.grid-item.revert .portfolio-slider .flickity-viewport:after,
.grid-item.revert .portfolio-image:after,
.grid-item.revert .portfolio-video:after,
.grid-item.revert .grid-slider .flickity-viewport:after,
.grid-item.revert .grid-image:after,
.grid-item.revert .grid-video:after {
    opacity: 0.5;
}

.portfolio-item.revert .portfolio-description,
.portfolio-item.revert .grid-description,
.portfolio-item.revert .portfolio-links,
.grid-item.revert .portfolio-description,
.grid-item.revert .grid-description,
.grid-item.revert .portfolio-links {
    transform: translate3d(0, -50%, 0);
    opacity: 1;
    visibility: visible;
}

.portfolio-item.revert .portfolio-description h3,
.portfolio-item.revert .grid-description h3,
.portfolio-item.revert .portfolio-links h3,
.grid-item.revert .portfolio-description h3,
.grid-item.revert .grid-description h3,
.grid-item.revert .portfolio-links h3 {
    transform: translateY(0px);
}

.portfolio-item.revert .portfolio-description span,
.portfolio-item.revert .portfolio-description p,
.portfolio-item.revert .grid-description span,
.portfolio-item.revert .grid-description p,
.portfolio-item.revert .portfolio-links span,
.portfolio-item.revert .portfolio-links p,
.grid-item.revert .portfolio-description span,
.grid-item.revert .portfolio-description p,
.grid-item.revert .grid-description span,
.grid-item.revert .grid-description p,
.grid-item.revert .portfolio-links span,
.grid-item.revert .portfolio-links p {
    opacity: 1;
}

.portfolio-item.revert:hover .portfolio-slider .flickity-viewport:after,
.portfolio-item.revert:hover .portfolio-image:after,
.portfolio-item.revert:hover .portfolio-video:after,
.portfolio-item.revert:hover .grid-slider .flickity-viewport:after,
.portfolio-item.revert:hover .grid-image:after,
.portfolio-item.revert:hover .grid-video:after,
.grid-item.revert:hover .portfolio-slider .flickity-viewport:after,
.grid-item.revert:hover .portfolio-image:after,
.grid-item.revert:hover .portfolio-video:after,
.grid-item.revert:hover .grid-slider .flickity-viewport:after,
.grid-item.revert:hover .grid-image:after,
.grid-item.revert:hover .grid-video:after {
    opacity: 0;
}

.portfolio-item.revert:hover .portfolio-description,
.portfolio-item.revert:hover .grid-description,
.portfolio-item.revert:hover .portfolio-links,
.grid-item.revert:hover .portfolio-description,
.grid-item.revert:hover .grid-description,
.grid-item.revert:hover .portfolio-links {
    transform: translate3d(0, -50%, 0);
    opacity: 0;
}

.portfolio-item.revert:hover .portfolio-description h3,
.portfolio-item.revert:hover .grid-description h3,
.portfolio-item.revert:hover .portfolio-links h3,
.grid-item.revert:hover .portfolio-description h3,
.grid-item.revert:hover .grid-description h3,
.grid-item.revert:hover .portfolio-links h3 {
    transform: translateY(0px);
}

.portfolio-item.revert:hover .portfolio-description span,
.portfolio-item.revert:hover .portfolio-description p,
.portfolio-item.revert:hover .grid-description span,
.portfolio-item.revert:hover .grid-description p,
.portfolio-item.revert:hover .portfolio-links span,
.portfolio-item.revert:hover .portfolio-links p,
.grid-item.revert:hover .portfolio-description span,
.grid-item.revert:hover .portfolio-description p,
.grid-item.revert:hover .grid-description span,
.grid-item.revert:hover .grid-description p,
.grid-item.revert:hover .portfolio-links span,
.grid-item.revert:hover .portfolio-links p {
    opacity: 0;
}

.portfolio-item.shadow,
.grid-item.shadow {
    box-shadow: none !important;
}

.portfolio-item.shadow .portfolio-item-wrap,
.portfolio-item.shadow .grid-item-wrap,
.grid-item.shadow .portfolio-item-wrap,
.grid-item.shadow .grid-item-wrap {
    box-shadow: 0 7px 20px -10px rgba(171, 171, 171, 0.6);
}

.portfolio-item.outline .portfolio-item-wrap,
.portfolio-item.outline .grid-item-wrap,
.grid-item.outline .portfolio-item-wrap,
.grid-item.outline .grid-item-wrap {
    border: 1px solid #ebebeb;
    padding: 1px;
}

.portfolio-item.light-bg .portfolio-item-wrap,
.portfolio-item.light-bg .grid-item-wrap,
.grid-item.light-bg .portfolio-item-wrap,
.grid-item.light-bg .grid-item-wrap {
    background-color: #fff;
    border: 1px solid #f3f3f3;
}

.portfolio-item.light-bg .portfolio-item-wrap .portfoio-description,
.portfolio-item.light-bg .grid-item-wrap .portfoio-description,
.grid-item.light-bg .portfolio-item-wrap .portfoio-description,
.grid-item.light-bg .grid-item-wrap .portfoio-description {
    margin-bottom: 20px;
}

.portfolio-item.grey-bg .portfolio-item-wrap,
.portfolio-item.grey-bg .grid-item-wrap,
.grid-item.grey-bg .portfolio-item-wrap,
.grid-item.grey-bg .grid-item-wrap {
    background-color: #f3f3f3;
}

.portfolio-item.grey-bg .portfolio-item-wrap .portfoio-description,
.portfolio-item.grey-bg .grid-item-wrap .portfoio-description,
.grid-item.grey-bg .portfolio-item-wrap .portfoio-description,
.grid-item.grey-bg .grid-item-wrap .portfoio-description {
    margin-bottom: 20px;
}

.alterneting-size .portfolio-item:nth-child(2n) .portfolio-item-wrap,
.alterneting-size .grid-item:nth-child(2n) .grid-item-wrap {
    transform: scale(0.75);
}

.portfolio-slider,
.grid-slider {
    width: 100%;
}

.portfolio-slider.shadow,
.grid-slider.shadow {
    box-shadow: 10px 10px 10px rgba(235, 235, 235, 0.5);
}

.portfolio-slider.outline,
.grid-slider.outline {
    border: 3px solid #fff;
}


/*Sidebar version*/

.content.col-lg-9 .portfolio-item .portfolio-description h3,
.content.col-lg-9 .portfolio-item .grid-description h3,
.content.col-lg-9 .grid-item .portfolio-description h3,
.content.col-lg-9 .grid-item .grid-description h3 {
    font-size: 13px;
    line-height: 14px;
}

.container-fluid .content.col-lg-9 .portfolio-item .portfolio-description h3,
.container-fluid .content.col-lg-9 .portfolio-item .grid-description h3,
.container-fluid .content.col-lg-9 .grid-item .portfolio-description h3,
.container-fluid .content.col-lg-9 .grid-item .grid-description h3 {
    font-size: 16px;
    line-height: 22px;
}

.sidebar-both .portfolio-item .portfolio-description h3,
.sidebar-both .portfolio-item .grid-description h3,
.sidebar-both .grid-item .portfolio-description h3,
.sidebar-both .grid-item .grid-description h3 {
    font-size: 13px;
    line-height: 14px;
}

.sidebar-both .portfolio-item .portfolio-description p,
.sidebar-both .portfolio-item .portfolio-description span,
.sidebar-both .portfolio-item .grid-description p,
.sidebar-both .portfolio-item .grid-description span,
.sidebar-both .grid-item .portfolio-description p,
.sidebar-both .grid-item .portfolio-description span,
.sidebar-both .grid-item .grid-description p,
.sidebar-both .grid-item .grid-description span {
    font-size: 12px;
}


/*Portfolio page*/

.portfolio-content {
    position: relative;
}

.portfolio-content img {
    width: 100%;
}

.portfolio-attributes {
    color: #484848;
    font-size: 12px;
    overflow: hidden;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.portfolio-attributes a:not(.btn) {
    color: #484848;
}

.portfolio-attributes .attribute {
    float: left;
    margin-bottom: 20px;
    padding-right: 20px;
    text-align: left;
    width: auto;
}

.portfolio-attributes .attribute strong {
    opacity: 0.6;
    border-bottom: 1px solid rgba(31, 31, 31, 0.05);
    display: block;
    width: auto;
    margin-bottom: 10px;
    font-size: 11px;
}

.portfolio-attributes.style1 .attribute {
    display: inline-flex;
    float: none;
    margin-bottom: 6px;
}

.portfolio-attributes.style1 .attribute strong {
    border: 0;
    margin-right: 8px;
    width: auto;
}

.portfolio-attributes.style2 .attribute {
    float: none;
    width: 100%;
}

.portfolio-attributes.style2 .attribute strong {
    border: 0;
    width: 100%;
}

.project-description {
    margin-bottom: 40px;
}

.project-description h2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
}

.project-description h3 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
}


/*Portfolio Ajax  Page*/

.portfolio-ajax-page [class*="col-"] {
    padding: 0;
}

.portfolio-ajax-page .project-description {
    padding: 24px 40px;
    margin-bottom: 0;
}

.portfolio-ajax-page .project-description>p {
    padding-bottom: 6px;
}

.portfolio-ajax-page .project-description .portfolio-attributes {
    margin-top: 20px;
}

.portfolio-ajax-page .project-description .portfolio-attributes .attribute {
    margin-bottom: 0;
    width: 100%;
}

.portfolio-ajax-page .project-description .portfolio-attributes .attribute>strong {
    min-width: 68px;
}


/*  Blog & comments */


/* ----------------------------------------------------------------
	Blog
-----------------------------------------------------------------*/

.post-item {
    float: left;
    width: 100%;
    height: auto;
    padding: 0 0 20px 0;
    /*Styles*/
    /*Types*/
}

.post-item .post-item-wrap {
    background-color: #ffffff;
    position: relative;
}

.post-item img {
    width: 100%;
    height: auto;
}

.post-item .post-image,
.post-item .post-slider,
.post-item .post-video,
.post-item .post-audio,
.post-item .post-quote-img {
    margin: 0;
    position: relative;
    padding: 0;
}

.team-member .team-image .keynote-speaker,
.post-item .post-image .post-meta-category,
.post-item .post-slider .post-meta-category,
.post-item .post-video .post-meta-category,
.post-item .post-audio .post-meta-category,
.post-item .post-quote-img .post-meta-category {
    background-color: #d51f26;
    color: #ffffff;
    font-size: 13px;
    padding: 2px 16px;
    border-radius: 50px;
    position: absolute;
    right: 10px;
    top: 11px;
    z-index: 3;
    margin-right: 0;
}

.team-member .team-image .keynote-speaker_1 {
    background-color: #d51f26;
    color: #ffffff;
    font-size: 18px !important;
    padding: 8px 22px !important;
    border-radius: 40px;
    right: 10px;
    top: 11px;
    z-index: 3;
    margin-right: 0;
}

.team-member .team-image .keynote-speaker i,
.post-item .post-image .post-meta-category i,
.post-item .post-slider .post-meta-category i,
.post-item .post-video .post-meta-category i,
.post-item .post-audio .post-meta-category i,
.post-item .post-quote-img .post-meta-category i {
    margin-right: 4px;
}

.team-member .team-image .keynote-speaker span,
.post-item .post-image .post-meta-category a,
.post-item .post-slider .post-meta-category a,
.post-item .post-video .post-meta-category a,
.post-item .post-audio .post-meta-category a,
.post-item .post-quote-img .post-meta-category a {
    color: #ffffff;
}

.post-item .post-quote-img .post-meta-category {
    background-color: #ffffff;
    color: #1290c6;
}

.post-item .post-quote-img .post-meta-category a {
    color: #1290c6;
}

.post-item .post-video video,
.post-item .post-video iframe {
    width: 100%;
    background-color: #000000;
}

.post-item .post-audio>a>img {
    margin-bottom: -28px;
}

.post-item .post-audio audio,
.post-item .post-audio iframe {
    width: 100%;
}

.post-item .post-audio audio {
    margin-bottom: -6px;
}

.post-item .post-item-description {
    padding: 24px;
    line-height: 28px;
    color: #676767;
}

.post-item .post-item-description>h2,
.post-item .post-item-description>h2>a {
    font-size: 18px;
    letter-spacing: -0.1px;
    line-height: 23px;
    margin-bottom: 16px;
    color: #444;
    font-weight: 600;
}

.post-item .post-item-description .post-meta-date,
.post-item .post-item-description .post-meta-comments,
.post-item .post-item-description .post-meta-category {
    color: #C2C2C2;
    position: relative;
    top: -4px;
    font-size: 12px;
    margin-right: 8px;
}

.post-item .post-item-description .post-meta-date i,
.post-item .post-item-description .post-meta-comments i,
.post-item .post-item-description .post-meta-category i {
    margin-right: 4px !important;
}

.post-item .post-item-description .post-meta-date a,
.post-item .post-item-description .post-meta-comments a,
.post-item .post-item-description .post-meta-category a {
    color: #C2C2C2;
}

.post-item .post-item-description .post-post-likes {
    float: right;
    font-size: 14px;
    color: #aaa;
}

.post-item .post-item-description .post-post-likes i {
    margin-right: 8px;
    color: #aaa;
}

.post-item.shadow {
    box-shadow: none;
}

.post-item.shadow .post-item-wrap {
    box-shadow: 0 1px 10px #efefef !important;
}

.post-item.grey-bg .post-item-wrap {
    background-color: #f7f7f7;
}

.post-item.border {
    border: none !important;
}

.post-item.border .post-item-wrap {
    border: 1px solid #f2f2f2;
}

.post-item.border>.post-item-wrap>.post-item-description {
    padding: 24px !important;
    width: 100% !important;
}

.post-item.quote .post-item-wrap {
    background-color: #1290c6;
    border-radius: 4px;
    border-width: 0;
}

.post-item.quote .post-quote-img img {
    width: auto;
    margin-left: 40px;
    margin-top: 40px;
}

.post-item.quote .post-item-description {
    padding: 40px;
}

.post-item.quote .post-item-description .post-meta-date,
.post-item.quote .post-item-description .post-meta-comments,
.post-item.quote .post-item-description .post-meta-category {
    color: #ffffff;
    opacity: 0.8;
}

.post-item.quote .post-item-description .post-meta-date a,
.post-item.quote .post-item-description .post-meta-date p,
.post-item.quote .post-item-description .post-meta-comments a,
.post-item.quote .post-item-description .post-meta-comments p,
.post-item.quote .post-item-description .post-meta-category a,
.post-item.quote .post-item-description .post-meta-category p {
    color: #ffffff;
}

.post-item.quote .post-item-description>h3,
.post-item.quote .post-item-description>h3>a,
.post-item.quote .post-item-description a,
.post-item.quote .post-item-description p,
.post-item.quote .post-item-description .item-link {
    color: #ffffff;
}

.post-item.quote .post-item-description .post-author {
    font-size: 14px;
    height: 40px;
}

.post-item.quote .post-item-description .post-author>img {
    height: 28px;
    margin-right: 8px;
    width: 28px;
}

.post-items-transparent .post-item .post-item-wrap {
    border: 0;
    background-color: transparent;
}

.post-items-transparent .post-item .post-item-wrap .post-item-description>p,
.post-items-transparent .post-item .post-item-wrap .post-item-description .post-meta-date,
.post-items-transparent .post-item .post-item-wrap .post-item-description .post-meta-comments,
.post-items-transparent .post-item .post-item-wrap .post-item-description .post-meta-category {
    opacity: .7;
}


/*Single Post*/

.single-post .post-item {
    /*padding: 0 0 20px 0;*/
}

.single-post .post-item img {
    width: 100%;
    height: auto;
}

.single-post .post-item .post-item-description {
    padding: 22px 0;
    line-height: 28px;
    color: #676767;
}

.single-post .post-item .post-item-description p {
    font-size: 16px;
}

.single-post .post-item .post-item-description>h2,
.single-post .post-item .post-item-description>h2>a {
    font-size: 34px;
    letter-spacing: 0;
    line-height: 38px;
    margin-bottom: 12px;
    margin-top: 8px;
    color: #1f1f1f;
}

.single-post .post-item .post-item-description .post-meta-date,
.single-post .post-item .post-item-description .post-meta-comments,
.single-post .post-item .post-item-description .post-meta-category {
    color: #777;
    font-size: 13px;
    margin-right: 16px;
}

.single-post .post-item .post-item-description .post-meta-date a,
.single-post .post-item .post-item-description .post-meta-comments a,
.single-post .post-item .post-item-description .post-meta-category a {
    color: #777;
}

.single-post .post-item .post-item-description .post-meta-share {
    float: right;
}

.single-post .post-item .post-item-description .post-meta-share .btn {
    display: inline-block;
    margin-right: 0;
}

.single-post .post-item .post-item-description .post-meta {
    border-bottom: 1px solid #f3f3f3;
    clear: both;
    display: block;
    margin-bottom: 26px;
    padding: 12px 0;
    position: relative;
    width: 100%;
}

.single-post .post-item .post-tags {
    display: block;
    margin-bottom: 40px;
    position: relative;
    width: 100%;
}

.single-post .post-item .post-tags a {
    font-weight: 400;
    color: #484848;
    border: 1px solid #e6e8eb;
    border-radius: 4px;
    font-size: 12px;
    margin-bottom: 10px;
    padding: 3px 10px;
    background-color: #f8f9fa;
}


/*Thumbnail Version*/

.post-thumbnails a {
    color: #484848;
}

.post-thumbnails .post-item {
    width: 100%;
    padding: 0 0 20px 0;
}

.post-thumbnails .post-item .post-image,
.post-thumbnails .post-item .post-slider,
.post-thumbnails .post-item .post-video,
.post-thumbnails .post-item .post-audio {
    float: left;
    width: 46%;
}

.post-thumbnails .post-item:not(.quote) .post-item-description {
    float: left;
    padding-top: 0;
    width: 54% !important;
}

#blog .blog .post-item {
    width: 25%;
    padding: 0 20px 20px 0;
}


/*Single Page*/

.post-post-title h1 {
    font-size: 48px;
    letter-spacing: -.03em;
    line-height: 1;
    font-weight: 700;
}

.post-image {
    margin-bottom: 20px;
}

.post-image img {
    width: 100%;
}

.post-metaa {
    margin-bottom: 20px;
    min-height: 60px;
}

.post-metaa div {
    border-right: 1px solid #f6f6f6;
    float: left;
    margin-right: 18px;
    padding: 8px 18px 8px 0;
}

.post-metaa :last-child {
    border-right: 0;
}

.post-metaa .social-icons {
    margin-top: 8px;
}

.post-metaa .post-comments i,
.post-metaa .post-shares i {
    margin-right: 4px;
    font-size: 20px;
    float: left;
}

.post-metaa .post-comments .post-shares-number,
.post-metaa .post-comments .post-comments-number,
.post-metaa .post-shares .post-shares-number,
.post-metaa .post-shares .post-comments-number {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    display: block;
}

.post-metaa .post-comments .post-shares-label,
.post-metaa .post-comments .post-comments-label,
.post-metaa .post-shares .post-shares-label,
.post-metaa .post-shares .post-comments-label {
    color: #aaa;
    font-size: 12px;
    text-transform: uppercase;
    display: block;
}

.post-author {
    border-top: 1px solid #eee;
    font-size: 14px;
    height: 40px;
    margin-top: 15px;
    padding-bottom: 0;
    padding-top: 16px;
}

.post-author a {
    color: #484848;
}

.post-author p {
    position: relative;
}

.post-author>img {
    border-radius: 50%;
    height: 28px;
    float: left;
    margin-right: 8px;
    width: 28px;
}

.post-content {
    clear: both;
    display: block;
    padding: 0 40px 0 0;
}

.sidebar>h3:nth-of-type(2) {
    margin-top: 17px;
}


/* ----------------------------------------------------------------------
   GRID ARTICLE BOXES
-------------------------------------------------------------------------*/

.grid-articles {
    position: relative;
}

.grid-articles .post-entry {
    float: left;
    width: 25%;
    position: relative;
}

.grid-articles .post-entry:first-child {
    width: 50%;
}

.grid-articles.carousel .post-entry:first-child {
    width: 100%;
}

.grid-articles.grid-articles-v2 .post-entry:nth-child(1),
.grid-articles.grid-articles-v2 .post-entry:nth-child(2) {
    width: 50%;
}

.grid-articles .post-entry-overlay {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.grid-articles .post-entry .post-entry-meta {
    height: auto;
    bottom: 0;
    left: 0;
    padding: 0 30px 30px;
    position: absolute;
    width: 100%;
    z-index: 2;
}

.grid-articles .post-entry:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.9) 100%);
    opacity: 0.4;
    transition: opacity 0.4s ease;
    z-index: 1;
}

.grid-articles .post-entry:before {
    content: "";
    height: auto;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.grid-articles .post-entry:hover:before {
    opacity: 1;
    z-index: 1;
}

.grid-articles .post-entry .post-entry-meta .post-entry-meta-category {
    margin-bottom: 10px;
}

.grid-articles .post-entry .post-entry-meta .post-entry-meta-title h2,
.grid-articles .post-entry .post-entry-meta .post-entry-meta-title h2 a {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
    margin: 0;
}

.grid-articles .post-entry:first-child .post-entry-meta .post-entry-meta-title h2,
.grid-articles .post-entry:first-child .post-entry-meta .post-entry-meta-title h2 a,
.grid-articles.grid-articles-v2 .post-entry:nth-child(2) .post-entry-meta .post-entry-meta-title h2 a {
    font-size: 22px;
}


/*with space*/

.grid-articles.grid-articles-space .post-entry .post-entry-overlay {
    border-color: #fff;
    border-style: solid;
    border-width: 4px;
}

.grid-articles.grid-articles-space .post-entry:first-child .post-entry-overlay {
    border-width: 4px 4px 4px 0px;
}

.grid-articles.grid-articles-space .post-entry:nth-child(3) .post-entry-overlay,
.grid-articles.grid-articles-space .post-entry:nth-child(5) .post-entry-overlay {
    border-width: 4px 0 4px 4px;
}

.grid-articles .post-date {
    color: #fff;
    opacity: 0.6;
}

.grid-articles .post-entry-meta .product-description,
.post-entry-meta .product-description a {
    color: #fff !important;
}

@media (max-width: 1024px) {
    /*	Grid post*/
    .grid-articles:not(.post-carousel) .post-entry {
        width: 50%;
    }
    .grid-articles:not(.post-carousel) .post-entry:first-child {
        width: 100%;
    }
    .grid-articles:not(.post-carousel) .post-entry:first-child .post-entry-meta .post-entry-meta-title h2 {
        font-size: 20px;
    }
    .grid-articles:not(.post-carousel) .post-entry .post-entry-meta .post-entry-meta-title h2 {
        font-size: 13px;
    }
    .grid-articles:not(.post-carousel) .post-entry .post-entry-overlay {
        border-width: 0 !important;
    }
    .post-thumbnails .post-item:not(.quote) .post-item-description {
        width: 100% !important;
    }
}


/*---------------------------------------------------
      POST NEWS THUMBNAIL BOXES
 -------------------------------------------------*/

.post-thumbnail .post-thumbnail-entry>img {
    margin-bottom: 14px;
    max-height: 240px;
    width: 100%;
}

.post-thumbnail .post-thumbnail-entry .post-thumbnail-content p {
    margin-bottom: 6px;
}

.post-thumbnail~.post-thumbnail-list {
    border-top: 1px solid #F7F9FB;
}

.post-thumbnail~.post-thumbnail-list .post-thumbnail-entry .post-thumbnail-content a {
    font-weight: 400;
    opacity: 0.7;
    line-height: 20px;
}

.post-thumbnail~.post-thumbnail-list .post-thumbnail-entry .post-thumbnail-content a:hover {
    opacity: 1;
}


/*Post thumbnail list*/

.post-thumbnail-list {
    position: relative;
}

.post-thumbnail-list .post-thumbnail-entry img+.post-thumbnail-content {
    padding-left: 90px;
}

.post-thumbnail-list .post-thumbnail-entry>img {
    float: left;
    display: block;
    height: 60px;
    width: 80px;
    margin-right: 16px;
    border-radius: 2px;
}

.post-thumbnail-list .post-thumbnail-entry .post-thumbnail-content a {
    font-size: 14px;
    font-weight: 500;
    margin: -4px 0 0;
}

.post-thumbnail-entry {
    border-bottom: 1px solid #F7F9FB;
    float: left;
    margin-bottom: 16px;
    padding-bottom: 10px;
    position: relative;
    clear: both;
}

.post-thumbnail-entry a {
    color: #484848;
}

.post-thumbnail-entry:last-child {
    border-bottom: 0;
}

.post-thumbnail-entry .post-thumbnail-content>a {
    display: block;
}

.post-thumbnail-entry .post-thumbnail-content .post-date,
.post-thumbnail-entry .post-thumbnail-content .post-category {
    color: #aaa;
    font-size: 13px;
}

.post-thumbnail-entry .post-thumbnail-content .post-date i,
.post-thumbnail-entry .post-thumbnail-content .post-category i {
    margin-right: 2px;
}

.post-thumbnail-entry .post-thumbnail-content .post-date~.post-category {
    margin-left: 6px;
}

.post-thumbnail-entry .post-thumbnail-content .post-thumbnail-list .post-thumbnail-entry .post-thumbnail-content a {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    line-height: 12px !important;
}


/*Responsive*/

@media (max-width: 767px) {
    .post-thumbnails .post-item {
        width: 100%;
    }
    .post-thumbnails .post-item .post-image,
    .post-thumbnails .post-item .post-slider,
    .post-thumbnails .post-item .post-video,
    .post-thumbnails .post-item .post-audio {
        float: none;
        width: 100%;
    }
    .post-thumbnails .post-item:not(.quote) .post-item-description {
        padding-top: 24px;
        float: none;
        width: 100%;
    }
}


/*------------------------------------------------------
     Comments
------------------------------------------------------*/

.comments {
    margin: 0;
}

.comments .comment_number {
    font-size: 18px;
    margin: 0 0 30px;
}

.comments .comment_number span {
    color: #1290c6;
}

.comments .comment {
    padding: 10px 0;
    background-color: #fff;
}

.comments .comment .image {
    border-radius: 65px;
    display: block;
    float: left;
    height: 65px;
    overflow: hidden;
    width: 65px;
}

.comments .comment .text {
    padding: 0 0 0 86px;
    min-height: 80px;
    position: relative;
}

.comments .comment .text .name {
    display: block;
    color: #303030;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: -4px;
}

.comments .comment .comment_date {
    color: #888;
    font-size: 12px;
}

.comments .comment .comment_desig {
    color: #888;
    font-size: 12px;
}

.comments .comment .text .comment-reply-link {
    color: #1290c6;
    font-size: 9px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0 0 0 5px;
    opacity: .7;
}

.comments .comment .text .comment-reply-link:hover {
    opacity: 1;
}

.comments .comment .text .text_holder {
    display: block;
    margin: 3px 0 0 0;
}

.comments .comment .comment {
    margin-left: 90px;
}

.post-item .comments {
    padding: 40px 0;
    border-top: 1px solid #eee;
}

.respond-form {
    padding-top: 40px;
}

.respond-comment {
    font-size: 18px;
    margin: 0 0 30px;
}

.respond-comment span {
    color: #1290c6;
}

@media (max-width: 1024px) {
    .comments {
        padding: 22px 0 30px;
        margin: 0;
    }
    .comments .comment .image {
        border-radius: 48px;
        height: 48px;
        width: 48px;
        margin-right: 14px;
    }
    .comments .comment .text {
        padding: 0;
    }
    .comments .comment .comment {
        margin-left: 30px;
    }
}


/*  Shop */

.shop {
    position: relative;
}

.product {
    margin-bottom: 40px;
    position: relative;
    display: block;
}

.product a:not(.btn) {
    color: #484848;
}

.product .product-image {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
}

.product .product-image>a,
.product .product-image img {
    display: block;
    width: 100%;
    height: 100%;
}

.product .product-image>a,
.product .product-wishlist a {
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

.product .product-image>a:nth-of-type(2) {
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    position: absolute;
}

.product .product-image:hover>a:nth-of-type(2) {
    opacity: 1;
    z-index: 2;
}

.product .product-wishlist a {
    border: 2px solid #333;
    border-radius: 50%;
    height: 30px;
    left: 12px;
    line-height: 28px;
    position: absolute;
    text-align: center;
    top: 12px;
    width: 30px;
    z-index: -1;
    opacity: 0;
}

.product:hover .product-wishlist,
.product:hover .product-wishlist a {
    z-index: 3;
    opacity: 1;
}

.product .product-description {
    padding: 8px 0 10px;
}

.product .product-title {
    max-width: 80%;
}

.product .product-title h3,
.product .product-title h3 a {
    font-size: 18px;
    margin-bottom: 0;
}

.product .product-category {
    display: block;
    opacity: 0.5;
}

.product .product-price {
    width: 26%;
    float: right;
    margin-bottom: 0;
    margin-top: -26px;
    text-align: right;
}

.product .product-price ins {
    color: #444;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 4px;
    text-decoration: none;
}

.product .product-price del {
    display: block;
    font-size: 12px;
    font-weight: 400;
    margin: -20px 0 -4px;
    opacity: 0.6;
}

.product .product-rate {
    height: 23px;
    float: left;
    color: #FFC300;
}

.product .product-reviews,
.product .product-reviews a {
    color: #73848e;
    float: right;
    font-size: 13px;
}

.product .product-sale,
.product .product-new,
.product .product-hot,
.product .product-out-stock {
    border-radius: 50%;
    color: #ffffff;
    font-size: 11px;
    font-weight: 600;
    height: 42px;
    right: 10px;
    line-height: 42px;
    position: absolute;
    text-align: center;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.14);
    top: 10px;
    width: 42px;
    z-index: 5;
}

.product .product-sale,
.product .product-sale-off {
    background: #ffc300;
}

.product .product-new {
    background: #00c0e9;
}

.product .product-hot {
    background: #d6284b;
}

.product .product-out-stock {
    background: #a5a5a5 none repeat scroll 0 0;
    border-radius: 4px;
    padding: 0 18px;
    width: auto;
}

.product .product-sale-off {
    border-radius: 12px 0 0;
    bottom: 0px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    height: 42px;
    line-height: 42px;
    position: absolute;
    right: 0px;
    text-align: center;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.14);
    width: 88px;
    z-index: 5;
}

.product .product-overlay {
    position: absolute;
    width: 100%;
    height: 44px;
    line-height: 44px;
    top: auto;
    bottom: -44px;
    left: 0;
    z-index: 6;
    transition: bottom .3s ease;
}

.product .product-overlay a {
    transition: bottom .3s ease;
    color: #fff;
    display: block;
    font-size: 13px;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
}

.product .product-overlay a:hover {
    background-color: #111;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.product:hover .product-overlay {
    bottom: 0px;
}


/*Grid Columns*/

.grid-5-columns .product .product-title h3,
.grid-5-columns .product .product-title h3 a,
.grid-5-columns .product .product-price ins,
.grid-6-columns .product .product-title h3,
.grid-6-columns .product .product-title h3 a,
.grid-6-columns .product .product-price ins {
    font-size: 14px;
}

.grid-5-columns .product .product-reviews,
.grid-5-columns .product .product-reviews a,
.grid-6-columns .product .product-reviews,
.grid-6-columns .product .product-reviews a {
    font-size: 11px;
    float: left;
}

.grid-5-columns .product .product-reviews,
.grid-5-columns .product .product-reviews a {
    float: right;
}

.shop-category {
    position: relative;
}

.shop-category-box {
    position: relative;
    margin-bottom: 20px;
}

.shop-category-box img {
    width: 100%;
}

.shop-category-box .shop-category-box-title {
    background-color: rgba(255, 255, 255, 0.9);
    bottom: 10px;
    padding: 4px 0;
    position: absolute;
    text-align: center;
    width: 100%;
}

.shop-category-box-title h6 {
    margin-bottom: 0;
}

.shop-cart th,
.shop-cart .table td {
    vertical-align: middle;
}

.shop-cart .cart-product-remove {
    text-align: center;
}

.shop-cart .cart-product-thumbnail>a {
    color: #484848;
    float: left;
    margin-right: 12px;
}

.shop-cart .cart-product-thumbnail img {
    max-width: 52px;
}

.shop-cart .cart-product-thumbnail .cart-product-thumbnail-name {
    padding: 24px;
}

.cart-product-description {
    max-width: 200px;
    font-size: 12px;
}

.cart-product-description span {
    display: block;
}

.cart-product-quantity .minus,
.cart-product-quantity .plus {
    background-color: #ffffff;
    border: 1px solid #eee;
    margin: 0 -4px;
    padding: 5px 12px;
}

.cart-product-quantity .qty {
    border: 1px solid #eee;
    max-width: 40px;
    padding: 5px;
    text-align: center;
}

.payment-method td {
    padding: 12px 20px !important;
}

.product-page:not(.widget-shop) .product-title h3 {
    font-size: 28px;
    margin-bottom: 0;
}

.product-size {
    display: block;
    list-style: outside none none;
    margin: 0 20px 15px 0;
    overflow: hidden;
    padding-left: 0;
}

.product-size li {
    float: left;
    margin: 0 -1px 0 0;
}

.product-size li label span {
    cursor: pointer;
    display: block;
    font-size: 1.2em;
    font-weight: 400;
    height: 40px;
    line-height: 37px;
    margin: 0;
    text-align: center;
    transition: all 0.3s ease 0s;
    width: 40px;
}

.product-size li label input {
    left: -9000px;
    position: absolute;
}

.product-size li label span {
    border: 1px solid #eee;
    color: #909090;
}

.product-size li label span:hover {
    background-color: #eee;
}

.product-size li label input:checked+span {
    background-color: #eee;
    border-color: #eee;
}

.product .comment .product-rate {
    float: right;
}

.ajax-quick-view .product {
    margin-bottom: 0px;
    margin-top: 20px;
}

.widget-shop {
    position: relative;
}

.widget-shop .product {
    position: relative;
    border-bottom: 1px solid #eee;
    float: left;
    margin-bottom: 12px;
    min-height: 96px;
    padding-bottom: 12px;
}

.widget-shop .product:last-child {
    border-bottom: 0;
    margin-bottom: -4px;
    padding-bottom: 0;
}

.widget-shop .product .product-description {
    padding-top: 0px;
}

.widget-shop .product .product-image {
    float: left;
    padding-right: 10px;
    width: 26%;
}

.widget-shop .product .product-description {
    display: table-cell;
    padding-bottom: 0;
    vertical-align: top;
    width: 74%;
}

.widget-shop .product .product-title,
.widget-shop .product .product-category {
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: -8px;
}

.widget-shop .product .product-title h3,
.widget-shop .product .product-title h3 a,
.widget-shop .product .product-category h3,
.widget-shop .product .product-category h3 a {
    font-size: 15px;
    line-height: 18px;
}

.widget-shop .product .product-category {
    margin-top: -6px;
}

.widget-shop .product .product-price {
    clear: both;
    float: none;
    margin-bottom: 0;
    margin-top: 0;
    text-align: left;
}

.widget-shop .product .product-price ins {
    font-size: 14px;
    margin-bottom: 4px;
}

.widget-shop .product .product-price del {
    display: unset;
    font-size: 12px;
    margin: -8px 5px -8px 0;
}

.widget-tweeter .list-tweets {
    padding-left: 0;
}

.widget-categories .list {
    padding: 0;
}

#footer .widget-categories a {
    color: #888;
}


/*
        .twitter-widget ul {
            margin: 0;
            padding: 0;
        }
        .twitter-widget li {
            list-style: none;
            clear: both;
        }
        .twitter-widget li img,
        .twitter-widget li .tweets_txt {
            float: left;
            margin-bottom: 10px;
        }
        .twitter-widget li img {
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
        }
        .twitter-widget li .tweets_txt {
            width: 350px;
            margin-left: 10px;
        }
        .twitter-widget li .tweets_txt span {
            font-style: italic;
            color: #666;
            display: block;
        }
*/

.shop-promo-box {
    min-height: 360px;
    background-repeat: no-repeat;
    padding: 50px;
    border: 8px solid #eee;
}

.shop-promo-box>h2 {
    font-weight: 800;
    margin-bottom: 0;
}


/*  Forum */


/* ----------------------------------------------------------------
    Forum
-----------------------------------------------------------------*/

.forum {
    border-radius: 4px;
    padding: 20px 26px;
    background-color: #fff;
}

.forum .fa {
    width: 1em;
    text-align: center;
}

.forum table th {
    width: 6em;
    width: 14em;
}

.forum thead tr>th {
    background-color: #1290c6;
    color: #fff;
    border-bottom: 0;
}

.forum thead tr:first-child th:first-child {
    border-top-left-radius: 4px;
}

.forum thead tr:last-child th:last-child {
    border-top-right-radius: 4px;
}

.forum thead tr h3 {
    color: #fff;
    margin-bottom: 0px;
    font-size: 22px;
}

.forum h4>small {
    display: block;
}


/*  Footer */


/* ----------------------------------------------------------------
    FOOTER
-----------------------------------------------------------------*/

#footer {
    display: block;
    position: relative;
    background-color: #f8f9fa;
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}

#footer a:not(.btn):not(:hover) {
    color: #484848;
}

#footer p {
    font-family: "Poppins", sans-serif;
}

#footer a:not(.btn) {
    font-weight: 400;
}

#footer .footer-content {
    padding: 60px 0 40px 0;
}

#footer .widget .widget-title,
#footer .widget h4 {
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
}

#footer .widget .widget-title ul li a,
#footer .widget h4 ul li a {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    line-height: 32px;
    color: #999;
}

#footer .copyright-content {
    min-height: 80px;
    padding: 30px 0;
    background-color: #eff1f4;
    font-size: 13px;
}

#footer .logo {
    margin-bottom: 54px;
    margin-top: 12px;
    max-width: 80px;
}

#footer .footer-logo {
    border-right: 1px solid #333;
    margin-bottom: 20px;
    margin-right: 18px;
    padding-right: 16px;
}

.dark #footer,
#footer.inverted,
#footer.dark {
    background-color: #181818;
}

.dark #footer p,
#footer.inverted p,
#footer.dark p {
    color: #999;
}

.dark #footer a:not(.btn),
#footer.inverted a:not(.btn),
#footer.dark a:not(.btn) {
    font-weight: 400;
}

.dark #footer a:not(.btn):not(:hover),
#footer.inverted a:not(.btn):not(:hover),
#footer.dark a:not(.btn):not(:hover) {
    color: #999;
}

.dark #footer h1,
.dark #footer h2,
.dark #footer h3,
.dark #footer h4,
.dark #footer h5,
.dark #footer h6,
#footer.inverted h1,
#footer.inverted h2,
#footer.inverted h3,
#footer.inverted h4,
#footer.inverted h5,
#footer.inverted h6,
#footer.dark h1,
#footer.dark h2,
#footer.dark h3,
#footer.dark h4,
#footer.dark h5,
#footer.dark h6 {
    color: #fff;
}

.dark #footer .widget .widget-title,
.dark #footer .widget h4,
#footer.inverted .widget .widget-title,
#footer.inverted .widget h4,
#footer.dark .widget .widget-title,
#footer.dark .widget h4 {
    color: #fff;
}

.dark #footer .copyright-content,
#footer.inverted .copyright-content,
#footer.dark .copyright-content {
    background-color: #1E1E1E;
}


/*  Widgets */


/* ----------------------------------------------------------------
	Widgets
-----------------------------------------------------------------*/

.widget {
    margin-bottom: 30px;
    position: relative;
}

.widget .widget-title,
.widget>h4 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 24px;
    letter-spacing: 1px;
    margin-bottom: 20px;
}

.widget:after,
.widget:before {
    clear: both;
    content: " ";
    display: table;
}

.widget .flickity-page-dots {
    margin-bottom: 20px;
}

.widget a {
    color: #484848;
}

.widget p {
    margin-bottom: 20px;
}

.sidebar .widget {
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;
    padding-bottom: 30px;
}

.sidebar .widget:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

.sidebar .widget #testimonials .testimonial-item>img {
    border-radius: 50%;
    display: block;
    float: none;
    height: 50px !important;
    margin: 0 auto 20px;
    width: 50px !important;
}

.tags a {
    border: 2px solid #e6e8eb;
    border-radius: 50px;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 1px;
    outline: none;
    padding: 5px 14px;
    margin: 0 2px 5px 0;
    background-color: transparent;
    color: #484848;
    display: inline-block;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.tags a:hover,
.tags a:focus,
.tags a:active,
.tags a.active {
    background-color: #1290c6;
    border-color: #1290c6;
    color: #ffffff !important;
}

.widget-archive ul {
    padding-left: 0;
}

.flickr-widget,
.widget-instagram {
    opacity: 0;
    min-height: 225px;
    transition: opacity .3s ease;
    margin-bottom: 20px;
}

.flickr-widget.widget-instagram-loaded,
.flickr-widget.flickr-widget-loaded,
.widget-instagram.widget-instagram-loaded,
.widget-instagram.flickr-widget-loaded {
    opacity: 1;
}

.flickr-widget a img,
.widget-instagram a img {
    width: 25%;
    height: auto;
    padding: 3px;
    border-radius: 6px;
}

.flickr-widget .col-lg-3>a img,
.widget-instagram .col-lg-3>a img {
    padding: 2px;
    width: 25%;
}

.flickr-widget .col-lg-4>a>img,
.widget-instagram .col-lg-4>a>img {
    width: 25%;
}

body.breakpoint-sm .col-3 .flickr-widget img .widget-instagram img {
    width: 16.666%;
}

.widget .footer-widget .social-icon {
    background-color: rgba(0, 0, 0, 0.2);
}

.widget.widget-newsletter button {
    margin-left: -1px;
}

.widget.widget-newsletter button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.widget.widget-newsletter .btn {
    text-transform: none;
}

.widget.widget-newsletter .form-control {
    height: 40px;
}

.widget.widget-newsletter .btn {
    font-size: 12px;
    font-weight: 600;
    height: 40px;
    padding: 8px 16px;
}

.widget.widget-newsletter label.error {
    color: #e42c3e;
    display: none !important;
    font-weight: 400;
    margin-top: 5px;
}

.background-dark .widget.widget-newsletter .input-group-prepend {
    background-color: #ffffff;
    color: #ffffff;
}

.background-dark .widget.widget-newsletter .form-transparent-fields .form-control {
    border-left: 0;
    padding-left: 0;
}

.form-control.error,
.sm-form-control.error {
    border-color: #e42c3e;
}

.widget-newsletter small {
    display: block;
}

.widget.widget-search button {
    margin-left: -1px;
}

.widget.widget-search button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.widget.widget-search .btn {
    text-transform: none;
}

.widget.widget-search .btn {
    font-size: 12px;
    font-weight: 600;
    height: 40px;
    padding: 8px 16px;
}

.background-dark .widget.widget-search .input-group-prepend {
    background-color: rgba(0, 0, 0, 0.35);
    border-color: rgba(0, 0, 0, 0.25);
    color: #999;
}

#widget-contact-form {
    clear: left;
}

.widget .form-inline .custom-select,
.widget .form-inline .input-group {
    width: 100%;
}

.widget-tweeter,
.widget-twitter {
    min-height: 248px;
}

.widget-tweeter:not([data-avatar="true"]) li {
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.widget-tweeter:not([data-avatar="true"]) li:before,
.widget-twitter:not([data-avatar="true"]) li:before {
    margin-left: -22px;
    position: absolute;
    font-family: "Font Awesome 5 Brands";
    content: "\f099";
}

.widget-tweeter[data-avatar="true"] li img,
.widget-twitter[data-avatar="true"] li img {
    border-radius: 50%;
    left: -4px;
    position: absolute;
    width: 26px;
    z-index: 3;
}

.widget-tweeter[data-avatar="true"] li,
.widget-twitter[data-avatar="true"] li {
    padding-left: 10px;
}

.widget-tweeter li,
.widget-twitter li {
    line-height: 1.66;
    margin-bottom: 20px;
}

.widget-tweeter li:last-child,
.widget-twitter li:last-child {
    margin-bottom: 0px;
}

.widget-tweeter ul,
.widget-twitter ul {
    list-style: none;
    padding-left: 22px;
    margin-bottom: 0;
}

.widget-tweeter small,
.widget-twitter small {
    display: block;
    color: #9896a6 !important;
    width: 100%;
    margin-top: 10px;
}

.widget-newsletter .input-group-prepend i {
    color: #555 !important;
}


/*Widget: Contact us*/

.widget-gallery a>img {
    float: left;
    height: auto;
    width: 33.3333333%;
    padding: 3px;
}

.p-dropdown-content .widget-myaccount {
    min-width: 220px;
}

.widget-myaccount {
    min-width: 220px;
    text-align: center;
}

.widget-myaccount ul>li,
.widget-myaccount>li {
    display: block;
}

.widget-myaccount ul>li:first-child label,
.widget-myaccount>li:first-child label {
    margin-top: 0;
}

.widget-myaccount ul>li label,
.widget-myaccount>li label {
    color: #999999;
    font-size: 11px;
    text-transform: uppercase;
    margin-top: 14px;
    margin-bottom: 0;
}

.widget-myaccount ul>li a,
.widget-myaccount>li a {
    line-height: 26px;
    white-space: nowrap;
    display: block;
    padding: 2px 8px;
}

.widget-myaccount ul>li a i,
.widget-myaccount>li a i {
    margin-right: 6px;
}

.bootstrap-datetimepicker-widget .datepicker {
    width: 100%;
}

.p-dropdown-content .widget-mycart {
    min-width: 246px;
}

.widget-mycart {
    width: 100%;
    position: relative;
}

.widget-mycart .cart-item {
    margin-top: 8px;
    display: inline-table;
    width: 100%;
}

.widget-mycart .cart-item .cart-image {
    width: 50px;
    height: 50px;
    float: left;
    overflow: hidden;
    border-radius: 4px;
}

.widget-mycart .cart-item .cart-image img {
    width: 100%;
}

.widget-mycart .cart-item .cart-product-meta {
    float: left;
    padding-left: 20px;
}

.widget-mycart .cart-item .cart-product-meta>a {
    font-weight: 500;
    display: block;
}

.widget-mycart .cart-item .cart-product-meta span {
    opacity: .7;
}

.widget-mycart .cart-item .cart-item-remove {
    width: 20px;
    float: right;
    font-size: 12px;
}

.widget-mycart .cart-item .cart-item-remove i {
    color: #484848 !important;
}

.widget-mycart .cart-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 4px;
    margin: 10px 0;
}

.widget-mycart .cart-total .cart-total-labels {
    text-align: left;
}

.widget-mycart .cart-total .cart-total-labels span {
    margin-bottom: 2px;
    display: block;
    font-weight: 500;
}

.widget-mycart .cart-total .cart-total-labels span strong {
    font-size: 16px;
    font-weight: 600;
}

.widget-mycart .cart-total .cart-total-prices {
    text-align: right;
}

.widget-mycart .cart-total .cart-total-prices span {
    margin-bottom: 2px;
    display: block;
    font-weight: 500;
}

.widget-mycart .cart-total .cart-total-prices span strong {
    font-weight: 600;
    font-size: 16px;
}

.widget-mycart .cart-buttons .btn {
    margin-bottom: 0;
}

.p-dropdown-content .widget-notification {
    min-width: 246px;
}

.widget-notification {
    width: 100%;
    position: relative;
}

.widget-notification .notification-item {
    display: flex;
    align-items: flex-start;
    margin-top: 12px;
    width: 100%;
    position: relative;
}

.widget-notification .notification-item.notification-new::before {
    content: " ";
    background-color: #CE041C;
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    right: 0;
    top: 5px;
}

.widget-notification .notification-item .notification-image {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    overflow: hidden;
}

.widget-notification .notification-item .notification-image img {
    width: 100%;
}

.widget-notification .notification-item .notification-meta {
    flex: 1px;
    padding-left: 12px;
}

.widget-notification .notification-item .notification-meta>a {
    font-weight: 500;
    display: block;
}

.widget-notification .notification-item .notification-meta span {
    opacity: .6;
    font-size: 11px;
}


/*Widget Tabs*/

.widget .tabs-content {
    margin-bottom: 0;
    padding-bottom: 0;
}

.sidebar .widget .tabs li>a {
    /*font-size: 11px;*/
    padding: 10px;
}

.sidebar .widget .tabs .tabs-navigation li {
    float: left;
    display: inherit;
}

.widget .post-thumbnail-content a {
    font-size: 13px;
    line-height: 20px;
    display: block;
}

.sidebar .widget .post-thumbnail-list .post-thumbnail-entry>img {
    height: 48px;
    margin-right: 10px;
    width: 64px;
}

.sidebar .widget .post-thumbnail-list .post-thumbnail-entry img+.post-thumbnail-content {
    padding-left: 72px;
}

.sidebar .widget .post-thumbnail-entry .post-thumbnail-content .post-date,
.post-thumbnail-entry .post-thumbnail-content .post-category {
    font-size: 11px;
}

.sidebar .widget .post-thumbnail-entry:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}


/*Dark*/

.dark .widget .widget-title,
.dark .widget>h4 {
    color: #ffffff;
}

@media (max-width: 1024px) {
    .widget {
        float: left;
        width: 100%;
    }
}


/*  Extras */


/*------------------------------------------------------
     Extras
------------------------------------------------------*/

.lazy {
    opacity: 0;
    transition: opacity 3s ease-in;
}

.lazy.img-loaded {
    opacity: 1;
}

.item-link {
    color: #484848;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase !important;
}

.item-link i {
    position: relative;
    right: 0;
    transition: right 0.3s ease 0s;
}

.item-link:hover {
    color: #1290c6;
}

.item-link:hover i {
    right: -6px;
}

.background-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.background-fixed {
    background-attachment: fixed;
    background-position: center top;
    background-size: cover;
}

.no-parallax {
    background-size: cover !important;
    background-attachment: scroll !important;
    background-position: center center !important;
}

.background-colored {
    background-color: #1290c6;
}

#particles-dots {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: -1;
}

.particles canvas {
    position: absolute;
    top: 0;
    left: 0;
}


/* ----------------------------------------------------------------
    MULTISCROLL
-----------------------------------------------------------------*/

#multiscroll .ms-left .ms-section {
    background-position: 100% center !important;
}

#multiscroll .ms-right .ms-section {
    background-position: 0 center !important;
}


/* ----------------------------------------------------------------
Scroll top
-----------------------------------------------------------------*/

#scrollTop {
    z-index: -1;
    opacity: 0;
    position: fixed;
    text-align: center;
    line-height: 12px !important;
    right: 26px;
    bottom: 0;
    color: #fff;
    cursor: pointer;
    border-radius: 100px;
    height: 40px;
    width: 40px;
    background-color: rgba(0, 0, 0, 0.25);
    background-repeat: no-repeat;
    background-position: center;
    transition: opacity 0.3s, bottom 0.5s, box-shadow 0.3s cubic-bezier(0.55, 0, 0.1, 1), background-color 0.1s linear;
    background-color: rgba(0, 0, 0, 0.25) !important;
    overflow: hidden;
}

#scrollTop i {
    line-height: 39px !important;
    width: 39px !important;
    height: 39px !important;
    font-size: 16px !important;
    top: 0px !important;
    left: 0px !important;
    text-align: center !important;
    position: relative;
    z-index: 10;
    background-color: transparent !important;
    transform: translate(0, 0px);
    transition: transform 0.2s ease;
}

#scrollTop:hover {
    transition: box-shadow 0.3s cubic-bezier(0.55, 0, 0.1, 1), background-color 0.05s linear 0.25s;
    background-color: transparent !important;
}

#scrollTop:hover i {
    transform: translate(0, -39px) !important;
}

#scrollTop:after,
#scrollTop:before {
    display: block;
    content: ' ';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #1290c6;
    transform: scale(0);
    transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
    border-radius: 100px;
}

#scrollTop:before {
    background-color: rgba(255, 255, 255, 0.25);
    transform: scale(1);
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    z-index: 2;
}

#scrollTop:hover:after {
    transform: scale(1);
}

#scrollTop:active:before {
    opacity: 1;
}


/* ----------------------------------------------------------------
Misc
-----------------------------------------------------------------*/

.show-grid[class*="col-lg-"] .show-grid-block {
    background-color: #eeeeee;
    border: 1px solid #ffffff;
    display: block;
    line-height: 40px;
    min-height: 40px;
    text-align: center;
}

.fontawesome-icon-list i {
    font-size: 18px;
    display: inline-block;
    font-size: 1.3em;
    margin-right: 5px;
    position: relative;
    text-align: center;
    top: 2px;
    width: 30px;
    padding: 16px 0px;
}

[class^="icon"] {
    display: inline-block;
}


/* ----------------------------------------------------------------------
Images
-------------------------------------------------------------------------*/

.img-thumbnail {
    border: 1px solid #eee;
    padding: 1px;
}


/* ----------------------------------------------------------------------
NEWS MARKETING BOX
-------------------------------------------------------------------------*/

.marketing-box {
    background-color: #f1f1f1;
    border: 1px solid #eee;
    clear: both;
    color: #999;
    height: 100px;
    line-height: 100px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}


/* ----------------------------------------------------------------------
NEWS MARKETING BOX
-------------------------------------------------------------------------*/

.news-ticker {
    border-bottom: 1px solid #eee;
    position: relative;
}

.news-ticker-title {
    background-color: #f5f5f5;
    float: left;
    margin-right: 12px;
    height: 100%;
}

.news-ticker-title h4 {
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    padding: 20px 30px 17px;
}

.news-ticker-content {
    margin-bottom: 0;
    padding: 14px;
    width: 100%;
}

.news-ticker-content .polo-carousel-item {
    margin-right: 40px;
}

.news-ticker-content .polo-carousel-item a {
    white-space: nowrap;
    padding-right: 40px !important;
}

.news-ticker-content .polo-carousel-item:before {
    color: #d82731;
    content: "\e9be";
    font-family: inspiro-icons !important;
    font-size: 10px;
    margin-left: -32px;
    position: absolute;
}

@media (max-width: 1024px) {
    .news-ticker {
        display: none;
    }
}


/* ----------------------------------------------------------------------
DEMO GRID
-------------------------------------------------------------------------*/

.grid-system-demo [class*="col-"] .grid-col-demo {
    background-color: #eee;
    display: block;
    line-height: 54px;
    min-height: 54px;
    text-align: center;
}

.grid-system-demo .row {
    padding-bottom: 42px;
}

.grid-system-demo-live .row {
    padding-bottom: 42px;
}


/* ----------------------------------------------------------------------
BOXED GRID
-------------------------------------------------------------------------*/

.boxed-grid .row {
    margin-right: -10px;
    margin-left: -10px;
}

.boxed-grid [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
}

.boxed-grid [class*="col-"] a {
    margin-bottom: 20px;
}

.boxed-grid a {
    overflow: hidden;
    display: block;
    position: relative;
}

.boxed-grid a img {
    width: 100%;
    height: auto;
    transition: all 0.6s ease;
}

.boxed-grid a:hover img {
    transform: scale(1.2);
}

.boxed-grid a .title {
    position: absolute;
    top: 40%;
    left: 30px;
    font-size: 28px;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
}


/* ----------------------------------------------------------------------
PAGE 404
-------------------------------------------------------------------------*/

.page-error-404 {
    color: #eee;
    display: block;
    font-size: 300px;
    font-weight: 800;
    line-height: 0.7;
}

body.breakpoint-sm .page-error-404 {
    font-size: 200px;
    line-height: 0.8;
    margin-bottom: 20px;
}

body.breakpoint-xs .page-error-404 {
    font-size: 140px;
    line-height: 0.8;
    margin-bottom: 20px;
}


/* ----------------------------------------------------------------
Scrolldown icon animation
-----------------------------------------------------------------*/

.scrolldown-animation {
    animation: 1s ease-in-out 0s normal none infinite running scrolldown-keyframes;
    bottom: 40px;
    height: 40px;
    left: 50%;
    margin-left: -20px;
    position: absolute;
    width: 45px;
    z-index: 60;
}

@keyframes scrolldown-keyframes {
    0%,
    100% {
        transform: translateY(-8px);
    }
    50% {
        transform: translateY(0px);
    }
}

@keyframes scrolldown-keyframes {
    0%,
    100% {
        transform: translateY(-8px);
    }
    50% {
        transform: translateY(0px);
    }
}


/*animated background*/

.animated-background {
    animation: 120s linear 0s normal none infinite running animatedBackground;
    background-repeat: repeat-x;
    background-size: cover;
}

@keyframes animatedBackground {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -3000px 0;
    }
}

@keyframes animatedBackground {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -3000px 0;
    }
}


/* ----------------------------------------------------------------
Image Block
-----------------------------------------------------------------*/

.image-block {
    background-color: #f4f4f4;
}

.image-block .col-lg-6 {
    padding: 60px;
}

.image-block-content {
    margin-bottom: 30px;
    padding: 0 50px;
}

.image-block-content .feature-icon {
    border-radius: 100%;
    color: #fff;
    display: inline-block;
    height: 60px;
    line-height: 60px;
    text-align: center;
    width: 60px;
    background-color: #eee;
}

.image-block-content .feature-icon i {
    font-size: 28px;
    margin-top: 16px;
}

.image-block-content .feature-content {
    padding-left: 85px;
}

.image-block-content .feature-content h3 {
    margin-bottom: 0;
}

.image-absolute {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 426px;
    height: 556px;
}


/*hero headings*/

.heading-fancy-border {
    border: 15px solid #111;
    margin: 0 10%;
    padding: 60px 0px;
}

.text-light .heading-fancy-border {
    border-color: #fff !important;
}


/*hero heading 2*/

.hero-heading-2 {
    padding: 20px;
    background-color: #111;
    border-radius: 8px;
}

.hero-heading-2>i {
    font-size: 160px;
    opacity: 0.1;
    position: absolute;
    right: 23px;
    top: 2px;
}


/*hero heading 3*/

.hero-heading-3 {
    background-color: #fff;
    border: 36px solid #fff;
    box-shadow: 0 0 0 1px #111 inset;
    color: #111;
    opacity: 0.7;
    padding: 72px;
    width: auto;
    margin: 40px;
    text-align: center;
}

.hero-heading-3 h2,
.hero-heading-3 h3 {
    text-transform: uppercase;
    font-size: 92px;
    text-align: center;
    margin: 0;
    line-height: 72px;
}

@media (max-width: 1024px) {
    .hero-heading-3 {
        background-color: #fff;
        border: 30px solid #fff;
        padding: 30px;
        width: 100%;
        margin: 0;
    }
    .hero-heading-3 h2,
    .hero-heading-3 h3 {
        font-size: 40px !important;
        line-height: 40px !important;
    }
}


/*working-hours list*/

.working-hours ul {
    padding: 0;
}

.working-hours li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: inline-block;
    padding: 10px 0;
    width: 100%;
}

.working-hours li:before {
    content: "\f017";
    font-family: "Font Awesome 5 Free";
    margin-right: 15px;
}

.working-hours li span {
    float: right;
}

.working-hours p {
    font-size: 13px;
    color: #111;
}


/*price menu list*/

.price-menu-list {
    padding: 0;
    list-style: none;
}

.price-menu-list li {
    margin-bottom: 40px;
}

.price-menu-list li>h2 {
    margin-bottom: 0;
}

.price-menu-list li>p {
    margin-bottom: 8px;
    opacity: 0.6;
}

.price-menu-list li>img {
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    width: 100px;
    height: 100px;
    float: left;
}


/* ----------------------------------------------------------------------
Responsive utilities
-------------------------------------------------------------------------*/

.responsive-utilities th small {
    color: #999;
    display: block;
    font-weight: 400;
}

.responsive-utilities td.is-visible {
    background-color: #dff0d8 !important;
    color: #468847;
}


/* ----------------------------------------------------------------
Bootstrap row colums fixes
-----------------------------------------------------------------*/

.col-lg-2-5 {
    width: 20%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
}


/* ----------------------------------------------------------------
Creative Profile Page
-----------------------------------------------------------------*/

.profile-content {
    width: 100%;
    padding: 0;
    position: relative;
}

.profile-content .profile-image {
    background-position: 50% 20%;
    background-repeat: repeat-y;
    background-size: cover;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 67.6666%;
    z-index: 2;
    display: table;
}

.profile-content .profile-image .profile-name {
    padding: 15px;
    position: absolute;
    width: 100%;
    bottom: 0;
}

.profile-content .profile-image .profile-name h3 {
    color: #fff;
    font-size: 120px;
    opacity: 0.7;
    line-height: 1;
    text-shadow: 0 22px 25px rgba(0, 0, 0, 0.13);
}

.profile-content .profile-bio {
    float: right;
    height: auto;
    margin: 50px;
    overflow: hidden;
    padding: 0 0 0 100px;
    position: relative;
    right: 0;
    top: 0;
    width: 33.3333%;
    z-index: 1;
}

.profile-content .profile-bio .profile-bio-footer {
    padding: 20px 0 0;
    width: 100%;
}

@media (max-width: 1024px) {
    .profile-content .profile-image {
        height: 300px;
        position: relative;
        width: 100%;
        display: table;
    }
    .profile-content .profile-image .profile-name h3 {
        font-size: 40px;
        text-align: center;
    }
    .profile-content .profile-bio {
        display: block;
        float: none;
        height: auto;
        margin: 15px auto;
        padding: 15px;
        width: 100%;
    }
}


/* ----------------------------------------------------------------
Text-Rotator
-----------------------------------------------------------------*/

.morphext>.animated {
    display: inline-block;
    opacity: 1;
}

.ohidden {
    position: relative;
    overflow: hidden !important;
}


/* ----------------------------------------------------------------
Play Button
-----------------------------------------------------------------*/

.play-button {
    position: relative;
    width: 90px;
    height: 90px;
    display: inline-block;
    border-width: 7px;
    border-style: solid;
    border-color: #ffffff;
    border-radius: 50%;
    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}

.play-button>i {
    font-size: 22px;
    color: #ffffff;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: 2px;
    transform: translateY(-50%) translateX(-50%);
}

.play-button.dark {
    border-color: #1f1f1f;
}

.play-button.dark>i {
    color: #1f1f1f;
}

.play-button:hover {
    transform: scale(1.15);
    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}

.lines-button {
    appearance: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    transition: 0.3s;
    user-select: none;
    width: 16px;
    background: transparent;
    outline: none;
}

.lines-button>* {
    display: block;
}

.lines-button:hover,
.lines-button:focus {
    outline: none;
}

.lines-button::-moz-focus-inner {
    border: 0;
}

.lines {
    display: inline-block;
    height: 2px;
    width: 20px;
    border-radius: 4px;
    transition: 0.3s;
    position: relative;
    top: -4px;
}

.lines::before,
.lines::after {
    display: inline-block;
    height: 2px;
    width: 20px;
    border-radius: 4px;
    transition: 0.3s;
    content: '';
    position: absolute;
    left: 0;
    transform-origin: 2.85714px center;
    width: 100%;
}

.lines::before {
    top: 6px;
}

.lines::after {
    top: -6px;
}

.lines,
.lines:before,
.lines:after {
    background-color: #111;
}

.toggle-active .lines {
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
    transform: scale3d(0.8, 0.8, 0.8);
}

.x {
    width: auto;
}

.x.toggle-active .lines {
    background: transparent !important;
}

.x.toggle-active .lines::before,
.x.toggle-active .lines::after {
    transform-origin: 50% 50%;
    top: 0;
    width: 26px;
}

.x.toggle-active .lines::before {
    transform: rotate3d(0, 0, 1, 45deg);
}

.x.toggle-active .lines::after {
    transform: rotate3d(0, 0, 1, -45deg);
}

.toggle-item i {
    position: relative;
}

.toggle-item i:nth-of-type(2) {
    visibility: hidden;
    position: absolute;
}

.toggle-item.toggle-active i:nth-of-type(2) {
    visibility: visible;
    position: relative;
}

.toggle-item.toggle-active i:nth-of-type(1) {
    visibility: hidden;
    position: absolute;
}

.image-hover-zoom {
    position: relative;
    overflow: hidden;
    cursor: url("../images/expand.png"), default;
    /*hover state*/
}

.image-hover-zoom img {
    transition: all .4s ease;
    opacity: 1;
}

.image-hover-zoom:hover img {
    opacity: .3;
}


/* ----------------------------------------------------------------
    VM PLAYER (VIMEO PLAYER)
-----------------------------------------------------------------*/

.vimeo_player_wrapper {
    z-index: -1 !important;
}

.vimeo_player_wrapper~iframe {
    display: none;
}

iframe {
    width: 100%;
}


/* ----------------------------------------------------------------
    Others
-----------------------------------------------------------------*/

.block-shadow {
    -moz-box-shadow: 0 25px 75px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 25px 75px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 25px 75px 0 rgba(0, 0, 0, 0.2);
    margin-left: -100px;
}

.portfolio-item .new-badge {
    background-image: url("../preview/images/new-badge.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    height: 61px;
    position: absolute;
    right: 12px;
    top: 3px;
    width: 55px;
    z-index: 100;
}


/* ----------------------------------------------------------------
    Bar Rating
-----------------------------------------------------------------*/

.bar-rating {
    height: 23px;
    float: left;
    color: #FFC300;
}


/* ----------------------------------------------------------------
    Bar Rating
-----------------------------------------------------------------*/

.image-left-offset {
    margin-left: -15px;
}

.image-left-offset img {
    width: 100%;
    max-width: none;
    z-index: 1;
    position: relative;
    display: block;
}

.image-right-offset {
    margin-right: -15px;
}

.image-right-offset img {
    width: 100%;
    max-width: none;
    z-index: 1;
    position: relative;
    display: block;
}


/* ----------------------------------------------------------------------
	Full Calendar
-------------------------------------------------------------------------*/

#external-events .fc-event {
    margin-bottom: 10px;
    cursor: grab;
}

.fc-view,
.fc-view>table {
    z-index: 0 !important;
}

.fc-toolbar .fc-button {
    font-size: 12px !important;
    padding: 4px 14px;
    height: 34px;
    font-family: "Poppins", sans-serif;
    background-color: #ffffff !important;
    border-color: #ebebeb !important;
    color: #4c5667 !important;
    text-shadow: none !important;
    background-image: unset !important;
    box-shadow: unset !important;
}

.fc-toolbar .fc-button:hover,
.fc-toolbar .fc-button:focus,
.fc-toolbar .fc-button:active,
.fc-toolbar .fc-button.active {
    border-color: #ebedf2 !important;
    background-color: #f4f5f8 !important;
    color: #4c5667 !important;
}

.fc-day-header {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.fc-day-header span {
    font-weight: 600;
}

.fc-list-heading td {
    font-weight: 600;
}

td.fc-today {
    border-style: none;
}

.fc-event {
    padding: 4px 10px;
}

.fc-event,
.fc-event:hover,
.fc-title,
.fc-more {
    color: #484848;
}

.fc-popover .fc-header {
    padding: 6px 9px;
}

.fc-event {
    background-color: #fff;
    border-width: 1px;
    border-color: #e6e8eb;
    box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
    border-left-width: 1px !important;
    padding: 5px 12px !important;
    border-radius: 4px !important;
    margin: 5px;
}

.fc-event-dot {
    background-color: #181818;
}

.fc-divider,
.fc-list-heading td,
.fc-popover .fc-header {
    background-color: #F7F9FB !important;
}

.fc-toolbar h2 {
    font-size: 1.2em;
}

.fc-event-primary.fc-event {
    background-color: #1290c6;
    border-color: #1290c6;
    color: #ffffff;
}

.fc-event-primary.fc-event span {
    color: #ffffff;
}

.fc-event-primary .fc-event-dot {
    background-color: #1290c6;
}

.fc-event-secondary.fc-event {
    background-color: #5A6268;
    border-color: #5A6268;
    color: #ffffff;
}

.fc-event-secondary.fc-event span {
    color: #ffffff;
}

.fc-event-secondary .fc-event-dot {
    background-color: #1290c6;
}

.fc-event-warning.fc-event {
    background-color: #ffb20e;
    border-color: #ffb20e;
    color: #ffffff;
}

.fc-event-warning.fc-event span {
    color: #ffffff;
}

.fc-event-warning .fc-event-dot {
    background-color: #ffb20e;
}

.fc-event-danger.fc-event {
    background-color: #CE041C;
    border-color: #CE041C;
    color: #ffffff;
}

.fc-event-danger.fc-event span {
    color: #ffffff;
}

.fc-event-danger .fc-event-dot {
    background-color: #CE041C;
}

.fc-event-info.fc-event {
    background-color: #53b0f8;
    border-color: #53b0f8;
    color: #ffffff;
}

.fc-event-info.fc-event span {
    color: #ffffff;
}

.fc-event-info .fc-event-dot {
    background-color: #53b0f8;
}

.fc-event-success.fc-event {
    background-color: #81c868;
    border-color: #81c868;
    color: #ffffff;
}

.fc-event-success.fc-event span {
    color: #ffffff;
}

.fc-event-success .fc-event-dot {
    background-color: #81c868;
}

.simple-dropdown .title {
    line-height: inherit !important;
    letter-spacing: 1px;
    padding: 0;
    color: #1f1f1f;
}

.simple-dropdown .dropdown-list {
    right: -10px;
    margin-top: -20px;
    left: auto;
    box-shadow: 0px -6px 16px rgba(0, 0, 0, 0.1);
    margin-top: -16px;
}

.simple-dropdown .dropdown-list:before {
    content: " ";
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #ffffff;
    top: -9px;
    right: 14px;
    position: absolute;
}

.p-dropdown {
    float: left;
    font-size: 13px;
    font-weight: 400;
    position: relative;
}

.p-dropdown a:not(.btn) {
    color: #484848;
}

.p-dropdown .btn,
.p-dropdown .title {
    padding-bottom: 10px;
    cursor: pointer;
    overflow: unset !important;
}

.p-dropdown .btn:before {
    top: 30px;
    right: 5px;
}

.p-dropdown .btn~.dropdown-content {
    top: 42px;
}

.p-dropdown .p-dropdown-content,
.p-dropdown ul.p-dropdown-content {
    line-height: normal;
    position: absolute;
    z-index: 5;
    text-align: left;
    opacity: 0;
    transition: all 0.3s ease;
    visibility: hidden;
    transform: translateY(8px);
    padding: 14px 20px;
    width: min-content;
    top: auto;
    right: 0;
    margin: 0;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #e6e8eb;
    min-width: 100px;
    box-shadow: 0 14px 20px rgba(0, 0, 0, 0.1);
}

.p-dropdown .p-dropdown-content hr,
.p-dropdown ul.p-dropdown-content hr {
    margin-left: -20px;
    margin-right: -20px;
}

.p-dropdown .p-dropdown-content ul>li,
.p-dropdown .p-dropdown-content>li,
.p-dropdown ul.p-dropdown-content ul>li,
.p-dropdown ul.p-dropdown-content>li {
    display: block;
}

.p-dropdown .p-dropdown-content ul>li:first-child label,
.p-dropdown .p-dropdown-content>li:first-child label,
.p-dropdown ul.p-dropdown-content ul>li:first-child label,
.p-dropdown ul.p-dropdown-content>li:first-child label {
    margin-top: 0;
}

.p-dropdown .p-dropdown-content ul>li label,
.p-dropdown .p-dropdown-content>li label,
.p-dropdown ul.p-dropdown-content ul>li label,
.p-dropdown ul.p-dropdown-content>li label {
    color: #999999;
    font-size: 11px;
    text-transform: uppercase;
    margin-top: 14px;
    margin-bottom: 0;
}

.p-dropdown .p-dropdown-content ul>li a,
.p-dropdown .p-dropdown-content>li a,
.p-dropdown ul.p-dropdown-content ul>li a,
.p-dropdown ul.p-dropdown-content>li a {
    line-height: 26px;
    white-space: nowrap;
    display: block;
    padding: 2px 8px;
}

.p-dropdown .p-dropdown-content ul>li a i,
.p-dropdown .p-dropdown-content>li a i,
.p-dropdown ul.p-dropdown-content ul>li a i,
.p-dropdown ul.p-dropdown-content>li a i {
    margin-right: 6px;
}

.p-dropdown.p-dropdown-invert .p-dropdown-content,
.p-dropdown.p-dropdown-invert ul.p-dropdown-content {
    left: 0;
    right: auto;
}

.p-dropdown.p-dropdown-invert .p-dropdown-content:before,
.p-dropdown.p-dropdown-invert ul.p-dropdown-content:before {
    content: " ";
    right: auto;
    left: 22px;
}

.p-dropdown:hover .title:before,
.p-dropdown.dropdown-active .title:before {
    opacity: 1;
    visibility: visible;
}

.p-dropdown:hover .p-dropdown-content,
.p-dropdown.dropdown-active .p-dropdown-content {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
}

.header-extras .p-dropdown .p-dropdown-content,
.header-extras .p-dropdown ul.p-dropdown-content {
    right: -26px;
    margin-top: -8px;
}

.header-extras .p-dropdown .p-dropdown-content:before,
.header-extras .p-dropdown ul.p-dropdown-content:before {
    display: none;
}

.widget-form {
    min-width: 200px;
}

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
    border-bottom-width: 0;
}

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
    border-left-width: 0;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #e6e8eb;
    border-bottom-width: 1px;
    border-left-width: 1px;
}

table.dataTable thead th,
table.dataTable thead td {
    padding: 10px 18px;
    border-bottom: 1px solid #e6e8eb;
    border-top: 0;
    background-color: #F7F9FB;
}

table.dataTable tfoot th,
table.dataTable tfoot td {
    border-top: 2px solid #e6e8eb;
    background-color: #F7F9FB;
}

.table-bordered {
    border: 1px solid #e6e8eb;
}

.dataTables_paginate,
.paging_simple_numbers .dataTables_info,
#datatable_paginate {
    margin-top: 20px;
}

div.dataTables_wrapper div.dataTables_length select {
    min-width: 60px;
}

.dataTables_wrapper .dataTable .group th,
.dataTables_wrapper .dataTable .group td {
    background-color: #f8f9fa;
}

.dataTable input {
    width: 100%;
}

.table th {
    font-weight: 500;
}


/* Countdown */

.p-countdown .p-countdown-show {
    display: none;
}


/* Box */

.p-cb {
    position: relative;
    margin: 12px 0;
    background-color: #fff;
    box-shadow: 0 6px 38px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 20px;
    transition: all 0.3s ease;
    border: 1px solid #e6e8eb;
    cursor: pointer;
}

.p-cb a {
    color: #484848;
}

.p-cb .cb-icon {
    float: left;
}

.p-cb .cb-icon i {
    font-size: 34px;
}

.p-cb .cb-icon~.cb-title,
.p-cb .cb-icon~.cb-content {
    padding-left: 50px;
}

.p-cb.cb-text .cb-title {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
}

.p-cb.cb-text .cb-content {
    font-size: 12px;
}

.p-cb.cb-text .cb-content p {
    color: #777777;
    margin-bottom: 0;
}

.p-cb.cb-text:hover {
    box-shadow: 0 6px 38px rgba(0, 0, 0, 0.05);
}

.p-cb.cb-text:hover a:not(.link) {
    color: inherit;
}

.icon-set-container {
    display: block;
    width: calc(100% + 30px);
    left: -15px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.icon-set-container .icon-preview .icon-holder {
    vertical-align: middle;
    top: -4px;
    height: 40px;
    width: 40px;
    background: rgba(0, 0, 0, 0.04);
    display: inline-block;
    line-height: 44px;
    text-align: center;
    border-radius: 4px;
    font-size: 24px;
    transition: .3s;
    position: relative;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.icon-set-container .icon-preview .icon-class {
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    font-size: 13px;
    line-height: 20px;
    top: 0;
    vertical-align: middle;
    padding-left: 50px;
    font-weight: 500;
}

.icon-set-container .row>[class^="col-"] {
    position: relative;
    font-size: 20px;
    margin: 12px 12px;
    background-color: #fff;
    box-shadow: 0 6px 38px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    flex: 1 1 calc(25% - 30px);
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 26px 18px;
    transition: .3s;
    color: #626262;
}

.icon-set-container .row>[class^="col-"]:hover {
    transform: translateY(-5px);
    background-color: #1290c6;
    color: #ffffff;
    box-shadow: 0 6px 38px rgba(0, 0, 0, 0.05);
}

.icon-set-container .row>[class^="col-"]:hover .icon-holder {
    background-color: #fff;
    color: #1290c6;
}

.kenburns-bg {
    background-position: 50% 20%;
    background-repeat: repeat-y;
    background-size: cover;
    height: 100%;
    position: absolute;
    top: 0;
    vertical-align: middle;
    width: 100%;
    z-index: -1;
    transform: scale(1);
    transition: 14s transform ease;
}

.kenburns-bg.kenburns-bg-animate {
    transform: translate3d(0, 0, 0) scale(1.2);
}

.zoom-out {
    animation-name: zoom-out;
    transform: scale(1);
    opacity: 1;
}

@-webkit-keyframes zoom-out {
    0% {
        opacity: 0.1;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes zoom-out {
    0% {
        opacity: 0.1;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes zoom-out {
    0% {
        opacity: 0.1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes zoom-out {
    0% {
        transform: scale(1.5);
        opacity: 0.1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.fullscreen .container-fullscreen {
    display: flex;
    width: 100%;
    height: 100%;
}

.fullscreen .container-fullscreen .text-middle {
    align-self: center;
    margin: 0 auto;
}


/*  Helpers */


/* ----------------------------------------------------------------
Helpers
-----------------------------------------------------------------*/

.background-theme {
    background-color: #1290c6 !important;
}

.align-items-bottom {
    align-items: flex-end !important;
    height: 100%;
}

.rounded {
    overflow: hidden;
}

.hidden {
    display: none;
}

.visible {
    opacity: 1;
}

.text-center {
    text-align: center;
}

.text-left.nav-tabs .nav-item {
    text-align: left !important;
}

.text-right.nav-tabs .nav-item {
    text-align: right !important;
}

.left {
    float: left !important;
}

.right {
    float: right !important;
}

.center {
    float: none !important;
    margin: 0 auto;
}

.block {
    display: block;
}

.align-center {
    display: inline-flex;
    text-align: center;
}

.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.text-bottom {
    display: table-cell;
    vertical-align: bottom;
    margin: auto;
    z-index: 3;
    position: relative;
}

.bg-light {
    background-color: #f8f9fa;
}

.text-background-light {
    background-color: #fff;
    padding: 0 4px;
}

.text-background-dark {
    background-color: #444;
    padding: 0 4px;
}

.text-shadow-light {
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.53);
}

.text-shadow-dark {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.53);
}

.background-primary {
    background-color: #1290c6 !important;
}

.background-secondary {
    background-color: #5A6268 !important;
}

.background-success {
    background-color: #81c868 !important;
}

.background-info {
    background-color: #53b0f8 !important;
}

.background-warning {
    background-color: #ffb20e !important;
}

.background-danger {
    background-color: #CE041C !important;
}

.background-white {
    background-color: #fff !important;
}

.background-light {
    background-color: #f8f9fa !important;
}

.background-grey {
    background-color: #F7F9FB !important;
}

.background-platinum {
    background-color: #f0efee !important;
}

.background-gold {
    background-color: #c2a60c !important;
}

.background-silver {
    background-color: #dddddd !important;
}

.background-bronze {
    background-color: #d78e00 !important;
}

.background-lightblue {
    background-color: #ebf5f7 !important;
}

.background-dark {
    background-color: #181818 !important;
}

.background-black {
    background-color: #000 !important;
}

.background-pattern {
    background-image: url(/images/overlay-pattern/gplay.png);
}

.background-pattern-1 {
    background-image: url(/images/overlay-pattern/3px-tile.png);
}

.background-pattern-2 {
    background-image: url(/images/overlay-pattern/asfalt-dark.png);
}

.background-pattern-3 {
    background-image: url(/images/overlay-pattern/axiom-pattern.png);
}

.shadow {
    box-shadow: 0 4px 16px #e2e2e2;
}

.post-shadow {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.shadow-bottom {
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1);
}

.shadow-inside {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
}

.shadow-inside-top {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.08);
}

.shadow-inside-bottom {
    box-shadow: inset 0 -1px 2px rgba(0, 0, 0, 0.08);
}

.text-xs,
.text-xs span {
    font-size: 30px;
    line-height: 30px;
}

.text-medium-light {
    font-size: 50px !important;
    font-weight: 100;
    line-height: 1.1;
}

.text-medium {
    font-size: 50px !important;
    font-weight: 800;
    line-height: 1.1;
    margin-bottom: 20px;
}

.text-md,
.text-md span,
.text-md span span {
    font-size: 74px !important;
    line-height: 86px !important;
}

.text-lg-light,
.text-lg-light span,
.text-lg-light span span {
    font-size: 90px !important;
    line-height: 100px !important;
    font-weight: 120 !important;
}

.text-lg,
.text-lg span,
.text-lg span span {
    font-size: 60px;
    line-height: 100px;
    letter-spacing: -.02em;
    font-weight: 700;
}

.text-lg-x2,
.text-lg-x2 span {
    color: #ffffff;
    font-size: 120px;
    line-height: 140px;
    margin-bottom: 10px;
}

.text-lg-x3,
.text-lg-x3 span {
    color: #ffffff;
    font-size: 200px;
    line-height: 220px;
}

.text-xl,
.text-xl span {
    font-size: 100px;
    line-height: 110px;
}

.text-small {
    font-size: 16px;
    font-weight: 800;
    line-height: 1.1;
}

.text-light,
.text-light h1,
.text-light h2,
.text-light h3,
.text-light h4,
.text-light h5,
.text-light h6,
.text-light .lead,
.text-light p,
.text-light a:not(.btn),
.text-light a:not(.btn):hover:not(.btn-light),
.text-light i,
.text-light li,
.text-light label,
.text-light div:not(.alert),
.text-light span:not(.btn-label) {
    color: #fafafa !important;
}

.text-dark,
.text-dark h1,
.text-dark h2,
.text-dark h3,
.text-dark h4,
.text-dark h5,
.text-dark h6,
.text-dark p,
.text-dark .lead,
.text-dark a:not(.btn),
.text-dark a:not(.btn-danger),
.text-dark a:not(.btn):hover:not(.btn-light),
.text-dark i,
.text-dark li,
.text-dark label,
.text-dark div:not(.alert),
.text-dark span:not(.btn-label) {
    color: #111 !important;
}

.text-grey,
.text-grey h1,
.text-grey h2,
.text-grey h3,
.text-grey h4,
.text-grey h5,
.text-grey h6,
.text-grey .lead,
.text-grey p,
.text-grey a:not(.button),
.text-grey a:not(.btn-light):hover,
.text-grey i,
.text-grey li,
.text-grey label,
.text-grey div:not(.alert),
.text-grey span:not(.btn-label) {
    color: #ccc;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white a,
.text-white i {
    color: #fff !important;
}

.with-errors li {
    color: #981a1a !important;
}

.text-bold {
    font-weight: bold !important;
}

.text-azure {
    color: #0095C8 !important;
}

.text-orange {
    color: #FF6600 !important;
}

.text-theme {
    color: #1290c6 !important;
}

.text-blue {
    color: #5F8295 !important;
}

.text-pink {
    color: #DD5A82 !important;
}

.text-purple {
    color: #DD5A82 !important;
}

.text-bricky {
    color: #894550 !important;
}

.text-yellow {
    color: #FFB848 !important;
}

.text-red {
    color: #ce2929 !important;
}


/*font weight*/

.font-weight-50 {
    font-weight: 50 !important;
}

.font-weight-100 {
    font-weight: 100 !important;
}

.font-weight-300 {
    font-weight: 300 !important;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.font-weight-800 {
    font-weight: 800 !important;
}

.border-box {
    border: 1px solid #eee;
    border-radius: 2px;
    padding: 30px;
}

.border-left {
    border-left: 1px solid #eee;
}

.border-top {
    border-top: 1px solid #eee;
}

.border-right {
    border-width: 0 1px 0 0;
    border-color: #eee;
    border-style: solid;
}

.border-bottom {
    border-bottom: 1px solid #eee;
}

.border-dotted {
    border-style: dotted;
}

.slider-size-half {
    max-height: 560px;
}

.no-margin {
    margin: 0px !important;
}

.m-b-0 {
    margin-bottom: 0px !important;
}

.m-b-5 {
    margin-bottom: 5px !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.m-b-15 {
    margin-bottom: 15px !important;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-b-25 {
    margin-bottom: 25px !important;
}

.m-b-30 {
    margin-bottom: 30px !important;
}

.m-b-35 {
    margin-bottom: 35px !important;
}

.m-b-40 {
    margin-bottom: 40px !important;
}

.m-b-50 {
    margin-bottom: 50px !important;
}

.m-b-60 {
    margin-bottom: 60px !important;
}

.m-b-70 {
    margin-bottom: 70px !important;
}

.m-b-80 {
    margin-bottom: 80px !important;
}

.m-b-90 {
    margin-bottom: 90px !important;
}

.m-b-100 {
    margin-bottom: 100px !important;
}

.m-b-150 {
    margin-bottom: 150px !important;
}

.m-b-200 {
    margin-bottom: 200px !important;
}

.m-t-0 {
    margin-top: 0px !important;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-t-15 {
    margin-top: 15px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-t-25 {
    margin-top: 25px !important;
}

.m-t-30 {
    margin-top: 30px !important;
}

.m-t-35 {
    margin-top: 35px !important;
}

.m-t-40 {
    margin-top: 40px !important;
}

.m-t-50 {
    margin-top: 50px !important;
}

.m-t-60 {
    margin-top: 60px !important;
}

.m-t-70 {
    margin-top: 70px !important;
}

.m-t-80 {
    margin-top: 80px !important;
}

.m-t-90 {
    margin-top: 90px !important;
}

.m-t-100 {
    margin-top: 100px !important;
}

.m-l-0 {
    margin-left: 0px !important;
}

.m-l-5 {
    margin-left: 5px !important;
}

.m-l-10 {
    margin-left: 10px !important;
}

.m-l-15 {
    margin-left: 15px !important;
}

.m-l-20 {
    margin-left: 20px !important;
}

.m-l-25 {
    margin-left: 25px !important;
}

.m-l-30 {
    margin-left: 30px !important;
}

.m-l-35 {
    margin-left: 35px !important;
}

.m-l-40 {
    margin-left: 40px !important;
}

.m-l-50 {
    margin-left: 50px !important;
}

.m-l-60 {
    margin-left: 60px !important;
}

.m-l-70 {
    margin-left: 70px !important;
}

.m-l-80 {
    margin-left: 80px !important;
}

.m-l-90 {
    margin-left: 90px !important;
}

.m-l-100 {
    margin-left: 100px !important;
}

.m-r-0 {
    margin-right: 0px !important;
}

.m-r-5 {
    margin-right: 5px !important;
}

.m-r-10 {
    margin-right: 10px !important;
}

.m-r-15 {
    margin-right: 15px !important;
}

.m-r-20 {
    margin-right: 20px !important;
}

.m-r-25 {
    margin-right: 25px !important;
}

.m-r-30 {
    margin-right: 30px !important;
}

.m-r-35 {
    margin-right: 35px !important;
}

.m-r-40 {
    margin-right: 40px !important;
}

.m-r-50 {
    margin-right: 50px !important;
}

.m-r-60 {
    margin-right: 60px !important;
}

.m-r-70 {
    margin-right: 70px !important;
}

.m-r-80 {
    margin-right: 80px !important;
}

.m-r-90 {
    margin-right: 90px !important;
}

.m-r-100 {
    margin-right: 100px !important;
}

.m-0 {
    margin: 0px !important;
}

.m-5 {
    margin: 5px !important;
}

.m-10 {
    margin: 10px !important;
}

.m-15 {
    margin: 15px !important;
}

.m-20 {
    margin: 20px !important;
}

.m-25 {
    margin: 25px !important;
}

.m-30 {
    margin: 30px !important;
}

.m-35 {
    margin: 35px !important;
}

.m-40 {
    margin: 40px !important;
}

.m-50 {
    margin: 50px !important;
}

.m-60 {
    margin: 60px !important;
}

.m-70 {
    margin: 70px !important;
}

.m-80 {
    margin: 80px !important;
}

.m-90 {
    margin: 90px !important;
}

.m-100 {
    margin: 100px !important;
}


/*Margins*/

.col-no-margin {
    padding: 0 !important;
}

.col-no-margin [class^="col-"] {
    margin: 0 !important;
    padding: 0 !important;
}

.row.col-no-margin {
    margin: 0 !important;
    padding: 0 !important;
}

.row.col-mdall-margins [class^="col-"] {
    margin: 0 0 20px 0 !important;
}

.no-padding-top {
    padding-top: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.p-0 {
    padding: 0px !important;
}

.p-5 {
    padding: 5px !important;
}

.p-10 {
    padding: 10px !important;
}

.p-15 {
    padding: 15px !important;
}

.p-20 {
    padding: 20px !important;
}

.p-25 {
    padding: 25px !important;
}

.p-30 {
    padding: 30px !important;
}

.p-35 {
    padding: 35px !important;
}

.p-40 {
    padding: 40px !important;
}

.p-50 {
    padding: 50px !important;
}

.p-60 {
    padding: 60px !important;
}

.p-70 {
    padding: 70px !important;
}

.p-80 {
    padding: 80px !important;
}

.p-90 {
    padding: 90px !important;
}

.p-100 {
    padding: 100px !important;
}

.p-150 {
    padding: 150px !important;
}

.p-200 {
    padding: 200px !important;
}

.p-b-0 {
    padding-bottom: 0px !important;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.p-b-10 {
    padding-bottom: 10px !important;
}

.p-b-15 {
    padding-bottom: 15px !important;
}

.p-b-20 {
    padding-bottom: 20px !important;
}

.p-b-25 {
    padding-bottom: 25px !important;
}

.p-b-30 {
    padding-bottom: 30px !important;
}

.p-b-35 {
    padding-bottom: 35px !important;
}

.p-b-40 {
    padding-bottom: 40px !important;
}

.p-b-50 {
    padding-bottom: 50px !important;
}

.p-b-60 {
    padding-bottom: 60px !important;
}

.p-b-70 {
    padding-bottom: 70px !important;
}

.p-b-80 {
    padding-bottom: 80px !important;
}

.p-b-90 {
    padding-bottom: 90px !important;
}

.p-b-100 {
    padding-bottom: 100px !important;
}

.p-b-110 {
    padding-bottom: 110px !important;
}

.p-b-120 {
    padding-bottom: 120px !important;
}

.p-b-130 {
    padding-bottom: 130px !important;
}

.p-b-140 {
    padding-bottom: 140px !important;
}

.p-b-150 {
    padding-bottom: 150px !important;
}

.p-b-200 {
    padding-bottom: 200px !important;
}

.p-t-0 {
    padding-top: 0px !important;
}

.p-t-5 {
    padding-top: 5px !important;
}

.p-t-10 {
    padding-top: 10px !important;
}

.p-t-15 {
    padding-top: 15px !important;
}

.p-t-20 {
    padding-top: 20px !important;
}

.p-t-25 {
    padding-top: 25px !important;
}

.p-t-30 {
    padding-top: 30px !important;
}

.p-t-35 {
    padding-top: 35px !important;
}

.p-t-40 {
    padding-top: 40px !important;
}

.p-t-50 {
    padding-top: 50px !important;
}

.p-t-60 {
    padding-top: 60px !important;
}

.p-t-70 {
    padding-top: 70px !important;
}

.p-t-80 {
    padding-top: 80px !important;
}

.p-t-90 {
    padding-top: 90px !important;
}

.p-t-100 {
    padding-top: 100px !important;
}

.p-t-110 {
    padding-top: 110px !important;
}

.p-t-120 {
    padding-top: 120px !important;
}

.p-t-130 {
    padding-top: 130px !important;
}

.p-t-140 {
    padding-top: 140px !important;
}

.p-t-150 {
    padding-top: 150px !important;
}

.p-t-200 {
    padding-top: 200px !important;
}

.p-r-0 {
    padding-right: 0px !important;
}

.p-r-5 {
    padding-right: 5px !important;
}

.p-r-10 {
    padding-right: 10px !important;
}

.p-r-15 {
    padding-right: 15px !important;
}

.p-r-20 {
    padding-right: 20px !important;
}

.p-r-25 {
    padding-right: 25px !important;
}

.p-r-30 {
    padding-right: 30px !important;
}

.p-r-35 {
    padding-right: 35px !important;
}

.p-r-40 {
    padding-right: 40px !important;
}

.p-r-50 {
    padding-right: 50px !important;
}

.p-r-60 {
    padding-right: 60px !important;
}

.p-r-70 {
    padding-right: 70px !important;
}

.p-r-80 {
    padding-right: 80px !important;
}

.p-r-90 {
    padding-right: 90px !important;
}

.p-r-100 {
    padding-right: 100px !important;
}

.p-l-0 {
    padding-left: 0px !important;
}

.p-l-5 {
    padding-left: 5px !important;
}

.p-l-10 {
    padding-left: 10px !important;
}

.p-l-15 {
    padding-left: 15px !important;
}

.p-l-20 {
    padding-left: 20px !important;
}

.p-l-25 {
    padding-left: 25px !important;
}

.p-l-30 {
    padding-left: 30px !important;
}

.p-l-35 {
    padding-left: 35px !important;
}

.p-l-40 {
    padding-left: 40px !important;
}

.p-l-50 {
    padding-left: 50px !important;
}

.p-l-60 {
    padding-left: 60px !important;
}

.p-l-70 {
    padding-left: 70px !important;
}

.p-l-80 {
    padding-left: 80px !important;
}

.p-l-90 {
    padding-left: 90px !important;
}

.p-l-100 {
    padding-left: 100px !important;
}

.b-r-2 {
    border-radius: 2px !important;
}

.b-r-3 {
    border-radius: 3px !important;
}

.b-r-4 {
    border-radius: 4px !important;
}

.b-r-5 {
    border-radius: 5px !important;
}

.b-r-6 {
    border-radius: 6px !important;
}

.b-r-7 {
    border-radius: 7px !important;
}

.b-r-8 {
    border-radius: 8px !important;
}

.b-r-9 {
    border-radius: 9px !important;
}

.b-r-10 {
    border-radius: 10px !important;
}

.b-radius {
    border-radius: 50% !important;
}

.float-right {
    float: right !important;
}

.float-left {
    float: left !important;
}

.float-none {
    float: none !important;
}

.fullwidth {
    width: 100% !important;
}


/*Line height*/

.lh80 {
    line-height: 80px !important;
}

.line {
    clear: both;
    position: relative;
    width: 100%;
    margin: 50px 0;
    border-top: 1px solid #e3e3e3;
}


/*Resposnive settings*/

@media (max-width: 1024px) {
    .text-lg,
    .text-lg span {
        font-size: 80px !important;
        line-height: 60px !important;
    }
    .text-lg-x2,
    .text-lg-x2 span,
    .text-lg-x3,
    .text-lg-x3 span {
        font-size: 70px !important;
        line-height: 70px !important;
    }
    .p-t-200 {
        padding-top: 80px !important;
    }
    .p-b-200 {
        padding-bottom: 80px !important;
    }
    .text-sm-center {
        text-align: center !important;
    }
    /*helper classes*/
    .sm-text-center {
        text-align: center !important;
    }
    .sm-text-left {
        text-align: left !important;
    }
    .sm-text-right {
        text-align: right !important;
    }
    .sm-left {
        float: left !important;
    }
    .sm-right {
        float: right !important;
    }
    .sm-center {
        float: none !important;
        margin: 0 auto;
    }
    .row.vertical-align {
        display: block;
    }
    .sm-m-b-0 {
        margin-bottom: 0px !important;
    }
    .sm-m-b-5 {
        margin-bottom: 5px !important;
    }
    .sm-m-b-10 {
        margin-bottom: 10px !important;
    }
    .sm-m-b-15 {
        margin-bottom: 15px !important;
    }
    .sm-m-b-20 {
        margin-bottom: 20px !important;
    }
    .sm-m-b-25 {
        margin-bottom: 25px !important;
    }
    .sm-m-b-30 {
        margin-bottom: 30px !important;
    }
    .sm-m-b-35 {
        margin-bottom: 35px !important;
    }
    .sm-m-b-40 {
        margin-bottom: 40px !important;
    }
    .sm-m-b-50 {
        margin-bottom: 50px !important;
    }
    .sm-m-b-60 {
        margin-bottom: 60px !important;
    }
    .sm-m-b-70 {
        margin-bottom: 70px !important;
    }
    .sm-m-b-80 {
        margin-bottom: 80px !important;
    }
    .sm-m-b-90 {
        margin-bottom: 90px !important;
    }
    .sm-m-b-100 {
        margin-bottom: 100px !important;
    }
    .sm-m-b-150 {
        margin-bottom: 150px !important;
    }
    .sm-m-b-200 {
        margin-bottom: 200px !important;
    }
    .sm-m-t-0 {
        margin-top: 0px !important;
    }
    .sm-m-t-5 {
        margin-top: 5px !important;
    }
    .sm-m-t-10 {
        margin-top: 10px !important;
    }
    .sm-m-t-15 {
        margin-top: 15px !important;
    }
    .sm-m-t-20 {
        margin-top: 20px !important;
    }
    .sm-m-t-25 {
        margin-top: 25px !important;
    }
    .sm-m-t-30 {
        margin-top: 30px !important;
    }
    .sm-m-t-35 {
        margin-top: 35px !important;
    }
    .sm-m-t-40 {
        margin-top: 40px !important;
    }
    .sm-m-t-50 {
        margin-top: 50px !important;
    }
    .sm-m-t-60 {
        margin-top: 60px !important;
    }
    .sm-m-t-70 {
        margin-top: 70px !important;
    }
    .sm-m-t-80 {
        margin-top: 80px !important;
    }
    .sm-m-t-90 {
        margin-top: 90px !important;
    }
    .sm-m-t-100 {
        margin-top: 100px !important;
    }
    .sm-m-l-0 {
        margin-left: 0px !important;
    }
    .sm-m-l-5 {
        margin-left: 5px !important;
    }
    .sm-m-l-10 {
        margin-left: 10px !important;
    }
    .sm-m-l-15 {
        margin-left: 15px !important;
    }
    .sm-m-l-20 {
        margin-left: 20px !important;
    }
    .sm-m-l-25 {
        margin-left: 25px !important;
    }
    .sm-m-l-30 {
        margin-left: 30px !important;
    }
    .sm-m-l-35 {
        margin-left: 35px !important;
    }
    .sm-m-l-40 {
        margin-left: 40px !important;
    }
    .sm-m-l-50 {
        margin-left: 50px !important;
    }
    .sm-m-l-60 {
        margin-left: 60px !important;
    }
    .sm-m-l-70 {
        margin-left: 70px !important;
    }
    .sm-m-l-80 {
        margin-left: 80px !important;
    }
    .sm-m-l-90 {
        margin-left: 90px !important;
    }
    .sm-m-l-100 {
        margin-left: 100px !important;
    }
    .sm-m-r-0 {
        margin-right: 0px !important;
    }
    .sm-m-r-5 {
        margin-right: 5px !important;
    }
    .sm-m-r-10 {
        margin-right: 10px !important;
    }
    .sm-m-r-15 {
        margin-right: 15px !important;
    }
    .sm-m-r-20 {
        margin-right: 20px !important;
    }
    .sm-m-r-25 {
        margin-right: 25px !important;
    }
    .sm-m-r-30 {
        margin-right: 30px !important;
    }
    .sm-m-r-35 {
        margin-right: 35px !important;
    }
    .sm-m-r-40 {
        margin-right: 40px !important;
    }
    .sm-m-r-50 {
        margin-right: 50px !important;
    }
    .sm-m-r-60 {
        margin-right: 60px !important;
    }
    .sm-m-r-70 {
        margin-right: 70px !important;
    }
    .sm-m-r-80 {
        margin-right: 80px !important;
    }
    .sm-m-r-90 {
        margin-right: 90px !important;
    }
    .sm-m-r-100 {
        margin-right: 100px !important;
    }
    .sm-m-0 {
        margin: 0px !important;
    }
    .sm-m-5 {
        margin: 5px !important;
    }
    .sm-m-10 {
        margin: 10px !important;
    }
    .sm-m-15 {
        margin: 15px !important;
    }
    .sm-m-20 {
        margin: 20px !important;
    }
    .sm-m-25 {
        margin: 25px !important;
    }
    .sm-m-30 {
        margin: 30px !important;
    }
    .sm-m-35 {
        margin: 35px !important;
    }
    .sm-m-40 {
        margin: 40px !important;
    }
    .sm-m-50 {
        margin: 50px !important;
    }
    .sm-m-60 {
        margin: 60px !important;
    }
    .sm-m-70 {
        margin: 70px !important;
    }
    .sm-m-80 {
        margin: 80px !important;
    }
    .sm-m-90 {
        margin: 90px !important;
    }
    .sm-m-100 {
        margin: 100px !important;
    }
}

@media (max-width: 768px) {
    .text-lg,
    .text-lg span {
        font-size: 40px !important;
        line-height: 40px !important;
    }
    .text-lg-x2,
    .text-lg-x2 span,
    .text-lg-x3,
    .text-lg-x3 span {
        font-size: 30px !important;
        line-height: 30px !important;
    }
    .text-medium,
    .text-medium-light {
        font-size: 40px !important;
    }
    .text-medium,
    .text-medium-light {
        font-size: 30px !important;
    }
    /*helper classes*/
    .xs-text-center {
        text-align: center !important;
    }
    .xs-text-left {
        text-align: left !important;
    }
    .xs-text-right {
        text-align: right !important;
    }
    /*paddings*/
    .p-xs-20 {
        padding: 20px !important;
    }
    .profile-content .profile-bio {
        width: 100% !important;
    }
}

.space {
    width: 100%;
    display: block;
    clear: both;
    height: 60px;
    position: relative;
}

@media (max-width: 768px) {
    .text-md,
    .text-md span,
    .text-md span span {
        font-size: 46px !important;
        line-height: 58px !important;
    }
}

@media (max-width: 576px) {
    .text-md,
    .text-md span,
    .text-md span span {
        font-size: 32px !important;
        line-height: 40px !important;
    }
}


/*  Components */


/* ----------------------------------------------------------------
    Bootstrap Notify
-----------------------------------------------------------------*/

.alert [data-notify="progressbar"] {
    height: 6px !important;
    margin-top: 8px;
}

.progress .progress-bar {
    height: 4px;
    background-color: #a0d68d;
}

.bootstrap-notify {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    z-index: 1500;
}

.bootstrap-notify.alert .close {
    padding: 4px;
}

.bootstrap-notify a,
.bootstrap-notify a:hover,
.bootstrap-notify a:visited {
    color: #ffffff !important;
    text-decoration: underline;
}

.element-container {
    box-shadow: none;
    display: block !important;
    margin: unset !important;
    position: relative !important;
    top: unset !important;
    right: unset !important;
    width: auto !important;
}


/* ----------------------------------------------------------------
	Date picker
-----------------------------------------------------------------*/

.input-daterange .input-group-addon {
    min-width: 44px;
}

.datepicker {
    width: 260px;
    padding: 10px !important;
}

.datepicker table {
    width: 100%;
}

.datepicker.datepicker-dropdown {
    box-shadow: 0px 0px 15px 1px rgba(94, 94, 94, 0.15);
}

.datepicker thead th,
.datepicker td,
.datepicker th {
    border-top: none;
    border-bottom: none;
}

.datepicker td,
.datepicker th {
    width: 34px !important;
    height: 32px !important;
    border-radius: 3px !important;
}

.datepicker tbody tr>td.day:hover {
    background: #ebedf2;
}

.datepicker tbody tr>td.day.old {
    color: #9c9c9c;
}

.datepicker tbody tr>td.day.new {
    color: #9c9c9c;
}

.datepicker tbody tr>td.day.today.active,
.datepicker tbody tr>td.day.active,
.datepicker tbody tr>td.day.selected {
    background: #1290c6 !important;
    color: #ffffff;
}

.datepicker tbody tr>td.day.today.active:hover,
.datepicker tbody tr>td.day.active:hover,
.datepicker tbody tr>td.day.selected:hover {
    background: #1290c6;
    color: #ffffff;
}

.datepicker tbody tr>td.day.today {
    opacity: .8;
}

.datepicker tbody tr>td.day.range {
    background: #f4f5f8;
}

.datepicker tbody tr>td.day.range.today {
    background: #1290c6;
    opacity: .4;
}

.datepicker tbody tr>td span.month {
    color: #575962;
}

.datepicker tbody tr>td span.month.focused,
.datepicker tbody tr>td span.month:hover {
    background: #ebedf2;
}

.datepicker tbody tr>td span.month.active {
    background: #1290c6;
    color: #fff;
}

.datepicker tbody tr>td span.month.active:hover {
    background: #1290c6;
    color: #fff;
}

.datepicker tfoot tr>th.clear:hover,
.datepicker tfoot tr>th.today:hover {
    background: #ebedf2;
}

.datepicker.datepicker-inline {
    border: 1px solid #f4f5f8;
}

.timepicker-picker a.btn {
    background: transparent !important;
    border: none !important;
}

.timepicker-picker a.btn span::before {
    color: #000;
}

.timepicker-picker a.btn:hover {
    background: #ebedf2;
}

.timepicker-picker .separator:before,
.timepicker-picker .separator:after {
    border: none !important;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
    width: auto;
    padding: 0px;
    box-shadow: 0px 0px 15px 1px rgba(94, 94, 94, 0.15);
    z-index: 1110 !important;
}

.timepicker,
.datetimepicker {
    width: 260px;
    padding: 10px;
}

.datetimepicker.datetimepicker-orient-top {
    margin-top: 8px;
}

.datetimepicker table {
    width: 100%;
}

.datetimepicker td,
.datetimepicker th {
    width: 34px !important;
    height: 32px !important;
    border-radius: 3px !important;
}

.datetimepicker thead th.next>span,
.datetimepicker thead th.prev>span {
    display: inline-block;
    font-family: inspiro-icons !important;
    font-size: 16px;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
}

.datetimepicker thead th.next>span:before {
    content: "\e930";
}

.datetimepicker thead th.prev>span:before {
    content: "\e92f";
}

.datetimepicker tbody tr>td.day.today {
    position: relative;
}

.datetimepicker tbody tr>td.day.today:before {
    content: '';
    display: inline-block;
    border: solid transparent;
    border-width: 0 0 7px 7px;
    border-bottom-color: #fff;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
}

.datetimepicker tfoot tr>th.clear,
.datetimepicker tfoot tr>th.today {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    font-weight: 500;
}

.input-daterange .input-group-addon {
    min-width: 44px;
}

.datetimepicker tbody tr>td.day.today,
.datetimepicker table tr td.active:active,
.datetimepicker table tr td.active:hover:active,
.datetimepicker table tr td.active.disabled:active,
.datetimepicker table tr td.active.disabled:hover:active,
.datetimepicker table tr td.active.active,
.datetimepicker table tr td.active:hover.active,
.datetimepicker table tr td.active.disabled.active,
.datetimepicker table tr td.active.disabled:hover.active,
.datetimepicker table tr td span.active:active,
.datetimepicker table tr td span.active:hover:active,
.datetimepicker table tr td span.active.disabled:active,
.datetimepicker table tr td span.active.disabled:hover:active,
.datetimepicker table tr td span.active.active,
.datetimepicker table tr td span.active:hover.active,
.datetimepicker table tr td span.active.disabled.active,
.datetimepicker table tr td span.active.disabled:hover.active {
    background: #1290c6 !important;
    color: #ffffff;
}

.datetimepicker table tr>td.today:hover,
.datetimepicker table tr>td.today:hover:hover,
.datetimepicker table tr>td.today.disabled:hover,
.datetimepicker table tr>td.today.disabled:hover:hover,
.datetimepicker table tr>td.today:active,
.datetimepicker table tr>td.today:hover:active,
.datetimepicker table tr>td.today.disabled:active,
.datetimepicker table tr>td.today.disabled:hover:active,
.datetimepicker table tr>td.today.active,
.datetimepicker table tr>td.today:hover.active,
.datetimepicker table tr>td.today.disabled.active,
.datetimepicker table tr>td.today.disabled:hover.active,
.datetimepicker table tr>td.today.disabled,
.datetimepicker table tr>td.today:hover.disabled,
.datetimepicker table tr>td.today.disabled.disabled,
.datetimepicker table tr>td.today.disabled:hover.disabled,
.datetimepicker table tr>td.today[disabled],
.datetimepicker table tr>td.today:hover[disabled],
.datetimepicker table tr>td.today.disabled[disabled],
.datetimepicker table tr>td.today.disabled:hover[disabled] {
    background: #1290c6 !important;
    color: #ffffff;
    opacity: .7;
}

.datetimepicker-dropdown-bottom-right:before {
    top: -7px;
    left: 6px;
}

.daterangepicker {
    padding: 10px;
    margin-top: 5px;
}

.daterangepicker .daterangepicker_input .input-mini {
    height: auto;
    padding: .65rem 1rem;
    padding-left: 2.2rem;
    line-height: 1.25;
}

.daterangepicker td,
.daterangepicker th {
    width: 35px;
    height: 35px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    border: 0;
    vertical-align: middle;
    line-height: 0;
}

.daterangepicker thead .fa.fa-chevron-left,
.daterangepicker thead .fa.fa-chevron-right {
    display: inline-block;
    font: normal normal normal 16px/1 LineAwesome;
    font-size: inherit;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

.daterangepicker thead .fa.fa-chevron-left:before {
    content: "";
}

.daterangepicker thead .fa.fa-chevron-right:before {
    content: "";
}

.daterangepicker thead th {
    font-weight: 500;
}

.daterangepicker thead th.next,
.daterangepicker thead th.prev {
    font-size: 1.3rem;
}

.daterangepicker tbody td.in-range.start-date.today {
    -webkit-border-top-left-radius: 0;
    -moz-border-top-left-radius: 0;
    -ms-border-top-left-radius: 0;
    -o-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-bottom-left-radius: 0;
    -ms-border-bottom-left-radius: 0;
    -o-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
}

.daterangepicker tbody td.in-range.end-date.today {
    -webkit-border-top-right-radius: 0;
    -moz-border-top-right-radius: 0;
    -ms-border-top-right-radius: 0;
    -o-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-bottom-right-radius: 0;
    -ms-border-bottom-right-radius: 0;
    -o-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
}

.daterangepicker tbody td.today {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    position: relative;
}

.daterangepicker tbody td.today.in-range {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    position: relative;
}

.daterangepicker tbody td.today.in-range:before {
    content: '';
    display: inline-block;
    border: solid transparent;
    border-width: 0 0 7px 7px;
    border-bottom-color: #fff;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
}

.daterangepicker tbody td.today:before {
    content: '';
    display: inline-block;
    border: solid transparent;
    border-width: 0 0 7px 7px;
    border-bottom-color: #fff;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
}

.daterangepicker select.ampmselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    padding: .35rem .75rem;
    outline: 0 !important;
}

.daterangepicker .calendar-time {
    margin: 15px 0 5px 0;
}

.daterangepicker .calendar-time select.ampmselect,
.daterangepicker .calendar-time select.hourselect,
.daterangepicker .calendar-time select.minuteselect {
    width: 70px;
}

.daterangepicker .calendar-time i {
    left: 3px;
    top: 5px;
    display: inline-block;
    font: normal normal normal 16px/1 LineAwesome;
    font-size: 1.4rem;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

.daterangepicker .calendar-time i:before {
    content: "";
}

.daterangepicker .ranges {
    padding: 10px;
    margin: 5px 5px 5px 10px;
}

.daterangepicker .ranges ul {
    width: 210px;
}

.daterangepicker .ranges ul li {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    background: 0 0;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-weight: 400;
    border-color: transparent;
}

.daterangepicker .ranges .range_inputs {
    margin-top: 15px;
}

.daterangepicker .ranges .range_inputs .btn {
    padding: .6rem 1.6rem;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    border-radius: 40px;
    margin-right: 6px;
}

.daterangepicker .daterangepicker_input .input-mini {
    height: auto;
    border: 1px solid #ebedf2;
}

.daterangepicker .daterangepicker_input .input-mini.active,
.daterangepicker .daterangepicker_input .input-mini:focus {
    border: 1px solid #1290c6;
}

.daterangepicker .daterangepicker_input i {
    color: #b2b1c5;
}

.daterangepicker thead th.next:hover,
.daterangepicker thead th.prev:hover {
    background: #ebedf2;
}

.daterangepicker tbody td.available:hover,
.daterangepicker tbody td:hover {
    background: #ebedf2;
}

.daterangepicker tbody td.off {
    color: #7b7e8a;
}

.daterangepicker tbody td.available {
    color: #575962;
}

.daterangepicker tbody td.today {
    background: #1290c6;
    color: #fff;
}

.daterangepicker tbody td.today:before {
    border-bottom-color: #fff;
    border-top-color: rgba(0, 0, 0, 0.2);
}

.daterangepicker tbody td.today.in-range {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
}

.daterangepicker tbody td.active,
.daterangepicker tbody td.in-range,
.daterangepicker tbody td.active:hover,
.daterangepicker tbody td.in-range:hover {
    background: #1290c6;
    color: #fff;
}

.daterangepicker select.ampmselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
    border: 1px solid #ebedf2;
}

.daterangepicker select.ampmselect.active,
.daterangepicker select.ampmselect:focus {
    border: 1px solid #1290c6;
}

.daterangepicker select.hourselect.active,
.daterangepicker select.hourselect:focus {
    border: 1px solid #1290c6;
}

.daterangepicker select.minuteselect.active,
.daterangepicker select.minuteselect:focus {
    border: 1px solid #1290c6;
}

.daterangepicker select.monthselect.active,
.daterangepicker select.monthselect:focus {
    border: 1px solid #1290c6;
}

.daterangepicker select.yearselect.active,
.daterangepicker select.yearselect:focus {
    border: 1px solid #1290c6;
}

.daterangepicker .calendar-time i {
    color: #b2b1c5;
}

.daterangepicker .ranges li {
    border: 1px solid #ebedf2;
    color: #7b7e8a;
}

.daterangepicker .ranges li:hover {
    background: 0 0;
    color: #7b7e8a;
    background: #f9f9fd;
}

.daterangepicker .ranges li.active {
    background: #1290c6;
    color: #fff;
    border: 1px solid #1290c6;
}


/* ----------------------------------------------------------------
	Date range picker
-----------------------------------------------------------------*/

.daterangepicker .ranges {
    padding: 0;
    margin: 0;
}

.daterangepicker .ranges ul>li:last-child {
    margin-bottom: 0 !important;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
    padding: 5px 8px !important;
    border: 0px;
    margin-bottom: 4px;
}

.daterangepicker td.in-range {
    background: #f4f5f8 !important;
    color: #212529 !important;
}

.daterangepicker td.active:not(.off),
.daterangepicker td.active:hover,
.daterangepicker .active.end-date.in-range {
    background: #1290c6 !important;
    color: #ffffff !important;
}

.daterangepicker .drp-buttons .btn {
    padding: 10px 10px;
    line-height: 10px;
}

input.daterange:after,
input.dates:after {
    font-family: inspiro-icons !important;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    content: "\e951";
    position: absolute;
    right: 4px;
    background: blue;
}

.daterangepicker .calendar-time select.ampmselect,
.daterangepicker .calendar-time select.hourselect,
.daterangepicker .calendar-time select.minuteselect {
    min-height: 20px;
    background-color: #fff !important;
}


/*  Elements */


/* ----------------------------------------------------------------
	Accordion
-----------------------------------------------------------------*/

.accordion .ac-item {
    margin-bottom: 10px;
    padding: 12px 20px;
    border-radius: 5px;
    border: 1px solid #e6e8eb;
}

.accordion .ac-item .ac-title {
    font-weight: 500;
    position: relative;
    padding-right: 10px;
    margin: 0;
    font-size: 1em;
    cursor: pointer;
    user-select: none;
}

.accordion .ac-item .ac-title:before {
    font-size: 18px;
    content: "\e9be";
    font-family: "inspiro-icons";
    right: 0;
    position: absolute;
}

.accordion .ac-item .ac-title>i {
    margin-right: 8px;
}

.accordion .ac-item .ac-content {
    padding-top: 12px;
}

.accordion .ac-item .ac-content>p:last-child {
    margin-bottom: 6px;
}

.accordion .ac-item.ac-active .ac-title:before {
    content: "\e9a1";
}

.accordion.accordion-shadow .ac-item {
    box-shadow: 0 6px 38px rgba(0, 0, 0, 0.05);
}

.accordion.accordion-simple .ac-item {
    padding: 14px 0;
    margin-bottom: 0;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #e6e8eb;
}

.accordion.accordion-simple .ac-item:last-child {
    border: 0;
}

.accordion.accordion-flat .ac-item {
    padding: 10px 0;
    margin-bottom: 0;
    border-radius: 0;
    border: 0;
}

.accordion.accordion-theme .ac-item {
    background-color: #1290c6;
    border: 0;
}

.accordion.accordion-theme .ac-item .ac-title {
    color: #ffffff;
}

.accordion.accordion-theme .ac-item .ac-content,
.accordion.accordion-theme .ac-item .ac-content p {
    color: #ffffff;
}

.accordion.dark .ac-item {
    background-color: #181818;
    border: 0;
}

.accordion.dark .ac-item .ac-title {
    color: #ffffff;
}

.accordion.dark .ac-item .ac-content,
.accordion.dark .ac-item .ac-content p {
    color: #ffffff;
}


/* ----------------------------------------------------------------
	Alert
-----------------------------------------------------------------*/

.alert {
    color: #ffffff;
    padding: 0.85rem 1.5rem;
    z-index: 9999;
}

.alert span[data-notify~="title"] {
    display: block;
    font-weight: bold;
}

.alert span.fas {
    font-size: 24px;
    float: left;
    padding-top: 10px;
    min-width: 40px;
    min-height: 40px;
}

.alert .close {
    color: #ffffff;
    font-weight: 300;
    padding: 12px 18px;
}

.alert>.alert-icon {
    float: left;
}

.alert>.alert-icon i {
    font-size: 34px;
}

.alert>p {
    padding-left: 54px;
}

.alert.alert-primary {
    background-color: #1290c6;
    border-color: #093cfc;
}

.alert.alert-secondary {
    background-color: #5A6268;
    border-color: #4e555a;
}

.alert.alert-success {
    background-color: #81c868;
    border-color: #71c155;
}

.alert.alert-info {
    background-color: #53b0f8;
    border-color: #3aa5f7;
}

.alert.alert-danger {
    background-color: #CE041C;
    border-color: #b50419;
}

.alert.alert-warning {
    background-color: #ffb20e;
    border-color: #f4a600;
}

.alert.alert-light {
    color: #9896a6;
    background-color: #f8f9fa;
    border-color: #e6e8eb;
}

.alert.alert-light .alert-link,
.alert.alert-light h1,
.alert.alert-light h2,
.alert.alert-light h3,
.alert.alert-light h4,
.alert.alert-light h5,
.alert.alert-light h6 {
    color: #9896a6;
}

.alert.alert-dark {
    background-color: #9896a6;
    border-color: #8a889a;
}

.alert h1,
.alert h2,
.alert h3,
.alert h4,
.alert h5,
.alert h6,
.alert .alert-link {
    color: #ffffff;
}

.alert [data-notify="progressbar"] {
    height: 2px !important;
    margin-top: 8px;
}

.avatar {
    flex-shrink: 0;
    width: 2.7rem;
    height: 2.7rem;
    border-radius: 50%;
}

.avatar .avatar-lg {
    width: 3rem;
    height: 3rem;
}

.avatar .avatar-sm {
    width: 1.5rem;
    height: 1.5rem;
}

.avatar-xl {
    width: 9rem;
    height: 9rem;
}

.avatar-lg {
    width: 3rem;
    height: 3rem;
}

.avatar-sm {
    width: 1.5rem;
    height: 1.5rem;
}

ul.avatars {
    display: flex;
    padding: 0;
    list-style: none;
}

ul.avatars>li:not(:last-child) {
    margin-right: -0.375rem;
}

@media (min-width: 1024px) {
    .avatar.avatar-lg {
        width: 4.5rem;
        height: 4.5rem;
    }
}


/* ----------------------------------------------------------------------
	Buttons
-------------------------------------------------------------------------*/

a:not([href]):not([tabindex]):not(.btn) {
    color: none;
}

button.btn,
.btn:not(.close):not(.mfp-close),
a.btn:not([href]):not([tabindex]) {
    font-family: "Poppins", sans-serif;
    border-radius: 5px 5px 5px 5px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .5px;
    text-decoration: none;
    outline: none;
    padding: 12px 18px;
    position: relative;
    text-transform: uppercase;
    background-color: #1290c6;
    border-color: #1290c6;
    border-width: 1px;
    border-style: solid;
    color: #ffffff;
    transition: all 0.3s;
    margin-bottom: 6px;
    outline: none;
    line-height: 14px;
    cursor: pointer;
}

button.btn:after,
.btn:not(.close):not(.mfp-close):after,
a.btn:not([href]):not([tabindex]):after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

button.btn i,
.btn:not(.close):not(.mfp-close) i,
a.btn:not([href]):not([tabindex]) i {
    text-shadow: none;
}

button.btn.btn-xs,
.btn:not(.close):not(.mfp-close).btn-xs,
a.btn:not([href]):not([tabindex]).btn-xs {
    font-size: 10px;
    height: 24px;
    line-height: 22px;
    padding: 0 10px;
}

button.btn.btn-sm,
.btn:not(.close):not(.mfp-close).btn-sm,
a.btn:not([href]):not([tabindex]).btn-sm {
    font-size: 11px;
    height: 30px;
    line-height: 28px;
    padding: 0 14px;
}

button.btn.btn-lg,
.btn:not(.close):not(.mfp-close).btn-lg,
a.btn:not([href]):not([tabindex]).btn-lg {
    font-size: 16px;
    height: 58px;
    letter-spacing: 2px;
    line-height: 56px;
    padding: 0 40px;
}

button.btn:hover,
button.btn:focus,
button.btn:not(:disabled):not(.disabled):active,
button.btn:not(:disabled):not(.disabled).active,
.btn:not(.close):not(.mfp-close):hover,
.btn:not(.close):not(.mfp-close):focus,
.btn:not(.close):not(.mfp-close):not(:disabled):not(.disabled):active,
.btn:not(.close):not(.mfp-close):not(:disabled):not(.disabled).active,
a.btn:not([href]):not([tabindex]):hover,
a.btn:not([href]):not([tabindex]):focus,
a.btn:not([href]):not([tabindex]):not(:disabled):not(.disabled):active,
a.btn:not([href]):not([tabindex]):not(:disabled):not(.disabled).active {
    background-color: #161514;
    border-color: #161514;
    color: #ffffff;
    text-decoration: none;
}

button.btn.btn-outline,
.btn:not(.close):not(.mfp-close).btn-outline,
a.btn:not([href]):not([tabindex]).btn-outline {
    background-color: transparent;
    border-width: 2px;
    border-style: solid;
    border-color: #1290c6;
    color: #1290c6;
}

button.btn.btn-outline:hover,
button.btn.btn-outline:focus,
button.btn.btn-outline:active,
button.btn.btn-outline.active,
.btn:not(.close):not(.mfp-close).btn-outline:hover,
.btn:not(.close):not(.mfp-close).btn-outline:focus,
.btn:not(.close):not(.mfp-close).btn-outline:active,
.btn:not(.close):not(.mfp-close).btn-outline.active,
a.btn:not([href]):not([tabindex]).btn-outline:hover,
a.btn:not([href]):not([tabindex]).btn-outline:focus,
a.btn:not([href]):not([tabindex]).btn-outline:active,
a.btn:not([href]):not([tabindex]).btn-outline.active {
    background-color: #1290c6;
    border-width: 2px;
    border-style: solid;
    border-color: #1290c6;
    color: #fff;
}

button.btn.btn-rounded,
.btn:not(.close):not(.mfp-close).btn-rounded,
a.btn:not([href]):not([tabindex]).btn-rounded {
    border-radius: 2em !important;
}

button.btn.btn-block,
.btn:not(.close):not(.mfp-close).btn-block,
a.btn:not([href]):not([tabindex]).btn-block {
    width: 100%;
    display: block;
}

button.btn.btn-white,
button.btn.btn-light,
.btn:not(.close):not(.mfp-close).btn-white,
.btn:not(.close):not(.mfp-close).btn-light,
a.btn:not([href]):not([tabindex]).btn-white,
a.btn:not([href]):not([tabindex]).btn-light {
    background-color: #fff;
    border-color: #e6e8eb;
    color: #4c5667;
}

button.btn.btn-white:hover,
button.btn.btn-white:focus,
button.btn.btn-white:active,
button.btn.btn-white.active,
button.btn.btn-light:hover,
button.btn.btn-light:focus,
button.btn.btn-light:active,
button.btn.btn-light.active,
.btn:not(.close):not(.mfp-close).btn-white:hover,
.btn:not(.close):not(.mfp-close).btn-white:focus,
.btn:not(.close):not(.mfp-close).btn-white:active,
.btn:not(.close):not(.mfp-close).btn-white.active,
.btn:not(.close):not(.mfp-close).btn-light:hover,
.btn:not(.close):not(.mfp-close).btn-light:focus,
.btn:not(.close):not(.mfp-close).btn-light:active,
.btn:not(.close):not(.mfp-close).btn-light.active,
a.btn:not([href]):not([tabindex]).btn-white:hover,
a.btn:not([href]):not([tabindex]).btn-white:focus,
a.btn:not([href]):not([tabindex]).btn-white:active,
a.btn:not([href]):not([tabindex]).btn-white.active,
a.btn:not([href]):not([tabindex]).btn-light:hover,
a.btn:not([href]):not([tabindex]).btn-light:focus,
a.btn:not([href]):not([tabindex]).btn-light:active,
a.btn:not([href]):not([tabindex]).btn-light.active {
    border-color: #e6e8eb;
    background-color: #f4f5f8;
    color: #4c5667;
}

button.btn.btn-white.btn-outline,
button.btn.btn-light.btn-outline,
.btn:not(.close):not(.mfp-close).btn-white.btn-outline,
.btn:not(.close):not(.mfp-close).btn-light.btn-outline,
a.btn:not([href]):not([tabindex]).btn-white.btn-outline,
a.btn:not([href]):not([tabindex]).btn-light.btn-outline {
    border-color: #ffffff;
    background-color: transparent;
    color: #ffffff;
}

button.btn.btn-white.btn-outline:hover,
button.btn.btn-white.btn-outline:focus,
button.btn.btn-white.btn-outline:active,
button.btn.btn-white.btn-outline.active,
button.btn.btn-light.btn-outline:hover,
button.btn.btn-light.btn-outline:focus,
button.btn.btn-light.btn-outline:active,
button.btn.btn-light.btn-outline.active,
.btn:not(.close):not(.mfp-close).btn-white.btn-outline:hover,
.btn:not(.close):not(.mfp-close).btn-white.btn-outline:focus,
.btn:not(.close):not(.mfp-close).btn-white.btn-outline:active,
.btn:not(.close):not(.mfp-close).btn-white.btn-outline.active,
.btn:not(.close):not(.mfp-close).btn-light.btn-outline:hover,
.btn:not(.close):not(.mfp-close).btn-light.btn-outline:focus,
.btn:not(.close):not(.mfp-close).btn-light.btn-outline:active,
.btn:not(.close):not(.mfp-close).btn-light.btn-outline.active,
a.btn:not([href]):not([tabindex]).btn-white.btn-outline:hover,
a.btn:not([href]):not([tabindex]).btn-white.btn-outline:focus,
a.btn:not([href]):not([tabindex]).btn-white.btn-outline:active,
a.btn:not([href]):not([tabindex]).btn-white.btn-outline.active,
a.btn:not([href]):not([tabindex]).btn-light.btn-outline:hover,
a.btn:not([href]):not([tabindex]).btn-light.btn-outline:focus,
a.btn:not([href]):not([tabindex]).btn-light.btn-outline:active,
a.btn:not([href]):not([tabindex]).btn-light.btn-outline.active {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #4c5667;
}

button.btn.btn-white.btn-white-hover:hover,
button.btn.btn-white.btn-white-hover:focus,
button.btn.btn-white.btn-white-hover:active,
button.btn.btn-white.btn-white-hover.active,
button.btn.btn-white.btn-light-hover:hover,
button.btn.btn-white.btn-light-hover:focus,
button.btn.btn-white.btn-light-hover:active,
button.btn.btn-white.btn-light-hover.active,
button.btn.btn-light.btn-white-hover:hover,
button.btn.btn-light.btn-white-hover:focus,
button.btn.btn-light.btn-white-hover:active,
button.btn.btn-light.btn-white-hover.active,
button.btn.btn-light.btn-light-hover:hover,
button.btn.btn-light.btn-light-hover:focus,
button.btn.btn-light.btn-light-hover:active,
button.btn.btn-light.btn-light-hover.active,
.btn:not(.close):not(.mfp-close).btn-white.btn-white-hover:hover,
.btn:not(.close):not(.mfp-close).btn-white.btn-white-hover:focus,
.btn:not(.close):not(.mfp-close).btn-white.btn-white-hover:active,
.btn:not(.close):not(.mfp-close).btn-white.btn-white-hover.active,
.btn:not(.close):not(.mfp-close).btn-white.btn-light-hover:hover,
.btn:not(.close):not(.mfp-close).btn-white.btn-light-hover:focus,
.btn:not(.close):not(.mfp-close).btn-white.btn-light-hover:active,
.btn:not(.close):not(.mfp-close).btn-white.btn-light-hover.active,
.btn:not(.close):not(.mfp-close).btn-light.btn-white-hover:hover,
.btn:not(.close):not(.mfp-close).btn-light.btn-white-hover:focus,
.btn:not(.close):not(.mfp-close).btn-light.btn-white-hover:active,
.btn:not(.close):not(.mfp-close).btn-light.btn-white-hover.active,
.btn:not(.close):not(.mfp-close).btn-light.btn-light-hover:hover,
.btn:not(.close):not(.mfp-close).btn-light.btn-light-hover:focus,
.btn:not(.close):not(.mfp-close).btn-light.btn-light-hover:active,
.btn:not(.close):not(.mfp-close).btn-light.btn-light-hover.active,
a.btn:not([href]):not([tabindex]).btn-white.btn-white-hover:hover,
a.btn:not([href]):not([tabindex]).btn-white.btn-white-hover:focus,
a.btn:not([href]):not([tabindex]).btn-white.btn-white-hover:active,
a.btn:not([href]):not([tabindex]).btn-white.btn-white-hover.active,
a.btn:not([href]):not([tabindex]).btn-white.btn-light-hover:hover,
a.btn:not([href]):not([tabindex]).btn-white.btn-light-hover:focus,
a.btn:not([href]):not([tabindex]).btn-white.btn-light-hover:active,
a.btn:not([href]):not([tabindex]).btn-white.btn-light-hover.active,
a.btn:not([href]):not([tabindex]).btn-light.btn-white-hover:hover,
a.btn:not([href]):not([tabindex]).btn-light.btn-white-hover:focus,
a.btn:not([href]):not([tabindex]).btn-light.btn-white-hover:active,
a.btn:not([href]):not([tabindex]).btn-light.btn-white-hover.active,
a.btn:not([href]):not([tabindex]).btn-light.btn-light-hover:hover,
a.btn:not([href]):not([tabindex]).btn-light.btn-light-hover:focus,
a.btn:not([href]):not([tabindex]).btn-light.btn-light-hover:active,
a.btn:not([href]):not([tabindex]).btn-light.btn-light-hover.active {
    background-color: #ffffff;
    border-color: #ebebeb;
    color: #4c5667;
}

button.btn:not(.btn-shadow):focus,
button.btn:not(.btn-shadow):focus:active,
.btn:not(.close):not(.mfp-close):not(.btn-shadow):focus,
.btn:not(.close):not(.mfp-close):not(.btn-shadow):focus:active,
a.btn:not([href]):not([tabindex]):not(.btn-shadow):focus,
a.btn:not([href]):not([tabindex]):not(.btn-shadow):focus:active {
    box-shadow: none !important;
}

button.btn.btn-transparent,
.btn:not(.close):not(.mfp-close).btn-transparent,
a.btn:not([href]):not([tabindex]).btn-transparent {
    background-color: transparent;
    border: 0;
    color: #484848;
}

button.btn.btn-transparent.btn-inverted,
.btn:not(.close):not(.mfp-close).btn-transparent.btn-inverted,
a.btn:not([href]):not([tabindex]).btn-transparent.btn-inverted {
    color: #ffffff;
}

button.btn.btn-primary,
.btn:not(.close):not(.mfp-close).btn-primary,
a.btn:not([href]):not([tabindex]).btn-primary {
    background-color: #1290c6;
    border-color: #1290c6;
}

button.btn.btn-primary:hover,
button.btn.btn-primary:focus,
button.btn.btn-primary:not(:disabled):not(.disabled):active,
button.btn.btn-primary:not(:disabled):not(.disabled).active,
.btn:not(.close):not(.mfp-close).btn-primary:hover,
.btn:not(.close):not(.mfp-close).btn-primary:focus,
.btn:not(.close):not(.mfp-close).btn-primary:not(:disabled):not(.disabled):active,
.btn:not(.close):not(.mfp-close).btn-primary:not(:disabled):not(.disabled).active,
a.btn:not([href]):not([tabindex]).btn-primary:hover,
a.btn:not([href]):not([tabindex]).btn-primary:focus,
a.btn:not([href]):not([tabindex]).btn-primary:not(:disabled):not(.disabled):active,
a.btn:not([href]):not([tabindex]).btn-primary:not(:disabled):not(.disabled).active {
    background-color: #161514;
    border-color: #161514;
    color: #ffffff;
}

button.btn.btn-secondary,
.btn:not(.close):not(.mfp-close).btn-secondary,
a.btn:not([href]):not([tabindex]).btn-secondary {
    background-color: #5A6268;
    border-color: #5A6268;
}

button.btn.btn-secondary:hover,
button.btn.btn-secondary:focus,
button.btn.btn-secondary:not(:disabled):not(.disabled):active,
button.btn.btn-secondary:not(:disabled):not(.disabled).active,
.btn:not(.close):not(.mfp-close).btn-secondary:hover,
.btn:not(.close):not(.mfp-close).btn-secondary:focus,
.btn:not(.close):not(.mfp-close).btn-secondary:not(:disabled):not(.disabled):active,
.btn:not(.close):not(.mfp-close).btn-secondary:not(:disabled):not(.disabled).active,
a.btn:not([href]):not([tabindex]).btn-secondary:hover,
a.btn:not([href]):not([tabindex]).btn-secondary:focus,
a.btn:not([href]):not([tabindex]).btn-secondary:not(:disabled):not(.disabled):active,
a.btn:not([href]):not([tabindex]).btn-secondary:not(:disabled):not(.disabled).active {
    background-color: #42484d;
    border-color: #42484d;
    color: #ffffff;
}

button.btn.btn-success,
.btn:not(.close):not(.mfp-close).btn-success,
a.btn:not([href]):not([tabindex]).btn-success {
    background-color: #81c868;
    border-color: #81c868;
}

button.btn.btn-success:hover,
button.btn.btn-success:focus,
button.btn.btn-success:not(:disabled):not(.disabled):active,
button.btn.btn-success:not(:disabled):not(.disabled).active,
.btn:not(.close):not(.mfp-close).btn-success:hover,
.btn:not(.close):not(.mfp-close).btn-success:focus,
.btn:not(.close):not(.mfp-close).btn-success:not(:disabled):not(.disabled):active,
.btn:not(.close):not(.mfp-close).btn-success:not(:disabled):not(.disabled).active,
a.btn:not([href]):not([tabindex]).btn-success:hover,
a.btn:not([href]):not([tabindex]).btn-success:focus,
a.btn:not([href]):not([tabindex]).btn-success:not(:disabled):not(.disabled):active,
a.btn:not([href]):not([tabindex]).btn-success:not(:disabled):not(.disabled).active {
    background-color: #62b944;
    border-color: #62b944;
    color: #ffffff;
}

button.btn.btn-info,
.btn:not(.close):not(.mfp-close).btn-info,
a.btn:not([href]):not([tabindex]).btn-info {
    background-color: #53b0f8;
    border-color: #53b0f8;
}

button.btn.btn-info:hover,
button.btn.btn-info:focus,
button.btn.btn-info:not(:disabled):not(.disabled):active,
button.btn.btn-info:not(:disabled):not(.disabled).active,
.btn:not(.close):not(.mfp-close).btn-info:hover,
.btn:not(.close):not(.mfp-close).btn-info:focus,
.btn:not(.close):not(.mfp-close).btn-info:not(:disabled):not(.disabled):active,
.btn:not(.close):not(.mfp-close).btn-info:not(:disabled):not(.disabled).active,
a.btn:not([href]):not([tabindex]).btn-info:hover,
a.btn:not([href]):not([tabindex]).btn-info:focus,
a.btn:not([href]):not([tabindex]).btn-info:not(:disabled):not(.disabled):active,
a.btn:not([href]):not([tabindex]).btn-info:not(:disabled):not(.disabled).active {
    background-color: #2299f6;
    border-color: #2299f6;
    color: #ffffff;
}

button.btn.btn-warning,
.btn:not(.close):not(.mfp-close).btn-warning,
a.btn:not([href]):not([tabindex]).btn-warning {
    background-color: #ffb20e;
    border-color: #ffb20e;
}

button.btn.btn-warning:hover,
button.btn.btn-warning:focus,
button.btn.btn-warning:not(:disabled):not(.disabled):active,
button.btn.btn-warning:not(:disabled):not(.disabled).active,
.btn:not(.close):not(.mfp-close).btn-warning:hover,
.btn:not(.close):not(.mfp-close).btn-warning:focus,
.btn:not(.close):not(.mfp-close).btn-warning:not(:disabled):not(.disabled):active,
.btn:not(.close):not(.mfp-close).btn-warning:not(:disabled):not(.disabled).active,
a.btn:not([href]):not([tabindex]).btn-warning:hover,
a.btn:not([href]):not([tabindex]).btn-warning:focus,
a.btn:not([href]):not([tabindex]).btn-warning:not(:disabled):not(.disabled):active,
a.btn:not([href]):not([tabindex]).btn-warning:not(:disabled):not(.disabled).active {
    background-color: #da9400;
    border-color: #da9400;
    color: #ffffff;
}

button.btn.btn-danger,
.btn:not(.close):not(.mfp-close).btn-danger,
a.btn:not([href]):not([tabindex]).btn-danger {
    background-color: #CE041C;
    border-color: #CE041C;
}

button.btn.btn-danger:hover,
button.btn.btn-danger:focus,
button.btn.btn-danger:not(:disabled):not(.disabled):active,
button.btn.btn-danger:not(:disabled):not(.disabled).active,
.btn:not(.close):not(.mfp-close).btn-danger:hover,
.btn:not(.close):not(.mfp-close).btn-danger:focus,
.btn:not(.close):not(.mfp-close).btn-danger:not(:disabled):not(.disabled):active,
.btn:not(.close):not(.mfp-close).btn-danger:not(:disabled):not(.disabled).active,
a.btn:not([href]):not([tabindex]).btn-danger:hover,
a.btn:not([href]):not([tabindex]).btn-danger:focus,
a.btn:not([href]):not([tabindex]).btn-danger:not(:disabled):not(.disabled):active,
a.btn:not([href]):not([tabindex]).btn-danger:not(:disabled):not(.disabled).active {
    background-color: #9c0315;
    border-color: #9c0315;
    color: #ffffff;
}

button.btn.btn-dark,
.btn:not(.close):not(.mfp-close).btn-dark,
a.btn:not([href]):not([tabindex]).btn-dark {
    background-color: #000000;
    border-color: #000000;
}

button.btn.btn-dark:hover,
button.btn.btn-dark:focus,
button.btn.btn-dark:active,
button.btn.btn-dark.active,
.btn:not(.close):not(.mfp-close).btn-dark:hover,
.btn:not(.close):not(.mfp-close).btn-dark:focus,
.btn:not(.close):not(.mfp-close).btn-dark:active,
.btn:not(.close):not(.mfp-close).btn-dark.active,
a.btn:not([href]):not([tabindex]).btn-dark:hover,
a.btn:not([href]):not([tabindex]).btn-dark:focus,
a.btn:not([href]):not([tabindex]).btn-dark:active,
a.btn:not([href]):not([tabindex]).btn-dark.active {
    background-color: #333333;
    border-color: #333333;
    color: #ffffff;
}

button.btn.btn-dark.btn-outline,
.btn:not(.close):not(.mfp-close).btn-dark.btn-outline,
a.btn:not([href]):not([tabindex]).btn-dark.btn-outline {
    background-color: transparent;
    color: #000000;
    border-color: #000000;
}

button.btn.btn-dark.btn-outline:hover,
button.btn.btn-dark.btn-outline:focus,
button.btn.btn-dark.btn-outline:active,
button.btn.btn-dark.btn-outline.active,
.btn:not(.close):not(.mfp-close).btn-dark.btn-outline:hover,
.btn:not(.close):not(.mfp-close).btn-dark.btn-outline:focus,
.btn:not(.close):not(.mfp-close).btn-dark.btn-outline:active,
.btn:not(.close):not(.mfp-close).btn-dark.btn-outline.active,
a.btn:not([href]):not([tabindex]).btn-dark.btn-outline:hover,
a.btn:not([href]):not([tabindex]).btn-dark.btn-outline:focus,
a.btn:not([href]):not([tabindex]).btn-dark.btn-outline:active,
a.btn:not([href]):not([tabindex]).btn-dark.btn-outline.active {
    background-color: #000000;
    border-color: #000000;
    color: #ffffff;
}

button.btn.btn-red,
.btn:not(.close):not(.mfp-close).btn-red,
a.btn:not([href]):not([tabindex]).btn-red {
    background-color: #ce2929;
    border-color: #ce2929;
}

button.btn.btn-red:hover,
button.btn.btn-red:focus,
button.btn.btn-red:not(:disabled):not(.disabled):active,
button.btn.btn-red:not(:disabled):not(.disabled).active,
.btn:not(.close):not(.mfp-close).btn-red:hover,
.btn:not(.close):not(.mfp-close).btn-red:focus,
.btn:not(.close):not(.mfp-close).btn-red:not(:disabled):not(.disabled):active,
.btn:not(.close):not(.mfp-close).btn-red:not(:disabled):not(.disabled).active,
a.btn:not([href]):not([tabindex]).btn-red:hover,
a.btn:not([href]):not([tabindex]).btn-red:focus,
a.btn:not([href]):not([tabindex]).btn-red:not(:disabled):not(.disabled):active,
a.btn:not([href]):not([tabindex]).btn-red:not(:disabled):not(.disabled).active {
    background-color: #161514;
    border-color: #161514;
    color: #ffffff;
}

button.btn.btn-round,
.btn:not(.close):not(.mfp-close).btn-round,
a.btn:not([href]):not([tabindex]).btn-round {
    border-radius: 50%;
    padding: 0 10px !important;
    height: 36px !important;
    line-height: 36px !important;
}

button.btn.btn-round>i,
.btn:not(.close):not(.mfp-close).btn-round>i,
a.btn:not([href]):not([tabindex]).btn-round>i {
    font-size: 14px;
}

button.btn.btn-slide,
.btn:not(.close):not(.mfp-close).btn-slide,
a.btn:not([href]):not([tabindex]).btn-slide {
    border-radius: 50px;
    display: table-cell;
    opacity: 1;
    overflow: hidden;
    padding: 22px;
    position: relative;
    transition: width 0.4s ease 0s, background 0.4s ease 0s;
    width: 46px;
    line-height: 18px;
}

button.btn.btn-slide>i,
.btn:not(.close):not(.mfp-close).btn-slide>i,
a.btn:not([href]):not([tabindex]).btn-slide>i {
    float: left;
    left: 17px;
    position: absolute;
    top: 17px;
    transition: all 0.8s ease 0s;
}

button.btn.btn-slide>span,
.btn:not(.close):not(.mfp-close).btn-slide>span,
a.btn:not([href]):not([tabindex]).btn-slide>span {
    left: 40px;
    position: absolute;
    text-align: left;
    top: 13px;
    transition: left 0.5s ease 0s, opacity 0.5s ease 0s;
    white-space: nowrap;
    opacity: 0;
}

button.btn.btn-slide:hover,
.btn:not(.close):not(.mfp-close).btn-slide:hover,
a.btn:not([href]):not([tabindex]).btn-slide:hover {
    width: 140px;
    text-align: left;
}

button.btn.btn-slide:hover>i,
.btn:not(.close):not(.mfp-close).btn-slide:hover>i,
a.btn:not([href]):not([tabindex]).btn-slide:hover>i {
    transform: rotate(360deg);
}

button.btn.btn-slide:hover>span,
.btn:not(.close):not(.mfp-close).btn-slide:hover>span,
a.btn:not([href]):not([tabindex]).btn-slide:hover>span {
    opacity: 1;
}

button.btn.btn-slide.btn-xs,
.btn:not(.close):not(.mfp-close).btn-slide.btn-xs,
a.btn:not([href]):not([tabindex]).btn-slide.btn-xs {
    padding: 14px;
    width: 30px;
    line-height: 26px;
}

button.btn.btn-slide.btn-xs>i,
.btn:not(.close):not(.mfp-close).btn-slide.btn-xs>i,
a.btn:not([href]):not([tabindex]).btn-slide.btn-xs>i {
    left: 9px;
    top: 8px;
}

button.btn.btn-slide.btn-xs>span,
.btn:not(.close):not(.mfp-close).btn-slide.btn-xs>span,
a.btn:not([href]):not([tabindex]).btn-slide.btn-xs>span {
    left: 28px;
    top: 0px;
}

button.btn.btn-slide.btn-xs:hover,
.btn:not(.close):not(.mfp-close).btn-slide.btn-xs:hover,
a.btn:not([href]):not([tabindex]).btn-slide.btn-xs:hover {
    width: 110px;
}

button.btn.btn-slide.btn-sm,
.btn:not(.close):not(.mfp-close).btn-slide.btn-sm,
a.btn:not([href]):not([tabindex]).btn-slide.btn-sm {
    padding: 18px;
    width: 36px;
    line-height: 36px;
}

button.btn.btn-slide.btn-sm>i,
.btn:not(.close):not(.mfp-close).btn-slide.btn-sm>i,
a.btn:not([href]):not([tabindex]).btn-slide.btn-sm>i {
    left: 13px;
    top: 13px;
}

button.btn.btn-slide.btn-sm>span,
.btn:not(.close):not(.mfp-close).btn-slide.btn-sm>span,
a.btn:not([href]):not([tabindex]).btn-slide.btn-sm>span {
    left: 36px;
    top: 0px;
}

button.btn.btn-slide.btn-sm:hover,
.btn:not(.close):not(.mfp-close).btn-slide.btn-sm:hover,
a.btn:not([href]):not([tabindex]).btn-slide.btn-sm:hover {
    width: 110px;
}

button.btn.btn-slide.btn-lg,
.btn:not(.close):not(.mfp-close).btn-slide.btn-lg,
a.btn:not([href]):not([tabindex]).btn-slide.btn-lg {
    padding: 30px;
    line-height: 52px;
}

button.btn.btn-slide.btn-lg>i,
.btn:not(.close):not(.mfp-close).btn-slide.btn-lg>i,
a.btn:not([href]):not([tabindex]).btn-slide.btn-lg>i {
    left: 23px;
    top: 23px;
}

button.btn.btn-slide.btn-lg>span,
.btn:not(.close):not(.mfp-close).btn-slide.btn-lg>span,
a.btn:not([href]):not([tabindex]).btn-slide.btn-lg>span {
    left: 54px;
    top: 3px;
}

button.btn.btn-slide.btn-lg:hover,
.btn:not(.close):not(.mfp-close).btn-slide.btn-lg:hover,
a.btn:not([href]):not([tabindex]).btn-slide.btn-lg:hover {
    width: 200px;
}

button.btn.btn-reveal,
.btn:not(.close):not(.mfp-close).btn-reveal,
a.btn:not([href]):not([tabindex]).btn-reveal {
    padding: 12px 34px;
}

button.btn.btn-reveal span,
.btn:not(.close):not(.mfp-close).btn-reveal span,
a.btn:not([href]):not([tabindex]).btn-reveal span {
    left: 0;
    position: relative;
    transition: opacity 0.2s ease-out 0s, left 0.2s ease-out 0s;
}

button.btn.btn-reveal i,
.btn:not(.close):not(.mfp-close).btn-reveal i,
a.btn:not([href]):not([tabindex]).btn-reveal i {
    line-height: 18px;
    margin-top: -9px;
    opacity: 0;
    position: absolute;
    right: 28px;
    top: 50%;
    transition: all 0.2s ease-out 0s;
    width: 18px;
}

button.btn.btn-reveal:hover span,
.btn:not(.close):not(.mfp-close).btn-reveal:hover span,
a.btn:not([href]):not([tabindex]).btn-reveal:hover span {
    left: -10px;
}

button.btn.btn-reveal:hover i,
.btn:not(.close):not(.mfp-close).btn-reveal:hover i,
a.btn:not([href]):not([tabindex]).btn-reveal:hover i {
    opacity: 1 !important;
    right: 18px;
}

button.btn.btn-reveal.btn-xs,
.btn:not(.close):not(.mfp-close).btn-reveal.btn-xs,
a.btn:not([href]):not([tabindex]).btn-reveal.btn-xs {
    line-height: 0px;
}

button.btn.btn-reveal.btn-sm,
.btn:not(.close):not(.mfp-close).btn-reveal.btn-sm,
a.btn:not([href]):not([tabindex]).btn-reveal.btn-sm {
    line-height: 0px;
}

button.btn.btn-reveal.btn-lg,
.btn:not(.close):not(.mfp-close).btn-reveal.btn-lg,
a.btn:not([href]):not([tabindex]).btn-reveal.btn-lg {
    line-height: 26px;
    padding: 0 52px;
}

button.btn.btn-reveal.btn-reveal-left span,
.btn:not(.close):not(.mfp-close).btn-reveal.btn-reveal-left span,
a.btn:not([href]):not([tabindex]).btn-reveal.btn-reveal-left span {
    right: 0;
    left: auto;
    transition: opacity 0.2s ease-out 0s, right 0.2s ease-out 0s;
}

button.btn.btn-reveal.btn-reveal-left i,
.btn:not(.close):not(.mfp-close).btn-reveal.btn-reveal-left i,
a.btn:not([href]):not([tabindex]).btn-reveal.btn-reveal-left i {
    left: 28px;
    right: auto;
}

button.btn.btn-reveal.btn-reveal-left:hover span,
.btn:not(.close):not(.mfp-close).btn-reveal.btn-reveal-left:hover span,
a.btn:not([href]):not([tabindex]).btn-reveal.btn-reveal-left:hover span {
    right: -10px;
    left: auto;
}

button.btn.btn-reveal.btn-reveal-left:hover i,
.btn:not(.close):not(.mfp-close).btn-reveal.btn-reveal-left:hover i,
a.btn:not([href]):not([tabindex]).btn-reveal.btn-reveal-left:hover i {
    left: 18px;
    right: auto;
}

button.btn.btn-shadow,
.btn:not(.close):not(.mfp-close).btn-shadow,
a.btn:not([href]):not([tabindex]).btn-shadow {
    -webkit-box-shadow: 0 4px 16px #efefef;
    -moz-box-shadow: 0 4px 16px #efefef;
    box-shadow: 0 4px 16px #efefef;
}

button.btn.btn-shadow:hover,
button.btn.btn-shadow:focus,
button.btn.btn-shadow:not(:disabled):not(.disabled):active,
button.btn.btn-shadow:not(:disabled):not(.disabled).active,
.btn:not(.close):not(.mfp-close).btn-shadow:hover,
.btn:not(.close):not(.mfp-close).btn-shadow:focus,
.btn:not(.close):not(.mfp-close).btn-shadow:not(:disabled):not(.disabled):active,
.btn:not(.close):not(.mfp-close).btn-shadow:not(:disabled):not(.disabled).active,
a.btn:not([href]):not([tabindex]).btn-shadow:hover,
a.btn:not([href]):not([tabindex]).btn-shadow:focus,
a.btn:not([href]):not([tabindex]).btn-shadow:not(:disabled):not(.disabled):active,
a.btn:not([href]):not([tabindex]).btn-shadow:not(:disabled):not(.disabled).active {
    -webkit-box-shadow: 4px 8px 16px #dedede;
    -moz-box-shadow: 4px 8px 16px #dedede;
    box-shadow: 4px 8px 16px #dedede;
}

button.btn.btn-icon-holder,
.btn:not(.close):not(.mfp-close).btn-icon-holder,
a.btn:not([href]):not([tabindex]).btn-icon-holder {
    padding-right: 16px;
}

button.btn.btn-icon-holder:before,
.btn:not(.close):not(.mfp-close).btn-icon-holder:before,
a.btn:not([href]):not([tabindex]).btn-icon-holder:before {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    content: "";
    height: 100%;
    position: absolute;
    right: 42px;
    top: 0;
}

button.btn.btn-icon-holder.btn-outline:before,
.btn:not(.close):not(.mfp-close).btn-icon-holder.btn-outline:before,
a.btn:not([href]):not([tabindex]).btn-icon-holder.btn-outline:before {
    border-left: 2px solid #1290c6;
}

button.btn.btn-icon-holder.btn-outline.btn-dark:before,
.btn:not(.close):not(.mfp-close).btn-icon-holder.btn-outline.btn-dark:before,
a.btn:not([href]):not([tabindex]).btn-icon-holder.btn-outline.btn-dark:before {
    border-left: 2px solid #111;
}

button.btn.btn-icon-holder.btn-light:before,
.btn:not(.close):not(.mfp-close).btn-icon-holder.btn-light:before,
a.btn:not([href]):not([tabindex]).btn-icon-holder.btn-light:before {
    border-left: 1px solid #f3f3f3;
}

button.btn.btn-icon-holder.btn-light.btn-light-hover:before,
button.btn.btn-icon-holder.btn-light.btn-light-hover:hover,
.btn:not(.close):not(.mfp-close).btn-icon-holder.btn-light.btn-light-hover:before,
.btn:not(.close):not(.mfp-close).btn-icon-holder.btn-light.btn-light-hover:hover,
a.btn:not([href]):not([tabindex]).btn-icon-holder.btn-light.btn-light-hover:before,
a.btn:not([href]):not([tabindex]).btn-icon-holder.btn-light.btn-light-hover:hover {
    border-left: 1px solid #f3f3f3;
}

button.btn.btn-icon-holder i,
.btn:not(.close):not(.mfp-close).btn-icon-holder i,
a.btn:not([href]):not([tabindex]).btn-icon-holder i {
    margin-left: 30px;
    margin-right: -2px;
}

button.btn.btn-icon-holder.btn-xs,
.btn:not(.close):not(.mfp-close).btn-icon-holder.btn-xs,
a.btn:not([href]):not([tabindex]).btn-icon-holder.btn-xs {
    padding-right: 10px;
}

button.btn.btn-icon-holder.btn-xs:before,
.btn:not(.close):not(.mfp-close).btn-icon-holder.btn-xs:before,
a.btn:not([href]):not([tabindex]).btn-icon-holder.btn-xs:before {
    right: 30px;
}

button.btn.btn-icon-holder.btn-xs i,
.btn:not(.close):not(.mfp-close).btn-icon-holder.btn-xs i,
a.btn:not([href]):not([tabindex]).btn-icon-holder.btn-xs i {
    margin-left: 20px;
    margin-right: 2px;
}

button.btn.btn-icon-holder.btn-sm,
.btn:not(.close):not(.mfp-close).btn-icon-holder.btn-sm,
a.btn:not([href]):not([tabindex]).btn-icon-holder.btn-sm {
    padding-right: 16px;
}

button.btn.btn-icon-holder.btn-sm:before,
.btn:not(.close):not(.mfp-close).btn-icon-holder.btn-sm:before,
a.btn:not([href]):not([tabindex]).btn-icon-holder.btn-sm:before {
    right: 36px;
}

button.btn.btn-icon-holder.btn-sm i,
.btn:not(.close):not(.mfp-close).btn-icon-holder.btn-sm i,
a.btn:not([href]):not([tabindex]).btn-icon-holder.btn-sm i {
    margin-left: 24px;
    margin-right: 0;
}

button.btn.btn-icon-holder.btn-lg,
.btn:not(.close):not(.mfp-close).btn-icon-holder.btn-lg,
a.btn:not([href]):not([tabindex]).btn-icon-holder.btn-lg {
    padding-right: 28px;
}

button.btn.btn-icon-holder.btn-lg:before,
.btn:not(.close):not(.mfp-close).btn-icon-holder.btn-lg:before,
a.btn:not([href]):not([tabindex]).btn-icon-holder.btn-lg:before {
    right: 50px;
}

button.btn.btn-icon-holder.btn-lg i,
.btn:not(.close):not(.mfp-close).btn-icon-holder.btn-lg i,
a.btn:not([href]):not([tabindex]).btn-icon-holder.btn-lg i {
    margin-left: 34px;
    margin-right: -8px;
}

button.btn.btn-creative,
.btn:not(.close):not(.mfp-close).btn-creative,
a.btn:not([href]):not([tabindex]).btn-creative {
    padding: 24px 40px;
    font-size: 15px;
    font-weight: 600;
}

button.btn.btn-creative:before,
.btn:not(.close):not(.mfp-close).btn-creative:before,
a.btn:not([href]):not([tabindex]).btn-creative:before {
    right: 58px;
}

button.btn.btn-creative i,
.btn:not(.close):not(.mfp-close).btn-creative i,
a.btn:not([href]):not([tabindex]).btn-creative i {
    margin-left: 54px;
    margin-right: -16px;
}

button.btn.btn-facebook,
.btn:not(.close):not(.mfp-close).btn-facebook,
a.btn:not([href]):not([tabindex]).btn-facebook {
    background-color: #3b5998 !important;
    color: #ffffff !important;
    border-width: 0;
}

button.btn.btn-twitter,
.btn:not(.close):not(.mfp-close).btn-twitter,
a.btn:not([href]):not([tabindex]).btn-twitter {
    background-color: #00aced !important;
    color: #ffffff !important;
    border-width: 0;
}

button.btn.btn-linkedin,
.btn:not(.close):not(.mfp-close).btn-linkedin,
a.btn:not([href]):not([tabindex]).btn-linkedin {
    background-color: #007bb6 !important;
    color: #ffffff !important;
    border-width: 0;
}

button.btn.btn-dribbble,
.btn:not(.close):not(.mfp-close).btn-dribbble,
a.btn:not([href]):not([tabindex]).btn-dribbble {
    background-color: #ea4c89 !important;
    color: #ffffff !important;
    border-width: 0;
}

button.btn.btn-googleplus,
.btn:not(.close):not(.mfp-close).btn-googleplus,
a.btn:not([href]):not([tabindex]).btn-googleplus {
    background-color: #dd4b39 !important;
    color: #ffffff !important;
    border-width: 0;
}

button.btn.btn-instagram,
.btn:not(.close):not(.mfp-close).btn-instagram,
a.btn:not([href]):not([tabindex]).btn-instagram {
    background-color: #517fa4 !important;
    color: #ffffff !important;
    border-width: 0;
}

button.btn.btn-pinterest,
.btn:not(.close):not(.mfp-close).btn-pinterest,
a.btn:not([href]):not([tabindex]).btn-pinterest {
    background-color: #cb2027 !important;
    color: #ffffff !important;
    border-width: 0;
}

button.btn.btn-dropbox,
.btn:not(.close):not(.mfp-close).btn-dropbox,
a.btn:not([href]):not([tabindex]).btn-dropbox {
    background-color: #007ee5 !important;
    color: #ffffff !important;
    border-width: 0;
}

button.btn.btn-flickr,
.btn:not(.close):not(.mfp-close).btn-flickr,
a.btn:not([href]):not([tabindex]).btn-flickr {
    background-color: #ff0084 !important;
    color: #ffffff !important;
    border-width: 0;
}

button.btn.btn-tumblr,
.btn:not(.close):not(.mfp-close).btn-tumblr,
a.btn:not([href]):not([tabindex]).btn-tumblr {
    background-color: #32506d !important;
    color: #ffffff !important;
    border-width: 0;
}

button.btn.btn-skype,
.btn:not(.close):not(.mfp-close).btn-skype,
a.btn:not([href]):not([tabindex]).btn-skype {
    background-color: #00aff0 !important;
    color: #ffffff !important;
    border-width: 0;
}

button.btn.btn-youtube,
.btn:not(.close):not(.mfp-close).btn-youtube,
a.btn:not([href]):not([tabindex]).btn-youtube {
    background-color: #bb0000 !important;
    color: #ffffff !important;
    border-width: 0;
}

button.btn.btn-github,
.btn:not(.close):not(.mfp-close).btn-github,
a.btn:not([href]):not([tabindex]).btn-github {
    background-color: #171515 !important;
    color: #ffffff !important;
    border-width: 0;
}

button.btn+.btn,
.btn:not(.close):not(.mfp-close)+.btn,
a.btn:not([href]):not([tabindex])+.btn {
    margin-left: 4px;
}

button.btn .badge,
.btn:not(.close):not(.mfp-close) .badge,
a.btn:not([href]):not([tabindex]) .badge {
    position: absolute;
    right: -4px;
    top: -4px;
}

button.btn.disabled,
.btn:not(.close):not(.mfp-close).disabled,
a.btn:not([href]):not([tabindex]).disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: .6;
}

.disabled .btn {
    opacity: .3;
}

@media (max-width: 1024px) {
    button.btn:not(.btn-creative):not(.btn-slide),
    .btn:not(.close):not(.mfp-close):not(.btn-creative):not(.btn-slide),
    a.btn:not([href]):not([tabindex]):not(.btn-creative):not(.btn-slide) {
        font-size: 12px;
        height: 32px;
        line-height: 30px;
        padding: 0 14px;
    }
    button.btn:not(.btn-creative):not(.btn-slide).btn-reveal:hover span,
    .btn:not(.close):not(.mfp-close):not(.btn-creative):not(.btn-slide).btn-reveal:hover span,
    a.btn:not([href]):not([tabindex]):not(.btn-creative):not(.btn-slide).btn-reveal:hover span {
        left: auto;
        right: auto;
    }
    button.btn:not(.btn-creative):not(.btn-slide).btn-reveal:hover i,
    .btn:not(.close):not(.mfp-close):not(.btn-creative):not(.btn-slide).btn-reveal:hover i,
    a.btn:not([href]):not([tabindex]):not(.btn-creative):not(.btn-slide).btn-reveal:hover i {
        display: none;
    }
    button.btn:not(.btn-creative):not(.btn-slide).btn-lg,
    .btn:not(.close):not(.mfp-close):not(.btn-creative):not(.btn-slide).btn-lg,
    a.btn:not([href]):not([tabindex]):not(.btn-creative):not(.btn-slide).btn-lg {
        font-size: 13px;
        height: 38px;
        line-height: 36px;
        padding: 0 24px;
    }
    button.btn:not(.btn-creative):not(.btn-slide).btn-sm,
    .btn:not(.close):not(.mfp-close):not(.btn-creative):not(.btn-slide).btn-sm,
    a.btn:not([href]):not([tabindex]):not(.btn-creative):not(.btn-slide).btn-sm {
        font-size: 11px;
        height: 28px;
        line-height: 28px;
        padding: 0 12px;
    }
    button.btn:not(.btn-creative):not(.btn-slide).btn-xs,
    .btn:not(.close):not(.mfp-close):not(.btn-creative):not(.btn-slide).btn-xs,
    a.btn:not([href]):not([tabindex]):not(.btn-creative):not(.btn-slide).btn-xs {
        font-size: 10px;
        height: 26px;
        line-height: 26px;
        padding: 0 10px;
    }
}

.dark:not(.submenu-light) a.btn:not([href]):not([tabindex]).btn-shadow,
.dark:not(.submenu-light) .btn.btn-shadow {
    box-shadow: 0 4px 16px #292929;
}

.dark:not(.submenu-light) a.btn:not([href]):not([tabindex]).btn-shadow:hover,
.dark:not(.submenu-light) a.btn:not([href]):not([tabindex]).btn-shadow:focus,
.dark:not(.submenu-light) a.btn:not([href]):not([tabindex]).btn-shadow:not(:disabled):not(.disabled):active,
.dark:not(.submenu-light) a.btn:not([href]):not([tabindex]).btn-shadow:not(:disabled):not(.disabled).active,
.dark:not(.submenu-light) .btn.btn-shadow:hover,
.dark:not(.submenu-light) .btn.btn-shadow:focus,
.dark:not(.submenu-light) .btn.btn-shadow:not(:disabled):not(.disabled):active,
.dark:not(.submenu-light) .btn.btn-shadow:not(:disabled):not(.disabled).active {
    box-shadow: 4px 8px 16px #1d1d1d;
}

.card {
    border: 1px solid #e6e8eb;
    border-radius: 5px;
    background-color: #ffffff;
    margin-bottom: 20px;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}

.card .card-header {
    background-color: #ffffff;
    border-bottom: 1px solid #e6e8eb;
    font-weight: 600;
    padding: 1.8rem 2rem;
}

.card .card-header p:last-child {
    margin-bottom: 0;
}

.card .card-header .card-title {
    font-size: 1.35714286em;
    line-height: 1.68421053em;
    font-weight: 500;
}

.card .card-header .card-subtitle {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    color: #484848;
}

.card .card-header-title {
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 0px;
    font-weight: 600;
}

.card .card-body {
    padding: 1.8rem 2rem;
}

.card .card-body-title {
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
}

.card .card-footer {
    background-color: #ffffff;
    border-top: 1px solid #e6e8eb;
}


/* ----------------------------------------------------------------------
	Headings
-------------------------------------------------------------------------*/

.heading {
    margin-bottom: 100px;
    text-align: center;
}

.heading>h1,
.heading>h2 {
    font-size: 40px;
    text-transform: capitalize;
    line-height: 46px;
    font-weight: 300;
}

.heading>h1,
.heading>h2,
.heading>h4,
.heading>p {
    margin-bottom: default-bottom-margin;
}

.heading-text {
    margin-bottom: 30px;
}

.heading-text h1,
.heading-text h2,
.heading-text h3,
.heading-text h4,
.heading-text h5,
.heading-text h6 {
    position: relative;
    margin-bottom: 30px;
}

.heading-text h1 {
    font-size: 70px;
    font-weight: 600;
}

.heading-text h2 {
    font-size: 60px;
    margin-bottom: 30px;
    letter-spacing: -.7px;
    line-height: 66px;
}

.heading-text h3 {
    font-size: 60px;
    margin-bottom: 30px;
    letter-spacing: -.7px;
    line-height: 66px;
}

.heading-text h4 {
    font-size: 34px;
    margin-bottom: 30px;
    letter-spacing: -.7px;
    line-height: 40px;
}

.heading-text p {
    font-size: 18px;
    margin-bottom: 30px;
}

.heading-text.heading-gradient h2>* {
    -webkit-text-fill-color: transparent !important;
    -webkit-background-clip: text !important;
    background: radial-gradient(circle at left top, #4b72ff 9%, #1290c6 48%, #6442ff 91%);
}

.heading-text.heading-section h2 {
    position: relative;
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 60px;
}

.heading-text.heading-section h2:before {
    content: "";
    position: absolute;
    height: 2px;
    width: 100px;
    background-color: #1290c6;
    bottom: -30px;
    left: 0;
    right: 0;
}

.heading-text.heading-section.text-center h1:before,
.heading-text.heading-section.text-center h2:before,
.heading-text.heading-section.text-center h3:before,
.heading-text.heading-section.text-center h4:before,
.heading-text.heading-section.text-center h5:before,
.heading-text.heading-section.text-center h6:before {
    margin: 0 auto;
}

.heading-text.heading-section.text-right h1:before,
.heading-text.heading-section.text-right h2:before,
.heading-text.heading-section.text-right h3:before,
.heading-text.heading-section.text-right h4:before,
.heading-text.heading-section.text-right h5:before,
.heading-text.heading-section.text-right h6:before {
    right: 0;
    left: auto;
}

.heading-text.heading-section p {
    font-size: 18px;
    font-weight: 300;
}

.heading-text.heading-section p span {
    font-weight: 600;
}

.heading-text.heading-plain h2 {
    font-size: 56px;
    line-height: 56px;
    font-family: initial;
}

.heading-text.heading-plain p {
    font-size: 20px;
    font-weight: 300;
}

.heading-text.heading-plain p span {
    font-weight: 600;
}

.heading-text.heading-light h2 {
    font-size: 59px;
    font-weight: 100;
    letter-spacing: -1px;
    line-height: 70px;
}

.heading-text.heading-light p {
    font-size: 17px;
    font-weight: 300;
    margin-bottom: 20px;
}

.heading-text.heading-light p span {
    font-weight: 600;
}

.heading-text.heading-vintage h2 {
    border: 2px solid #ffffff;
    display: inline-block;
    padding: 8px 21px;
    margin-bottom: 30px;
}

.heading-text.heading-vintage p {
    font-size: 17px;
    font-weight: 300;
    margin-bottom: 20px;
}

.heading-text.heading-vintage p span {
    font-weight: 600;
}

.heading-text.heading-line h1:before,
.heading-text.heading-line h2:before,
.heading-text.heading-line h3:before,
.heading-text.heading-line h4:before,
.heading-text.heading-line h5:before,
.heading-text.heading-line h6:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 2px;
    left: 0;
    bottom: -8px;
    right: 0;
    background-color: #ddd;
}

.heading-text.heading-line.text-center h1:before,
.heading-text.heading-line.text-center h2:before,
.heading-text.heading-line.text-center h3:before,
.heading-text.heading-line.text-center h4:before,
.heading-text.heading-line.text-center h5:before,
.heading-text.heading-line.text-center h6:before {
    margin: 0 auto;
}

.heading-text.heading-line.text-right h1:before,
.heading-text.heading-line.text-right h2:before,
.heading-text.heading-line.text-right h3:before,
.heading-text.heading-line.text-right h4:before,
.heading-text.heading-line.text-right h5:before,
.heading-text.heading-line.text-right h6:before {
    right: 0;
    left: auto;
}

.heading-text.heading-small h2,
.heading-text.heading-small h3 {
    margin-bottom: 10px;
}

.heading-text.heading-small p {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 20px;
}

@media (max-width: 1024px) {
    .heading-text {
        margin-bottom: 16px;
    }
    .heading-text h1,
    .heading-text h2,
    .heading-text h3,
    .heading-text h4,
    .heading-text h5,
    .heading-text h6 {
        margin-bottom: 16px;
    }
    .heading-text h1 {
        font-size: 50px;
    }
    .heading-text h2 {
        font-size: 40px;
        line-height: 44px;
    }
    .heading-text h3 {
        font-size: 30px;
        margin-bottom: 10px;
        line-height: 36px;
    }
    .heading-text h4 {
        font-size: 24px;
        margin-bottom: 10px;
        line-height: 26px;
    }
    .heading-text p {
        font-size: 16px;
        margin-bottom: 16px;
    }
    .heading-text.heading-plain h2,
    .heading-text.heading-light h2,
    .heading-text.heading-section h2 {
        font-size: 40px;
        line-height: 44px;
        margin-bottom: 16px;
    }
    .heading-text.heading-plain h2:before,
    .heading-text.heading-light h2:before,
    .heading-text.heading-section h2:before {
        display: none;
    }
    .heading-text.heading-plain p,
    .heading-text.heading-light p,
    .heading-text.heading-section p {
        font-size: 16px;
        margin-bottom: 16px;
    }
}


/*Creative fade heading*/

.heading-creative {
    font-size: 62px;
    font-weight: 100;
    position: relative;
    text-align: left;
    width: 100%;
}

.heading-creative strong {
    color: #1290c6;
}


/*Fixes*/

#header:not([data-transparent="true"]).dark .heading-creative {
    color: #eeeeee;
}


/*--------------------------------------------------------
     Tabs
---------------------------------------------------------*/

ul.nav li.dropdown:hover>ul.dropdown-menu {
    display: block;
}

.tabs .nav-tabs {
    border-bottom: 2px solid #e6e8eb;
    margin-bottom: 30px;
}

.tabs .nav-tabs .nav-item {
    margin-bottom: -2px;
}

.tabs .nav-tabs .nav-link {
    color: #484848;
    padding: 14px 24px;
    color: rgba(72, 72, 72, 0.8);
    transition: 0.3s ease;
    font-weight: 500;
    border: 0;
    /*border: $nav-tabs-border-width solid transparent;*/
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 2px solid #e6e8eb;
}

.tabs .nav-tabs .nav-link i {
    margin-right: 6px;
}

.tabs .nav-tabs .nav-link.disabled {
    color: rgba(72, 72, 72, 0.5);
}

.tabs .nav-tabs .nav-link.active {
    color: #1290c6;
    background-color: transparent;
    border-bottom: 2px solid #1290c6;
}

.tabs .nav-tabs .dropdown-menu {
    border-radius: 5px;
    border: 0;
    box-shadow: 0 6px 38px rgba(0, 0, 0, 0.05);
}

.tabs.tabs-clean .nav-tabs {
    border-bottom: 0;
}

.tabs.tabs-clean .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.tabs.tabs-clean .nav-tabs .nav-link.active {
    border-color: #e6e8eb;
}

.tabs.tabs-vertical .nav-tabs {
    border: 0;
    margin-bottom: 0;
}

.tabs.tabs-vertical .nav-tabs .nav-link {
    border-radius: 0;
    border-bottom: 0;
    border-right: 2px solid #e6e8eb;
}

.tabs.tabs-vertical .nav-tabs .nav-link.active {
    color: #1290c6;
    background-color: transparent;
    border-right: 2px solid #1290c6;
}

.tabs.tabs-folder .nav-tabs {
    margin-bottom: 0;
    border-bottom: 0;
}

.tabs.tabs-folder .nav-tabs .nav-item {
    margin-bottom: 0;
}

.tabs.tabs-folder .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.tabs.tabs-folder .nav-tabs .nav-link.active {
    border-color: #e6e8eb;
    border-bottom-color: #ffffff;
}

.tabs.tabs-folder .tab-content {
    margin-top: -1px;
    border: 1px solid #e6e8eb;
    bottom: 10px;
    padding: 30px;
    border-radius: 0 6px 6px 6px;
}


/* ----------------------------------------------------------------------
	Progress Bar
-------------------------------------------------------------------------*/

.social-icons {
    line-height: 0;
}

.social-icons ul {
    padding: 0;
    margin: 0;
}

.text-center.social-icons ul {
    display: inline-block;
}

.social-icons li {
    float: left;
    list-style: none;
}

.social-icons li a {
    float: left;
    height: 32px;
    width: 32px;
    line-height: 32px;
    font-size: 16px;
    text-align: center;
    margin: 0 4px 4px 0;
    border-radius: 4px;
    border: 0;
    background: transparent;
    color: #333;
    overflow: hidden;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.dark .social-icons li a {
    color: #ffffff;
}

.social-icons.social-icons-colored a,
.social-icons.social-icons-colored-hover a:hover {
    color: #ffffff !important;
    border: 0;
}

.social-icons.social-icons-colored .social-rss a,
.social-icons.social-icons-colored-hover .social-rss a:hover,
.social-icons.social-icons-colored .social-snapchat a,
.social-icons.social-icons-colored-hover .social-snapchat a:hover {
    background-color: #faa33d;
}

.social-icons.social-icons-colored .social-facebook a,
.social-icons.social-icons-colored-hover .social-facebook a:hover {
    background-color: #5d82d1;
}

.social-icons.social-icons-colored .social-twitter a,
.social-icons.social-icons-colored-hover .social-twitter a:hover {
    background-color: #40bff5;
}

.social-icons.social-icons-colored .social-globe a,
.social-icons.social-icons-colored-hover .social-globe a:hover {
    background-color: #40bff5;
}

.social-icons.social-icons-colored .social-vimeo a,
.social-icons.social-icons-colored-hover .social-vimeo a:hover {
    background-color: #35c6ea;
}

.social-icons.social-icons-colored .social-myspace a,
.social-icons.social-icons-colored-hover .social-myspace a:hover {
    background-color: #008dde;
}

.social-icons.social-icons-colored .social-youtube a,
.social-icons.social-icons-colored-hover .social-youtube a:hover {
    background-color: #ef4e41;
}

.social-icons.social-icons-colored .social-instagram a,
.social-icons.social-icons-colored-hover .social-instagram a:hover {
    background-color: #e53d00;
}

.social-icons.social-icons-colored .social-gplus a,
.social-icons.social-icons-colored-hover .social-gplus a:hover {
    background-color: #d68400;
}

.social-icons.social-icons-colored .social-stumbleupon a,
.social-icons.social-icons-colored-hover .social-stumbleupon a:hover {
    background-color: #ff5c30;
}

.social-icons.social-icons-colored .social-lastfm a,
.social-icons.social-icons-colored-hover .social-lastfm a:hover {
    background-color: #f34320;
}

.social-icons.social-icons-colored .social-pinterest a,
.social-icons.social-icons-colored-hover .social-pinterest a:hover {
    background-color: #e13138;
}

.social-icons.social-icons-colored .social-google a,
.social-icons.social-icons-colored-hover .social-google a:hover {
    background-color: #eb5e4c;
}

.social-icons.social-icons-colored .social-evernote a,
.social-icons.social-icons-colored-hover .social-evernote a:hover {
    background-color: #9acf4f;
}

.social-icons.social-icons-colored .social-dribbble a,
.social-icons.social-icons-colored-hover .social-dribbble a:hover {
    background-color: #f7659c;
}

.social-icons.social-icons-colored .social-skype a,
.social-icons.social-icons-colored-hover .social-skype a:hover {
    background-color: #13c1f3;
}

.social-icons.social-icons-colored .social-forrst a,
.social-icons.social-icons-colored-hover .social-forrst a:hover {
    background-color: #45ad76;
}

.social-icons.social-icons-colored .social-linkedin a,
.social-icons.social-icons-colored-hover .social-linkedin a:hover {
    background-color: #238cc8;
}

.social-icons.social-icons-colored .social-wordpress a,
.social-icons.social-icons-colored-hover .social-wordpress a:hover {
    background-color: #2592c3;
}

.social-icons.social-icons-colored .social-grooveshark a,
.social-icons.social-icons-colored-hover .social-grooveshark a:hover {
    background-color: #ffb21d;
}

.social-icons.social-icons-colored .social-delicious a,
.social-icons.social-icons-colored-hover .social-delicious a:hover {
    background-color: #377bda;
}

.social-icons.social-icons-colored .social-behance a,
.social-icons.social-icons-colored-hover .social-behance a:hover {
    background-color: #1879fd;
}

.social-icons.social-icons-colored .social-dropbox a,
.social-icons.social-icons-colored-hover .social-dropbox a:hover {
    background-color: #17a3eb;
}

.social-icons.social-icons-colored .social-soundcloud a,
.social-icons.social-icons-colored-hover .social-soundcloud a:hover {
    background-color: #ff7e30;
}

.social-icons.social-icons-colored .social-deviantart a,
.social-icons.social-icons-colored-hover .social-deviantart a:hover {
    background-color: #6a8a7b;
}

.social-icons.social-icons-colored .social-yahoo a,
.social-icons.social-icons-colored-hover .social-yahoo a:hover {
    background-color: #ab47ac;
}

.social-icons.social-icons-colored .social-flickr a,
.social-icons.social-icons-colored-hover .social-flickr a:hover {
    background-color: #ff48a3;
}

.social-icons.social-icons-colored .social-digg a,
.social-icons.social-icons-colored-hover .social-digg a:hover {
    background-color: #75788d;
}

.social-icons.social-icons-colored .social-blogger a,
.social-icons.social-icons-colored-hover .social-blogger a:hover {
    background-color: #ff9233;
}

.social-icons.social-icons-colored .social-tumblr a,
.social-icons.social-icons-colored-hover .social-tumblr a:hover {
    background-color: #426d9b;
}

.social-icons.social-icons-colored .social-quora a,
.social-icons.social-icons-colored-hover .social-quora a:hover {
    background-color: #ea3d23;
}

.social-icons.social-icons-colored .social-github a,
.social-icons.social-icons-colored-hover .social-github a:hover {
    background-color: #3f91cb;
}

.social-icons.social-icons-colored .social-amazon a,
.social-icons.social-icons-colored-hover .social-amazon a:hover {
    background-color: #ff8e2e;
}

.social-icons.social-icons-colored .social-xing a,
.social-icons.social-icons-colored-hover .social-xing a:hover {
    background-color: #1a8e8c;
}

.social-icons.social-icons-colored .social-wikipedia a,
.social-icons.social-icons-colored-hover .social-wikipedia a:hover {
    background-color: #b3b5b8;
}

.social-icons.social-icons-border li a {
    border: 1px solid #d7d7d7;
    background: transparent;
    color: #333;
}

.dark .social-icons.social-icons-border li a {
    border: 1px solid #333 !important;
}

.dark .social-icons li a .social-icons.social-icons-dark li a {
    background: #888;
    color: #fff;
}

.social-icons.social-icons-light li a {
    background: #fff;
    color: #333;
    border: 1px solid #eee;
}

.social-icons.social-icons-rounded li a {
    border-radius: 50%;
}

.social-icons.social-icons-medium li a {
    height: 42px;
    width: 42px;
    line-height: 42px;
    font-size: 18px;
}

.social-icons.social-icons-large li a {
    height: 48px;
    width: 48px;
    line-height: 48px;
    font-size: 18px;
}

.social-icons:not(.social-icons-colored):not(.social-icons-colored-hover) li a:hover {
    background-color: #eee;
}

.dark .social-icons:not(.social-icons-colored):not(.social-icons-colored-hover) li a:hover {
    background-color: #1f1f1f;
}

.social-icons li:hover i {
    -webkit-animation: toTopFromBottom 0.3s forwards;
    -moz-animation: toTopFromBottom 0.3s forwards;
    animation: toTopFromBottom 0.3s forwards;
}

@-webkit-keyframes toTopFromBottom {
    49% {
        -webkit-transform: translateY(-100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateY(100%);
    }
    51% {
        opacity: 1;
    }
}

@-moz-keyframes toTopFromBottom {
    49% {
        -moz-transform: translateY(-100%);
    }
    50% {
        opacity: 0;
        -moz-transform: translateY(100%);
    }
    51% {
        opacity: 1;
    }
}

@keyframes toTopFromBottom {
    49% {
        transform: translateY(-100%);
    }
    50% {
        opacity: 0;
        transform: translateY(100%);
    }
    51% {
        opacity: 1;
    }
}

@font-face {
    font-family: 'inspiro-icons';
    src: url("../webfonts/inspiro-icons.ttf?ijzgpf") format("truetype"), url("../webfonts/inspiro-icons.woff?ijzgpf") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

i:not(.fa):not(.fab):not(.far):not(.fas) {
    font-family: inspiro-icons !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-activity:before {
    content: "\e900";
}

.icon-airplay:before {
    content: "\e901";
}

.icon-alert-circle:before {
    content: "\e902";
}

.icon-alert-octagon:before {
    content: "\e903";
}

.icon-alert-triangle:before {
    content: "\e904";
}

.icon-align-center:before {
    content: "\e905";
}

.icon-align-justify:before {
    content: "\e906";
}

.icon-align-left:before {
    content: "\e907";
}

.icon-align-right:before {
    content: "\e908";
}

.icon-anchor:before {
    content: "\e909";
}

.icon-aperture:before {
    content: "\e90a";
}

.icon-archive:before {
    content: "\e90b";
}

.icon-arrow-down-circle:before {
    content: "\e90c";
}

.icon-arrow-down-left:before {
    content: "\e90d";
}

.icon-arrow-down-right:before {
    content: "\e90e";
}

.icon-arrow-down:before {
    content: "\e90f";
}

.icon-arrow-left-circle:before {
    content: "\e910";
}

.icon-arrow-left:before {
    content: "\e911";
}

.icon-arrow-right-circle:before {
    content: "\e912";
}

.icon-arrow-right:before {
    content: "\e913";
}

.icon-arrow-up-circle:before {
    content: "\e914";
}

.icon-arrow-up-left:before {
    content: "\e915";
}

.icon-arrow-up-right:before {
    content: "\e916";
}

.icon-arrow-up:before {
    content: "\e917";
}

.icon-at-sign:before {
    content: "\e918";
}

.icon-award:before {
    content: "\e919";
}

.icon-bar-chart-2:before {
    content: "\e91a";
}

.icon-bar-chart:before {
    content: "\e91b";
}

.icon-battery-charging:before {
    content: "\e91c";
}

.icon-battery:before {
    content: "\e91d";
}

.icon-bell-off:before {
    content: "\e91e";
}

.icon-bell:before {
    content: "\e91f";
}

.icon-bluetooth:before {
    content: "\e920";
}

.icon-bold:before {
    content: "\e921";
}

.icon-book-open:before {
    content: "\e922";
}

.icon-book:before {
    content: "\e923";
}

.icon-bookmark:before {
    content: "\e924";
}

.icon-box:before {
    content: "\e925";
}

.icon-briefcase:before {
    content: "\e926";
}

.icon-calendar:before {
    content: "\e927";
}

.icon-camera-off:before {
    content: "\e928";
}

.icon-camera:before {
    content: "\e929";
}

.icon-cast:before {
    content: "\e92a";
}

.icon-check-circle:before {
    content: "\e92b";
}

.icon-check-square:before {
    content: "\e92c";
}

.icon-check:before {
    content: "\e92d";
}

.icon-chevron-down:before {
    content: "\e92e";
}

.icon-chevron-left:before {
    content: "\e92f";
}

.icon-chevron-right:before {
    content: "\e930";
}

.icon-chevron-up:before {
    content: "\e931";
}

.icon-chevrons-down:before {
    content: "\e932";
}

.icon-chevrons-left:before {
    content: "\e933";
}

.icon-chevrons-right:before {
    content: "\e934";
}

.icon-chevrons-up:before {
    content: "\e935";
}

.icon-chrome:before {
    content: "\e936";
}

.icon-circle:before {
    content: "\e937";
}

.icon-clipboard:before {
    content: "\e938";
}

.icon-clock:before {
    content: "\e939";
}

.icon-cloud-drizzle:before {
    content: "\e93a";
}

.icon-cloud-lightning:before {
    content: "\e93b";
}

.icon-cloud-off:before {
    content: "\e93c";
}

.icon-cloud-rain:before {
    content: "\e93d";
}

.icon-cloud-snow:before {
    content: "\e93e";
}

.icon-cloud:before {
    content: "\e93f";
}

.icon-code:before {
    content: "\e940";
}

.icon-codepen:before {
    content: "\e941";
}

.icon-codesandbox:before {
    content: "\e942";
}

.icon-coffee:before {
    content: "\e943";
}

.icon-columns:before {
    content: "\e944";
}

.icon-command:before {
    content: "\e945";
}

.icon-compass:before {
    content: "\e946";
}

.icon-copy:before {
    content: "\e947";
}

.icon-corner-down-left:before {
    content: "\e948";
}

.icon-corner-down-right:before {
    content: "\e949";
}

.icon-corner-left-down:before {
    content: "\e94a";
}

.icon-corner-left-up:before {
    content: "\e94b";
}

.icon-corner-right-down:before {
    content: "\e94c";
}

.icon-corner-right-up:before {
    content: "\e94d";
}

.icon-corner-up-left:before {
    content: "\e94e";
}

.icon-corner-up-right:before {
    content: "\e94f";
}

.icon-cpu:before {
    content: "\e950";
}

.icon-credit-card:before {
    content: "\e951";
}

.icon-crop:before {
    content: "\e952";
}

.icon-crosshair:before {
    content: "\e953";
}

.icon-database:before {
    content: "\e954";
}

.icon-delete:before {
    content: "\e955";
}

.icon-disc:before {
    content: "\e956";
}

.icon-dollar-sign:before {
    content: "\e957";
}

.icon-download-cloud:before {
    content: "\e92e";
}

.icon-download:before {
    content: "\e92f";
}

.icon-droplet:before {
    content: "\e95a";
}

.icon-edit-2:before {
    content: "\e95b";
}

.icon-edit-3:before {
    content: "\e95c";
}

.icon-edit:before {
    content: "\e95d";
}

.icon-external-link:before {
    content: "\e95e";
}

.icon-eye-off:before {
    content: "\e95f";
}

.icon-eye:before {
    content: "\e960";
}

.icon-facebook:before {
    content: "\e961";
}

.icon-fast-forward:before {
    content: "\e962";
}

.icon-feather:before {
    content: "\e963";
}

.icon-figma:before {
    content: "\e964";
}

.icon-file-minus:before {
    content: "\e965";
}

.icon-file-plus:before {
    content: "\e966";
}

.icon-file-text:before {
    content: "\e967";
}

.icon-file:before {
    content: "\e968";
}

.icon-film:before {
    content: "\e969";
}

.icon-filter:before {
    content: "\e96a";
}

.icon-flag:before {
    content: "\e96b";
}

.icon-folder-minus:before {
    content: "\e96c";
}

.icon-folder-plus:before {
    content: "\e96d";
}

.icon-folder:before {
    content: "\e96e";
}

.icon-framer:before {
    content: "\e96f";
}

.icon-frown:before {
    content: "\e970";
}

.icon-gift:before {
    content: "\e971";
}

.icon-git-branch:before {
    content: "\e972";
}

.icon-git-commit:before {
    content: "\e973";
}

.icon-git-merge:before {
    content: "\e974";
}

.icon-git-pull-request:before {
    content: "\e975";
}

.icon-github:before {
    content: "\e976";
}

.icon-gitlab:before {
    content: "\e977";
}

.icon-globe:before {
    content: "\e978";
}

.icon-grid:before {
    content: "\e979";
}

.icon-hard-drive:before {
    content: "\e97a";
}

.icon-hash:before {
    content: "\e97b";
}

.icon-headphones:before {
    content: "\e97c";
}

.icon-heart:before {
    content: "\e97d";
}

.icon-help-circle:before {
    content: "\e97e";
}

.icon-hexagon:before {
    content: "\e97f";
}

.icon-home:before {
    content: "\e980";
}

.icon-image:before {
    content: "\e981";
}

.icon-inbox:before {
    content: "\e982";
}

.icon-info:before {
    content: "\e983";
}

.icon-instagram:before {
    content: "\e984";
}

.icon-italic:before {
    content: "\e985";
}

.icon-key:before {
    content: "\e986";
}

.icon-layers:before {
    content: "\e987";
}

.icon-layout:before {
    content: "\e988";
}

.icon-life-buoy:before {
    content: "\e989";
}

.icon-link-2:before {
    content: "\e98a";
}

.icon-link:before {
    content: "\e98b";
}

.icon-linkedin:before {
    content: "\e98c";
}

.icon-list:before {
    content: "\e98d";
}

.icon-loader:before {
    content: "\e98e";
}

.icon-lock:before {
    content: "\e98f";
}

.icon-log-in:before {
    content: "\e990";
}

.icon-log-out:before {
    content: "\e991";
}

.icon-mail:before {
    content: "\e992";
}

.icon-map-pin:before {
    content: "\e993";
}

.icon-map:before {
    content: "\e994";
}

.icon-maximize-2:before {
    content: "\e995";
}

.icon-maximize:before {
    content: "\e996";
}

.icon-meh:before {
    content: "\e997";
}

.icon-menu:before {
    content: "\e998";
}

.icon-message-circle:before {
    content: "\e999";
}

.icon-message-square:before {
    content: "\e99a";
}

.icon-mic-off:before {
    content: "\e99b";
}

.icon-mic:before {
    content: "\e99c";
}

.icon-minimize-2:before {
    content: "\e99d";
}

.icon-minimize:before {
    content: "\e99e";
}

.icon-minus-circle:before {
    content: "\e99f";
}

.icon-minus-square:before {
    content: "\e9a0";
}

.icon-minus:before {
    content: "\e9a1";
}

.icon-monitor:before {
    content: "\e9a2";
}

.icon-moon:before {
    content: "\e9a3";
}

.icon-more-horizontal:before {
    content: "\e9a4";
}

.icon-more-vertical:before {
    content: "\e9a5";
}

.icon-mouse-pointer:before {
    content: "\e9a6";
}

.icon-move:before {
    content: "\e9a7";
}

.icon-music:before {
    content: "\e9a8";
}

.icon-navigation-2:before {
    content: "\e9a9";
}

.icon-navigation:before {
    content: "\e9aa";
}

.icon-octagon:before {
    content: "\e9ab";
}

.icon-package:before {
    content: "\e9ac";
}

.icon-paperclip:before {
    content: "\e9ad";
}

.icon-pause-circle:before {
    content: "\e9ae";
}

.icon-pause:before {
    content: "\e9af";
}

.icon-pen-tool:before {
    content: "\e9b0";
}

.icon-percent:before {
    content: "\e9b1";
}

.icon-phone-call:before {
    content: "\e9b2";
}

.icon-phone-forwarded:before {
    content: "\e9b3";
}

.icon-phone-incoming:before {
    content: "\e9b4";
}

.icon-phone-missed:before {
    content: "\e9b5";
}

.icon-phone-off:before {
    content: "\e9b6";
}

.icon-phone-outgoing:before {
    content: "\e9b7";
}

.icon-phone:before {
    content: "\e9b8";
}

.icon-pie-chart:before {
    content: "\e9b9";
}

.icon-play-circle:before {
    content: "\e9ba";
}

.icon-play:before {
    content: "\e9bb";
}

.icon-plus-circle:before {
    content: "\e9bc";
}

.icon-plus-square:before {
    content: "\e9bd";
}

.icon-plus:before {
    content: "\e9be";
}

.icon-pocket:before {
    content: "\e9bf";
}

.icon-power:before {
    content: "\e9a1";
}

.icon-printer:before {
    content: "\e9c1";
}

.icon-radio:before {
    content: "\e9c2";
}

.icon-refresh-ccw:before {
    content: "\e9c3";
}

.icon-refresh-cw:before {
    content: "\e9c4";
}

.icon-repeat:before {
    content: "\e9c5";
}

.icon-rewind:before {
    content: "\e9c6";
}

.icon-rotate-ccw:before {
    content: "\e9c7";
}

.icon-rotate-cw:before {
    content: "\e9c8";
}

.icon-rss:before {
    content: "\e9c9";
}

.icon-save:before {
    content: "\e9ca";
}

.icon-scissors:before {
    content: "\e9cb";
}

.icon-search:before {
    content: "\e9cc";
}

.icon-send:before {
    content: "\e9cd";
}

.icon-server:before {
    content: "\e9ce";
}

.icon-settings:before {
    content: "\e9cf";
}

.icon-share-2:before {
    content: "\e9d0";
}

.icon-share:before {
    content: "\e9d1";
}

.icon-shield-off:before {
    content: "\e9d2";
}

.icon-shield:before {
    content: "\e9d3";
}

.icon-shopping-bag:before {
    content: "\e9d4";
}

.icon-shopping-cart:before {
    content: "\e9d5";
}

.icon-shuffle:before {
    content: "\e9d6";
}

.icon-sidebar:before {
    content: "\e9d7";
}

.icon-skip-back:before {
    content: "\e9d8";
}

.icon-skip-forward:before {
    content: "\e9d9";
}

.icon-slack:before {
    content: "\e9da";
}

.icon-slash:before {
    content: "\e9db";
}

.icon-sliders:before {
    content: "\e9dc";
}

.icon-smartphone:before {
    content: "\e9dd";
}

.icon-smile:before {
    content: "\e9de";
}

.icon-speaker:before {
    content: "\e9df";
}

.icon-square:before {
    content: "\e9e0";
}

.icon-star:before {
    content: "\e9e1";
}

.icon-stop-circle:before {
    content: "\e9e2";
}

.icon-sun:before {
    content: "\e9e3";
}

.icon-sunrise:before {
    content: "\e9e4";
}

.icon-sunset:before {
    content: "\e9e5";
}

.icon-tablet:before {
    content: "\e9e6";
}

.icon-tag:before {
    content: "\e9e7";
}

.icon-target:before {
    content: "\e9e8";
}

.icon-terminal:before {
    content: "\e9e9";
}

.icon-thermometer:before {
    content: "\e9ea";
}

.icon-thumbs-down:before {
    content: "\e9eb";
}

.icon-thumbs-up:before {
    content: "\e9ec";
}

.icon-toggle-left:before {
    content: "\e9ed";
}

.icon-toggle-right:before {
    content: "\e9ee";
}

.icon-trash-2:before {
    content: "\e9ef";
}

.icon-trash:before {
    content: "\e9f0";
}

.icon-trello:before {
    content: "\e9f1";
}

.icon-trending-down:before {
    content: "\e9f2";
}

.icon-trending-up:before {
    content: "\e9f3";
}

.icon-triangle:before {
    content: "\e9f4";
}

.icon-truck:before {
    content: "\e9f5";
}

.icon-tv:before {
    content: "\e9f6";
}

.icon-twitter:before {
    content: "\e9f7";
}

.icon-type:before {
    content: "\e9f8";
}

.icon-umbrella:before {
    content: "\e9f9";
}

.icon-underline:before {
    content: "\e9fa";
}

.icon-unlock:before {
    content: "\e9fb";
}

.icon-upload-cloud:before {
    content: "\e9fc";
}

.icon-upload:before {
    content: "\e9fd";
}

.icon-user-check:before {
    content: "\e9fe";
}

.icon-user-minus:before {
    content: "\e9ff";
}

.icon-user-plus:before {
    content: "\ea00";
}

.icon-user-x:before {
    content: "\ea01";
}

.icon-user:before {
    content: "\ea02";
}

.icon-users:before {
    content: "\ea03";
}

.icon-video-off:before {
    content: "\ea04";
}

.icon-video:before {
    content: "\ea05";
}

.icon-voicemail:before {
    content: "\ea06";
}

.icon-volume-1:before {
    content: "\ea07";
}

.icon-volume-2:before {
    content: "\ea08";
}

.icon-volume-x:before {
    content: "\ea09";
}

.icon-volume:before {
    content: "\ea0a";
}

.icon-watch:before {
    content: "\ea0b";
}

.icon-wifi-off:before {
    content: "\ea0c";
}

.icon-wifi:before {
    content: "\ea0d";
}

.icon-wind:before {
    content: "\ea0e";
}

.icon-x-circle:before {
    content: "\ea0f";
}

.icon-x-octagon:before {
    content: "\ea10";
}

.icon-x-square:before {
    content: "\ea11";
}

.icon-x:before {
    content: "\ea12";
}

.icon-youtube:before {
    content: "\ea13";
}

.icon-zap-off:before {
    content: "\ea14";
}

.icon-zap:before {
    content: "\ea15";
}

.icon-zoom-in:before {
    content: "\ea16";
}

.icon-zoom-out:before {
    content: "\ea17";
}

div.icon-box:before {
    display: none !important;
}


/* ----------------------------------------------------------------------
	Icon Box
-------------------------------------------------------------------------*/

.icon {
    margin-bottom: 16px;
}

div.icon-box:before {
    display: none !important;
}

.icon-box {
    margin-bottom: 50px;
    position: relative;
}

.icon-box .icon {
    height: 64px;
    position: absolute;
    width: 64px;
}

.icon-box .icon i {
    font-size: 28px;
    text-align: center;
    line-height: 66px;
    border-radius: 50%;
}

.icon-box .icon a,
.icon-box .icon i {
    width: 100%;
    height: 100%;
    color: #333;
}

.icon-box h3,
.icon-box h5 {
    margin: 0 12px 10px 83px;
    letter-spacing: 0px;
    font-size: 16px;
    line-height: 25px;
    font-weight: 700;
}

.icon-box p {
    margin: 0 0 20px 83px;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
}

.icon-box-description>p {
    margin-bottom: 20px;
}

.icon-box.effect .icon i {
    z-index: 1;
}

.icon-box.effect .icon i:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    box-sizing: content-box;
}

.icon-box .icon i {
    -webkit-transition: background-color 0.2s, color 0.2s;
    transition: background-color 0.2s, color 0.2s;
}

.icon-box .icon i:after {
    top: -4px;
    left: -4px;
    padding: 4px;
    box-shadow: 0 0 0 3px #333;
    -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
    -webkit-transform: scale(0.8);
    -moz-transition: -moz-transform 0.2s, opacity 0.2s;
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
    transition: transform 0.2s, opacity 0.2s;
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
}

.icon-box.effect.dark .icon i:after {
    box-shadow: 0 0 0 3px #1290c6;
}

.icon-box.effect.dark .icon i:hover,
.icon-box.effect.dark:hover .icon i {
    background-color: #1290c6;
}

.icon-box.effect .icon i:hover:after,
.icon-box.effect:hover .icon i:after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}

.image-box .image-box-content p i.fa.fa-play {
    padding-left: 3px;
}

.icon-box.small .icon {
    height: 46px;
    width: 46px;
}

.icon-box.small .icon i {
    font-size: 20px;
    line-height: 47px;
}

.icon-box.small>h3,
.icon-box.small>h5 {
    font-size: 18px;
    margin: 0 12px 4px 60px;
}

.icon-box.small>p {
    margin: 0 0 20px 60px;
    line-height: 1.6;
    font-size: 14px;
}

.icon-box.medium>h3,
.icon-box.medium>h4,
.icon-box.medium>h5 {
    margin: 0 12px 10px 84px;
}

.icon-box.medium>p {
    margin: 0 0 20px 84px;
}

.icon-box.large .icon {
    height: 86px;
    width: 86px;
}

.icon-box.large .icon i {
    font-size: 38px;
    line-height: 87px;
}

.icon-box.large>h3,
.icon-box.large>h5 {
    margin: 0 12px 10px 110px;
    font-size: 24px;
}

.icon-box.large p {
    margin: 0 0 20px 110px;
}

.icon-box.icon-box-right {
    padding: 0;
    text-align: right;
}

.icon-box.icon-box-right>h3,
.icon-box.icon-box-right>h5 {
    margin: 0px 84px 0px 10px;
}

.icon-box.icon-box-right.medium>p {
    margin: 20px 84px 0px 10px;
}

.icon-box.icon-box-right .icon {
    right: 0;
}

.icon-box.icon-box-right p {
    font-size: 14px;
    margin-top: 15px;
}

.icon-box.text-center,
.icon-box.center {
    padding: 0;
    text-align: center;
}

.icon-box.text-center h3,
.icon-box.text-center h5,
.icon-box.center h3,
.icon-box.center h5 {
    margin: 0 12px 10px 0px;
}

.icon-box.text-center p,
.icon-box.center p {
    margin: 0 0 20px 0px;
}

.icon-box.text-center .icon,
.icon-box.center .icon {
    margin: 0 auto 22px;
    position: relative;
}

.icon-box.text-center p,
.icon-box.center p {
    font-size: 16px;
    margin-top: 15px;
}

.icon-box.text-center.small .icon,
.icon-box.center.small .icon {
    clear: both !important;
    position: relative !important;
}

.icon-box.border {
    border: 0 !important;
}

.icon-box.border .icon {
    border: 3px solid #333;
    text-align: center;
    border-radius: 50%;
    background-color: transparent;
}

.icon-box.border .icon i {
    line-height: 56px;
}

.icon-box.border:hover>.icon i {
    line-height: 62px;
}

.icon-box.border:hover>.icon {
    border: 0px;
}

.icon-box.border .icon i {
    background-color: transparent !important;
    color: #333;
}

.icon-box.border.small .icon {
    height: 46px;
    position: absolute;
    top: 5px;
    width: 46px;
}

.icon-box.border.small .icon i {
    font-size: 20px;
    line-height: 42px;
}

.icon-box.border.small:hover>.icon i {
    line-height: 49px;
}

.icon-box.border.large .icon i {
    line-height: 81px;
}

.icon-box.border.large:hover>.icon i {
    line-height: 86px;
}

.icon-box.square.large:hover>.icon i {
    line-height: 86px;
}

.icon-box.square .icon,
.icon-box.square.effect .icon i:after,
.icon-box.square .icon i {
    border-radius: 0;
}

.icon-box.square:hover>.icon i {
    line-height: 66px;
}

.icon-box.square.border .icon i {
    line-height: 59px;
}

.icon-box.square.border:hover>.icon i {
    line-height: 64px;
}

.icon-box.square.border.small .icon i {
    line-height: 41px;
}

.icon-box.square.border.small:hover>.icon i {
    line-height: 47px;
}

.icon-box.square.border.large .icon i {
    line-height: 78px;
}

.icon-box.square.border:large:hover>.icon i {
    line-height: 84px;
}

.icon-box.square.small:hover>.icon i {
    line-height: 48px;
}

.icon-box.light .icon i:after {
    box-shadow: 0 0 0 3px #e5e5e5;
}

.icon-box.light .icon i {
    background-color: #e5e5e5;
}

.icon-box.light .icon i {
    color: #808080;
}

.icon-box.light.border .icon {
    border: 3px solid #e5e5e5;
}

.icon-box.light.border.large .icon i {
    line-height: 87px;
}

.icon-box.light.border.large:hover>.icon i {
    line-height: 88px;
}

.icon-box.fancy .icon i {
    background-color: transparent;
    color: #222;
    font-size: 110px;
    opacity: 0.09;
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
    border-radius: 0px;
}

.icon-box.fancy.small>.icon i {
    font-size: 68px;
}

.icon-box.fancy.large .icon i {
    font-size: 120px;
}

.icon-box.fancy.text-center .icon,
.icon-box.fancy.center .icon {
    width: auto;
    margin-bottom: 30px;
}

.icon-box.effect.fancy .icon i:after {
    box-shadow: 0 0 0 0px transparent;
}

.icon-box.effect.fancy:hover>.icon i {
    font-size: 110px;
}

.icon-box.effect.fancy.small:hover>.icon i {
    font-size: 76px;
}

.icon-box.clean .icon i {
    background-color: none;
    color: #333;
    font-size: 50px;
}

.icon-box.clean.small .icon i {
    font-size: 36px;
}

.icon-box.effect.clean .icon i:after {
    box-shadow: 0 0 0 0px transparent;
}

.icon-box.process {
    border: 1px solid #888;
    margin-top: 45px;
    padding: 0 30px 10px;
    border-radius: 4px;
}

.icon-box.process.effect .icon i:hover:after,
.icon-box.process.effect:hover .icon i::after {
    opacity: 0;
    border: 0px;
}

.icon-box.process .icon {
    margin-top: 10px;
}

.icon-box.process.small .icon {
    margin-top: 10px;
}

.icon-box.box-type {
    background: #fafafa;
    border: 1px solid #ececec;
    border-radius: 4px;
    padding: 20px;
}

.icon-box.box-type .icon {
    margin-top: 20px;
}


/*Text Box*/

.text-box {
    padding: 40px;
    text-align: center;
    transition: background 0.4s ease 0s;
    cursor: pointer;
}

.text-box i {
    font-size: 42px;
    margin: 30px 0 20px;
}

.text-box i {
    color: #fff;
    transition: all 0.4s ease 0s;
}

.text-box h3,
.text-box h5,
.text-box p {
    color: #fff;
    transition: all 0.4s ease 0s;
}

.text-box:hover {
    background: #eeeeee;
}

.text-box:hover i,
.text-box:hover h3,
.text-box:hover h5,
.text-box:hover p {
    color: #333;
}

.text-box.text-box-light {
    border: 1px solid #d9d9d9;
    background: #ffffff;
}

.text-box.text-box-light i,
.text-box.text-box-light h3,
.text-box.text-box-light h5,
.text-box.text-box-light p {
    color: #1f1f1f;
}

.text-box.text-box-light:hover {
    background: #1290c6;
}

.text-box.text-box-light:hover i,
.text-box.text-box-light:hover h3,
.text-box.text-box-light:hover h5,
.text-box.text-box-light:hover p {
    color: #ffffff;
}

.text-box.hover-effect {
    padding: 30px 30px 10px 30px;
}

.text-box.hover-effect i {
    margin: 90px 0 20px;
}

.text-box.hover-effect p {
    opacity: 0;
}

.text-box.hover-effect:hover i {
    margin: 70px 0 6px 0;
}

.text-box.hover-effect:hover p {
    opacity: 1;
}


/* ----------------------------------------------------------------
    Featured Boxes
-----------------------------------------------------------------*/

.feature-box {
    background-color: #fff;
    margin-bottom: 0px;
    padding: 44px;
    border-radius: 4px;
    background-clip: padding-box;
    box-shadow: 0 7px 20px -10px rgba(171, 171, 171, 0.7);
    transition: all 0.55s ease;
    text-align: center;
    min-height: 250px;
}

.feature-box.feature-box-transparent {
    background-color: transparent;
    box-shadow: none;
}

.feature-box.feature-box-transparent:hover {
    background-color: #ffffff;
}

.feature-box>i {
    font-size: 46px;
    margin-bottom: 20px;
}

.feature-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9);
}

.feature-box:hover h2,
.feature-box:hover h3,
.feature-box:hover h4 {
    color: #1290c6;
}

.feature-box h2,
.feature-box h3,
.feature-box h4 {
    font-size: 14px;
    text-transform: uppercase;
    transition: all 0.33s;
    margin-top: 0px;
    margin-bottom: 10px;
}

.feature-box p {
    font-size: 14px;
    margin-bottom: 0;
}

.col-lg-2 .feature-box p {
    font-size: 12px;
    margin-bottom: 0;
    line-height: 18px;
    opacity: 0.8;
}

.icon-boxes {
    box-shadow: 0 6px 38px rgba(0, 0, 0, 0.05);
}

.icon-boxx {
    display: inline-flex;
    align-items: flex-start;
    padding: 60px 40px;
    border-left: 1px solid #e6e8eb;
    border-bottom: 1px solid #e6e8eb;
    min-height: 240px;
}

.icon-boxx i {
    font-size: 36px;
    font-weight: 100;
    margin-top: 10px;
}

.icon-boxx .icon-box-content {
    padding-left: 30px;
}

.icon-boxx .icon-box-content h3 {
    font-size: 20px;
    color: #1f1f1f;
    font-weight: 400;
}

.icon-boxx .icon-box-content p {
    font-size: 1rem;
    margin-bottom: 0;
    color: #9896a6;
}


/* ----------------------------------------------------------------
Carousels
-----------------------------------------------------------------*/

.flickity-viewport {
    overflow: hidden !important;
}

.flickity-slider>div>img,
.carousel .polo-carousel-item img {
    width: 100%;
}

.carousel[data-auto-width="true"] .flickity-slider>div>img,
.carousel[data-auto-width="true"] .carousel .polo-carousel-item img,
.carousel.custom-height .flickity-slider>div>img,
.carousel.custom-height .carousel .polo-carousel-item img {
    height: 100%;
    width: auto;
}

.flickity-enabled {
    position: relative;
}

.flickity-enabled:focus {
    outline: none;
}

.flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
}


/* draggable */

.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}


/* flickity-fade */

.flickity-enabled.is-fade .flickity-slider>* {
    pointer-events: none;
    z-index: 0;
}

.flickity-enabled.is-fade .flickity-slider>.is-selected {
    pointer-events: auto;
    z-index: 1;
}


/* ---- flickity-button ---- */

.flickity-button {
    background: #fff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin: -23px 0 0;
    display: block;
    position: absolute;
    top: 50%;
    z-index: 10;
    cursor: pointer;
    text-align: center;
    transition: all .2s ease 0s;
    color: #9896a6;
    text-align: center;
    z-index: 200;
    border: 0;
    box-sizing: initial;
    opacity: 0;
    border-radius: 50%;
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
}

.flickity-button:before {
    color: #1f1f1f;
}

.flickity-button:hover {
    background-color: #1290c6;
}

.flickity-button:hover:before {
    color: #ffffff;
}

.flickity-button.next:before,
.flickity-button.previous:before {
    font-size: 25px;
    font-family: inspiro-icons !important;
}

.flickity-button.next {
    right: 0px;
}

.flickity-button.next:before {
    margin-left: 4px;
    content: "\e930";
}

.flickity-button.next.disabled {
    opacity: 0;
}

.flickity-button.previous {
    left: 0px;
}

.flickity-button.previous:before {
    content: "\e92f";
    margin-right: 2px;
}

.flickity-button.previous.disabled {
    opacity: 0;
}

.flickity-button svg {
    display: none !important;
}

.inspiro-slider:hover .flickity-button.next,
.carousel:hover .flickity-button.next {
    left: auto;
    right: 10px;
    opacity: 1;
}

.inspiro-slider:hover .flickity-button.next.disabled,
.carousel:hover .flickity-button.next.disabled {
    opacity: 0;
}

.inspiro-slider:hover .flickity-button.previous,
.carousel:hover .flickity-button.previous {
    right: auto;
    left: 10px;
    opacity: 1;
}

.inspiro-slider:hover .flickity-button.previous.disabled,
.carousel:hover .flickity-button.previous.disabled {
    opacity: 0;
}

.inspiro-slider.arrows-dark .flickity-button,
.carousel.arrows-dark .flickity-button {
    color: #9896a6;
}

.inspiro-slider.arrows-dark .flickity-button:hover,
.carousel.arrows-dark .flickity-button:hover {
    background-color: #1290c6;
}

.inspiro-slider.arrows-dark .flickity-button:hover:before,
.carousel.arrows-dark .flickity-button:hover:before {
    color: #ffffff;
}

.inspiro-slider.arrows-grey .flickity-button,
.carousel.arrows-grey .flickity-button {
    color: #cecece;
}

.inspiro-slider.arrows-grey .flickity-button:hover,
.carousel.arrows-grey .flickity-button:hover {
    background-color: #1290c6;
}

.inspiro-slider.arrows-grey .flickity-button:hover:before,
.carousel.arrows-grey .flickity-button:hover:before {
    color: #ffffff;
}

.inspiro-slider.arrows-only .flickity-button,
.carousel.arrows-only .flickity-button {
    background-color: transparent;
    color: #ffffff;
    box-shadow: none;
}

.inspiro-slider.arrows-only .flickity-button:hover,
.carousel.arrows-only .flickity-button:hover {
    background-color: transparent;
}

.inspiro-slider.arrows-only .flickity-button:hover:before,
.carousel.arrows-only .flickity-button:hover:before {
    color: #ffffff;
}

.inspiro-slider.arrows-visibile .flickity-button.next,
.carousel.arrows-visibile .flickity-button.next {
    left: auto;
    right: 10px;
    opacity: 1;
}

.inspiro-slider.arrows-visibile .flickity-button.previous,
.carousel.arrows-visibile .flickity-button.previous {
    right: auto;
    left: 10px;
    opacity: 1;
}

.inspiro-slider .portfolio-item,
.carousel .portfolio-item {
    padding-bottom: 0;
}

.polo-carousel-item.is-selected {
    z-index: 2;
}

.flickity-button-icon {
    fill: currentColor;
}


/* ----------------------------------------------------------------
Carousel: Dots
-----------------------------------------------------------------*/

.flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: -25px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
    z-index: 3;
    height: 10px;
}

.flickity-page-dots .dot {
    display: inline-block;
    position: relative;
    margin: 0 4px;
    width: 10px;
    height: 10px;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #cccccc;
    transition: background 0.8s ease;
}

.flickity-page-dots .dot:after {
    outline: medium none;
    text-indent: -999em;
    border-radius: 50%;
    overflow: hidden;
    content: '';
    position: absolute;
    bottom: 0;
    height: 0;
    left: 0;
    width: 100%;
    background-color: #cccccc;
    box-shadow: 0 0 1px #cccccc;
    transition: height 0.3s ease;
}

.flickity-page-dots .dot.is-selected:after {
    height: 100%;
}

.inspiro-slider,
.carousel {
    /* dots inside */
    /* dots top */
    /* dots light */
    /* dots dark */
    /* dots grey */
}

.inspiro-slider.dots-inside .flickity-page-dots,
.carousel.dots-inside .flickity-page-dots {
    bottom: 20px;
    position: absolute;
}

.inspiro-slider.dots-inside-top .flickity-page-dots,
.carousel.dots-inside-top .flickity-page-dots {
    top: 20px;
}

.inspiro-slider.dots-outside-top .flickity-page-dots,
.carousel.dots-outside-top .flickity-page-dots {
    top: -46px;
}

.inspiro-slider.dots-light .flickity-page-dots .dot,
.carousel.dots-light .flickity-page-dots .dot {
    box-shadow: inset 0 0 0 2px #ffffff;
}

.inspiro-slider.dots-light .flickity-page-dots .dot:after,
.carousel.dots-light .flickity-page-dots .dot:after {
    background-color: #ffffff;
    box-shadow: 0 0 1px #ffffff;
}

.inspiro-slider.dots-dark .flickity-page-dots .dot,
.carousel.dots-dark .flickity-page-dots .dot {
    box-shadow: inset 0 0 0 2px #9896a6;
}

.inspiro-slider.dots-dark .flickity-page-dots .dot:after,
.carousel.dots-dark .flickity-page-dots .dot:after {
    background-color: #9896a6;
}

.inspiro-slider.dots-grey .flickity-page-dots .dot,
.carousel.dots-grey .flickity-page-dots .dot {
    box-shadow: inset 0 0 0 2px #bbbbbb;
}

.inspiro-slider.dots-grey .flickity-page-dots .dot:after,
.carousel.dots-grey .flickity-page-dots .dot:after {
    background-color: #bbbbbb;
}

.inspiro-slider.dots-creative .flickity-page-dots .dot,
.carousel.dots-creative .flickity-page-dots .dot {
    opacity: 0.5;
    box-shadow: none;
    border-radius: 0;
    background-color: #ffffff;
    height: 4px;
    width: 30px;
}

.inspiro-slider.dots-creative .flickity-page-dots .dot:after,
.carousel.dots-creative .flickity-page-dots .dot:after {
    background-color: #ffffff;
    box-shadow: none;
}

.inspiro-slider.dots-creative .flickity-page-dots .dot:hover,
.carousel.dots-creative .flickity-page-dots .dot:hover {
    opacity: 0.6;
}

.inspiro-slider.dots-creative .flickity-page-dots .dot.is-selected,
.carousel.dots-creative .flickity-page-dots .dot.is-selected {
    opacity: 1;
}

.inspiro-slider.dots-creative.dots-dark .flickity-page-dots .dot,
.carousel.dots-creative.dots-dark .flickity-page-dots .dot {
    background-color: #9896a6;
}

.inspiro-slider.dots-creative.dots-dark .flickity-page-dots .dot:after,
.carousel.dots-creative.dots-dark .flickity-page-dots .dot:after {
    background-color: #9896a6;
}

.flickity-rtl .flickity-page-dots {
    direction: rtl;
}

.carousel-promotion .owl-stage-outer {
    overflow: unset !important;
}

.carousel-promotion .owl-stage-outer .polo-carousel-item {
    opacity: .3;
    transition: opacity 400ms ease;
}

.carousel-promotion .owl-stage-outer .polo-carousel-item.active {
    opacity: 1;
}


/* Owl Carousel - Settings
-----------------------------------------------------------------*/

.carousel {
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease;
}

.carousel.carousel-loaded {
    opacity: 1;
    visibility: visible;
}

.item-video {
    position: relative;
    width: 100%;
    height: 400px;
}

.item-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}


/* ----------------------------------------------------------------
    Responsive Classes
-----------------------------------------------------------------*/

@media (max-width: 1024px) {
    .carousel .flickity-button {
        width: 40px;
    }
    .carousel .flickity-button.next,
    .carousel .flickity-button.next:hover {
        right: 10px !important;
        opacity: 1;
    }
    .carousel .flickity-button.previous,
    .carousel .flickity-button.previous:hover {
        left: 10px !important;
        opacity: 1;
    }
}


/* ----------------------------------------------------------------------
	Counters
-------------------------------------------------------------------------*/

.counter {
    margin-bottom: 14px;
}

.counter span {
    font-size: 50px;
    line-height: 40px;
}

.counter.small>span {
    font-size: 32px !important;
}

.counter.medium>span {
    font-size: 50px !important;
}

.counter.large>span {
    font-size: 62px !important;
}

.icon-box.fancy>.counter span {
    margin: 0 12px 10px 56px;
}

.icon-box.fancy.medium>.counter span {
    margin: 0 12px 10px 80px;
}

.icon-box.effect:not(.center)>.counter span {
    margin: 0 12px 10px 82px;
}

.icon-box.effect.large:not(.center)>.counter span {
    margin: 0 12px 10px 107px;
}

.icon-box.effect.medium:not(.center)>.counter span {
    margin: 0 12px 10px 82px;
}

.icon-box.effect.small:not(.center)>.counter span {
    margin: 0 12px 10px 58px;
    font-size: 32px !important;
}

.countdown-container {
    display: table;
    margin: 0 auto;
}

.countdown {
    color: #484848;
    overflow: hidden;
}

.countdown .countdown-box {
    display: block;
    float: left;
    margin: 24px;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: auto;
}

.countdown .number {
    font-size: 72px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 10px;
    text-align: center;
}

.countdown span {
    display: block;
    font-size: 18px;
    font-weight: 400;
    overflow: hidden;
    padding-top: 4px;
    position: relative;
    text-align: center;
    color: #9896a6;
}

.countdown span:before {
    background: none repeat scroll 0 0 #9896a6;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    opacity: .3;
}

.countdown.circle .countdown-box {
    border: 3px solid #9896a6;
    border-radius: 50%;
    color: #1f1f1f;
    height: 160px;
    padding: 34px 5px 0 0;
    width: 160px;
    margin: 20px 15px;
}

.countdown.circle .countdown-box .number {
    font-size: 52px;
    font-weight: 800px;
}

.countdown.circle .countdown-box span {
    font-size: 18px;
}

.countdown.rectangle .countdown-box {
    border: 4px solid;
    padding: 24px;
    width: 170px;
    margin-left: 15px;
    margin-right: 15px;
}

.countdown.rectangle .countdown-box .number {
    font-size: 52px;
    font-weight: 600;
}

.countdown.rectangle .countdown-box span {
    font-size: 18px;
}

.countdown.medium .countdown-box,
.countdown.circle.medium .countdown-box,
.countdown.rectangle.medium .countdown-box {
    height: 90px;
    margin: 6px;
    padding: 20px 4px 0 0;
    width: 90px;
}

.countdown.medium .number,
.countdown.circle.medium .number,
.countdown.rectangle.medium .number {
    font-size: 30px;
    margin-bottom: 4px;
}

.countdown.medium span,
.countdown.circle.medium span,
.countdown.rectangle.medium span {
    font-size: 12px;
}

.countdown.circle.medium .countdown-box,
.countdown.rectangle.medium .countdown-box {
    border: solid 2px;
}

.countdown.small .countdown-box,
.countdown.circle.small .countdown-box,
.countdown.rectangle.small .countdown-box {
    margin: 10px;
    height: 60px;
    padding: 10px 0px 0 0;
    width: 60px;
    margin: 5px 2px;
}

.countdown.small .number,
.countdown.circle.small .number,
.countdown.rectangle.small .number {
    font-size: 14px;
    margin-bottom: 6px;
}

.countdown.small span,
.countdown.circle.small span,
.countdown.rectangle.small span {
    font-size: 10px;
    padding-top: 0px;
}

.countdown.circle.small .countdown-box,
.countdown.rectangle.small .countdown-box {
    border: solid 2px;
}

.countdown.light .countdown-box {
    border-color: #ffffff;
    color: #ffffff;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.countdown.countdown-light span:before {
    background: #ffffff;
}

.countdown.countdown-light span,
.countdown.countdown-light .number {
    color: #ffffff !important;
}

.countdown.countdown-light.circle .countdown-box {
    border-color: #ffffff;
}

.countdown.countdown-light.rectangle .countdown-box {
    border-color: #ffffff;
}

.countdown.countdown-dark span,
.countdown.countdown-dark .number {
    color: #9896a6 !important;
}

@media (max-width: 1024px) {
    .countdown .countdown-box {
        height: 60px !important;
        padding: 2px 0px 0 0 !important;
        width: 60px !important;
        margin: 0px !important;
        border: solid 0px !important;
    }
    .countdown .countdown-box .number {
        font-size: 20px !important;
        margin-bottom: 0px;
    }
    .countdown .countdown-container .countdown-box span {
        font-size: 10px !important;
        padding-top: 0px;
    }
}


/* ----------------------------------------------------------------
    Dropcat & Highlight
-----------------------------------------------------------------*/

.dropcap {
    background: none repeat scroll 0 0 #eeeeee;
    border-radius: 4px;
    display: inline-block;
    float: left;
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    margin: 8px 20px 0 0;
    text-align: center;
    width: 40px;
}

.dropcap.dropcap-circle {
    border-radius: 50%;
}

.dropcap.dropcap-large {
    font-size: 44px;
    height: 80px;
    line-height: 80px;
    width: 80px;
}

.dropcap.dropcap-small {
    font-size: 16px;
    height: 20px;
    line-height: 20px;
    margin: 3px 5px -3px 0;
    width: 21px;
}

.dropcap.dropcap-colored {
    background-color: #1290c6;
    color: #ffffff;
}

span.highlight {
    padding: 0 5px;
}

.highlight.highlight-primary {
    color: #1290c6;
    font-weight: bold;
}

.highlight.highlight-secondary {
    color: #5A6268;
    font-weight: bold;
}

.highlight.highlight-warning {
    color: #ffb20e;
    font-weight: bold;
}

.highlight.highlight-danger {
    color: #CE041C;
    font-weight: bold;
}

.highlight.highlight-info {
    color: #53b0f8;
    font-weight: bold;
}

.highlight a {
    color: #333;
}

.highlight.highlight-large {
    font-size: 20px;
}

.highlight.highlight-small {
    font-size: 12px;
    padding: 0 7px;
}

.highlight.highlight-colored {
    background-color: #d58640;
    color: #241313;
}


/* ----------------------------------------------------------------
    Team Members
-----------------------------------------------------------------*/

.team-members .team-member {
    background-color: #ffffff;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 4px;
}

.team-members .team-member .team-image {
    margin-bottom: 20px;
}

.team-members .team-member .team-image>img {
    width: 100%;
    border-radius: 5px 5px 0 0;
}

.team-members .team-member .team-desc {
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.team-members .team-member .team-desc>h3 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;
}

.team-members .team-member .team-desc>span {
    font-size: 13px;
    color: #bbbbbb;
    line-height: 18px;
}

.team-members .team-member .team-desc>p {
    margin-top: 10px;
    font-size: 14px;
}

.team-members.team-members-circle .team-member {
    background-color: transparent;
    padding: 20px;
}

.team-members.team-members-circle .team-member .team-image>img {
    border-radius: 50%;
}

.team-members.team-members-card .team-member {
    border: 1px solid #eeeeee;
    border-radius: 4px;
}

.team-members.team-members-card .team-member .team-image>img {
    border-radius: 0;
}

.team-members.team-members-card .team-member .team-desc {
    background-color: #fdfdfd;
    border-radius: 0px 0px 4px 4px;
    padding: 10px;
}

.team-members.team-members-shadow .team-member {
    border: 1px solid #eeeeee;
    box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.03);
}

.team-members.team-members-shadow .team-member .team-image>img {
    border-radius: 0;
}

.team-members.team-members-shadow .team-member .team-desc {
    padding: 20px;
}

.team-members.team-members-transparent .team-member {
    background-color: transparent;
}

.team-members.team-members-transparent .team-member .team-desc>h3 {
    color: #ffffff;
}

.team-members.team-members-transparent .team-member .team-desc>span {
    color: #ffffff;
}

.team-members.team-members-transparent .team-member .team-desc>p {
    color: #ffffff;
}

.team-members.team-members-left .team-member {
    text-align: left;
}

.team-members.team-members-left .team-member .team-image {
    float: left;
    width: 40%;
    margin-bottom: 0;
}

.team-members.team-members-left .team-member .team-desc {
    overflow: hidden;
    position: relative;
    width: 60%;
    padding-left: 30px;
}

.team-members.team-members-left .team-member .team-desc>h3 {
    margin-top: 0;
}

.portfolio-item .team_details_link {
    left: 0;
    margin: 0 auto;
    padding: 20px;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 28%;
    transform: translate3d(0px, -38%, 0px);
    transition: all 250ms ease-in-out 0s;
    visibility: hidden;
    width: 70%;
    z-index: 3;
}

.team-members:hover .team_details_link,
{
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible;
}


/* ----------------------------------------------------------------------
	Progress Bar
-------------------------------------------------------------------------*/

.p-progress-bar-container,
.progress-bar-container {
    width: 100%;
    height: 40px;
    margin: 20px 0 20px 0;
    overflow: hidden;
    background-color: #eee;
}

.p-progress-bar,
.progress-bar {
    height: 100%;
    padding: 10px;
    background-color: #1290c6;
    box-shadow: none;
}

.p-progress-bar .progress-number,
.p-progress-bar .progress-type,
.progress-bar .progress-number,
.progress-bar .progress-type {
    color: #fff;
    float: right;
    margin-top: -2px;
}

.progress-title {
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    position: absolute;
    text-align: left;
}

.progress-title i {
    margin-right: 8px;
}

.p-progress-bar-container.radius,
.p-progress-bar-container.radius .p-progress-bar,
.p-progress-bar-container.radius .progress-bar,
.progress-bar-container.radius,
.progress-bar-container.radius .p-progress-bar,
.progress-bar-container.radius .progress-bar {
    border-radius: 4px;
}

.p-progress-bar-container.title-up .progress-title,
.progress-bar-container.title-up .progress-title {
    color: #333;
    margin-left: -10px;
    margin-top: -32px;
}

.p-progress-bar-container.title-up:not(:first-child),
.progress-bar-container.title-up:not(:first-child) {
    margin-top: 40px;
}

.p-progress-bar-container.title-up .p-progress-bar .progress-number,
.p-progress-bar-container.title-up .p-progress-bar .progress-type,
.p-progress-bar-container.title-up .progress-bar .progress-number,
.p-progress-bar-container.title-up .progress-bar .progress-type,
.progress-bar-container.title-up .p-progress-bar .progress-number,
.progress-bar-container.title-up .p-progress-bar .progress-type,
.progress-bar-container.title-up .progress-bar .progress-number,
.progress-bar-container.title-up .progress-bar .progress-type {
    color: #333;
    margin-top: -32px;
    position: absolute;
    right: 15px;
}

.p-progress-bar-container.title-up .p-progress-bar .progress-number,
.p-progress-bar-container.title-up .progress-bar .progress-number,
.progress-bar-container.title-up .p-progress-bar .progress-number,
.progress-bar-container.title-up .progress-bar .progress-number {
    right: 26px;
}

.p-progress-bar-container.medium,
.progress-bar-container.medium {
    height: 20px;
}

.p-progress-bar-container.medium .p-progress-bar,
.p-progress-bar-container.medium .progress-bar,
.progress-bar-container.medium .p-progress-bar,
.progress-bar-container.medium .progress-bar {
    padding: 0;
}

.p-progress-bar-container.medium .progress-title,
.progress-bar-container.medium .progress-title {
    margin-left: 0;
    margin-top: -20px;
}

.p-progress-bar-container.medium .p-progress-bar .progress-number,
.p-progress-bar-container.medium .p-progress-bar .progress-type,
.p-progress-bar-container.medium .progress-bar .progress-number,
.p-progress-bar-container.medium .progress-bar .progress-type,
.progress-bar-container.medium .p-progress-bar .progress-number,
.progress-bar-container.medium .p-progress-bar .progress-type,
.progress-bar-container.medium .progress-bar .progress-number,
.progress-bar-container.medium .progress-bar .progress-type {
    margin-top: -18px;
}

.p-progress-bar-container.small,
.progress-bar-container.small {
    height: 10px;
}

.p-progress-bar-container.small .p-progress-bar,
.p-progress-bar-container.small .progress-bar,
.progress-bar-container.small .p-progress-bar,
.progress-bar-container.small .progress-bar {
    padding: 0;
}

.p-progress-bar-container.small .progress-title,
.progress-bar-container.small .progress-title {
    margin-left: 0;
    margin-top: -20px;
}

.p-progress-bar-container.small .p-progress-bar .progress-number,
.p-progress-bar-container.small .p-progress-bar .progress-type,
.p-progress-bar-container.small .progress-bar .progress-number,
.p-progress-bar-container.small .progress-bar .progress-type,
.progress-bar-container.small .p-progress-bar .progress-number,
.progress-bar-container.small .p-progress-bar .progress-type,
.progress-bar-container.small .progress-bar .progress-number,
.progress-bar-container.small .progress-bar .progress-type {
    margin-top: -18px;
}

.p-progress-bar-container.no-bg,
.progress-bar-container.no-bg {
    background: none !important;
}

.p-progress-bar-container.extra-small,
.progress-bar-container.extra-small {
    height: 2px;
}

.p-progress-bar-container.extra-small .p-progress-bar,
.p-progress-bar-container.extra-small .progress-bar,
.progress-bar-container.extra-small .p-progress-bar,
.progress-bar-container.extra-small .progress-bar {
    padding: 0;
}

.p-progress-bar-container.extra-small .progress-title,
.progress-bar-container.extra-small .progress-title {
    margin-left: 0;
    margin-top: -20px;
}

.p-progress-bar-container.extra-small .p-progress-bar .progress-number,
.p-progress-bar-container.extra-small .p-progress-bar .progress-type,
.p-progress-bar-container.extra-small .progress-bar .progress-number,
.p-progress-bar-container.extra-small .progress-bar .progress-type,
.progress-bar-container.extra-small .p-progress-bar .progress-number,
.progress-bar-container.extra-small .p-progress-bar .progress-type,
.progress-bar-container.extra-small .progress-bar .progress-number,
.progress-bar-container.extra-small .progress-bar .progress-type {
    margin-top: -18px;
}

.p-progress-bar-container.transparent,
.progress-bar-container.transparent {
    background: none !important;
}

.p-progress-bar-container.transparent .p-progress-bar,
.p-progress-bar-container.transparent .progress-bar,
.progress-bar-container.transparent .p-progress-bar,
.progress-bar-container.transparent .progress-bar {
    background-color: rgba(0, 0, 0, 0.35);
}

.p-progress-bar-container.transparent.title-up .p-progress-bar .progress-title,
.p-progress-bar-container.transparent.title-up .p-progress-bar .progress-number,
.p-progress-bar-container.transparent.title-up .p-progress-bar .progress-type,
.p-progress-bar-container.transparent.title-up .progress-bar .progress-title,
.p-progress-bar-container.transparent.title-up .progress-bar .progress-number,
.p-progress-bar-container.transparent.title-up .progress-bar .progress-type,
.progress-bar-container.transparent.title-up .p-progress-bar .progress-title,
.progress-bar-container.transparent.title-up .p-progress-bar .progress-number,
.progress-bar-container.transparent.title-up .p-progress-bar .progress-type,
.progress-bar-container.transparent.title-up .progress-bar .progress-title,
.progress-bar-container.transparent.title-up .progress-bar .progress-number,
.progress-bar-container.transparent.title-up .progress-bar .progress-type {
    color: #fff;
}

.pie-animated {
    opacity: 1;
}

.pie-chart {
    display: inline-block;
    margin-bottom: 20px;
    margin-top: 50px;
    position: relative;
    text-align: center;
}

.pie-chart canvas {
    position: absolute;
    top: 0;
    left: 0;
}

.pie-chart span,
.pie-chart i {
    display: inline-block;
    font-size: 28px;
    z-index: 2;
    font-weight: 600;
}

.pie-chart i {
    font-size: 38px;
}

.pie-chart span.percent:after {
    content: '%';
    margin-left: 0.1em;
    font-size: .8em;
}

.pie-chart.transparent span,
.pie-chart.transparent span.percent,
.pie-chart.transparent i {
    color: #fff;
}

hr {
    margin-bottom: 10px;
    margin-top: 10px;
    clear: both;
}

hr.space {
    border: none;
    height: 50px;
}

hr.space-xs {
    border: none;
    height: 20px;
}

hr.space-md {
    border: none;
    height: 40px;
}

hr.space-lg {
    border: none;
    height: 80px;
}

hr.space-xlg {
    border: none;
    height: 120px;
}

hr.space-xxlg {
    border: none;
    height: 200px;
}

body.breakpoint-md hr.space {
    height: 26px;
}

body.breakpoint-sm hr.space,
body.breakpoint-xs hr.space {
    height: 16px;
}

body.breakpoint-xs hr.space-lg,
body.breakpoint-sm hr.space-lg {
    height: 40px;
}

body.breakpoint-xs hr.space-xlg,
body.breakpoint-sm hr.space-xlg {
    height: 40px;
}

body.breakpoint-xs hr.space-xxlg,
body.breakpoint-sm hr.space-xxlg {
    height: 80px;
}


/* ----------------------------------------------------------------------
	Seperator
-------------------------------------------------------------------------*/

.seperator,
.separator {
    color: #ccc;
    width: 100%;
    margin: 30px auto;
    overflow: hidden;
    text-align: center;
    line-height: 1.2em;
}

.seperator-simple {
    margin: 14px auto;
}

.seperator::before,
.seperator::after,
.separator::before,
.separator::after {
    border-bottom: 1px solid #eee;
    content: "";
    display: inline-block;
    height: 0.65em;
    margin: 0 -4px 0 -100%;
    vertical-align: top;
    width: 50%;
}

.seperator.seperator-dark::before,
.seperator.seperator-dark::after,
.separator.seperator-dark::before,
.separator.seperator-dark::after {
    border-bottom: 1px solid #333 !important;
}

.seperator.seperator-grey::before,
.seperator.seperator-grey::after,
.separator.seperator-grey::before,
.separator.seperator-grey::after {
    border-bottom: 1px solid #ccc;
}

.seperator.seperator-light::before,
.seperator.seperator-light::after,
.separator.seperator-light::before,
.separator.seperator-light::after {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.seperator::after,
.separator::after {
    margin: 0 -100% 0 0;
}

.seperator i,
.separator i {
    font-size: 18px;
}

.seperator i,
.seperator span,
.seperator a,
.separator i,
.separator span,
.separator a {
    margin: 0 20px 0 24px;
    display: inline-block;
}

.seperator.dotted:before,
.seperator.dotted:after,
.separator.dotted:before,
.separator.dotted:after {
    border-bottom: 2px dotted #ccc;
}

.seperator.seperator-left,
.separator.seperator-left {
    float: left;
    margin: 2px 36px 0 0;
}

.seperator.left i,
.separator.left i {
    float: left;
    margin: 2px 36px 0 0;
}

.seperator.right i,
.separator.right i {
    float: right;
    margin: 0 0 2px 36px;
}

.seperator.seperator-bold:before,
.seperator.seperator-bold:after,
.separator.seperator-bold:before,
.separator.seperator-bold:after {
    border-bottom-width: 6px;
}

.seperator.seperator-small,
.separator.seperator-small {
    width: 10%;
    margin: 5px auto;
}

.seperator.seperator-medium,
.separator.seperator-medium {
    width: 20%;
    margin: 10px auto;
}

.text-seperator {
    background-color: #111;
    display: block;
    height: 4px;
    margin: 20px 0;
}

.text-seperator-small {
    width: 10%;
}

.text-seperator-medium {
    width: 30%;
}

.text-seperator-large {
    width: 60%;
}

.seperator.seperator-image:before,
.seperator.seperator-image:after,
.separator.seperator-image:before,
.separator.seperator-image:after {
    border: 0;
}

.seperator.seperator-image,
.separator.seperator-image {
    background-position: center center;
    background-repeat: repeat-x;
    margin: 0;
    padding: 0;
}

.seperator.seperator-over-top,
.separator.seperator-over-top {
    margin-bottom: -17px;
    position: relative;
    top: -13px !important;
}

.triangle-divider-bottom {
    background-image: url("../images/triangle-divider-down.png");
    background-position: left center;
    background-repeat: repeat-x;
    bottom: 0;
    height: 12px;
    position: absolute;
    width: 100%;
}

.triangle-divider-top {
    background-image: url("../images/triangle-divider-top.png");
    background-position: left center;
    background-repeat: repeat-x;
    top: 0;
    height: 12px;
    position: absolute;
    width: 100%;
}

.spinners {
    position: relative;
}

.spinners:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    border-top: 2px solid #07d;
    border-right: 2px solid transparent;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    -webkit-animation: spinner-rotate .6s linear infinite;
    -moz-animation: spinner-rotate .6s linear infinite;
    -ms-animation: spinner-rotate .6s linear infinite;
    -o-animation: spinner-rotate .6s linear infinite;
    animation: spinner-rotate 0.6s linear infinite;
    width: 1.4rem;
    height: 1.4rem;
    margin-top: -.7rem;
    margin-left: -.7rem;
    border-top-width: 2px;
    border-right-width: 2px;
}

.spinners.spinners--xl:before {
    width: 2.6rem;
    height: 2.6rem;
    margin-top: -1.6rem;
    margin-left: -1.6rem;
    border-top-width: 5px;
    border-right-width: 5px;
}

.spinners.spinners--lg:before {
    width: 2rem;
    height: 2rem;
    margin-top: -1rem;
    margin-left: -1rem;
    border-top-width: 3px;
    border-right-width: 3px;
}

.spinners.spinners--sm:before {
    width: 1rem;
    height: 1rem;
    margin-top: -.5rem;
    margin-left: -.5rem;
    border-top-width: 1px;
    border-right-width: 1px;
}

.spinners.spinners--right:before {
    left: auto;
    right: 0.7rem;
}

.spinners.spinners--right.spinners--lg:before {
    right: 1rem;
}

.spinners.spinners--right.spinners--sm:before {
    right: 0.5rem;
}

.spinners.spinners--right.btn {
    padding-right: 3.1rem;
}

.spinners.spinners--right.btn.spinners--lg {
    padding-right: 4.5rem;
}

.spinners.spinners--right.btn.spinners--sm {
    padding-right: 2.25rem;
}

.spinners.spinners--left:before {
    left: 1.4rem;
}

.spinners.spinners--left.spinners--lg:before {
    left: 2rem;
}

.spinners.spinners--left.spinners--sm:before {
    left: 1rem;
}

.spinners.spinners--left.btn {
    padding-left: 3.1rem;
}

.spinners.spinners--left.btn.spinners--lg {
    padding-left: 4.5rem;
}

.spinners.spinners--left.btn.spinners--sm {
    padding-left: 2.25rem;
}

.spinners:before {
    border-top-color: #dfe2ea;
}

.spinners.spinners--skin-dark:before {
    border-top-color: #acafba;
}

.spinners.spinners--brand:before {
    border-top-color: #716aca;
}

.spinners.spinners--metal:before {
    border-top-color: #c4c5d6;
}

.spinners.spinners--light:before {
    border-top-color: #fff;
}

.spinners.spinners--accent:before {
    border-top-color: #00c5dc;
}

.spinners.spinners--focus:before {
    border-top-color: #9816f4;
}

.spinners.spinners--primary:before {
    border-top-color: #5867dd;
}

.spinners.spinners--success:before {
    border-top-color: #34bfa3;
}

.spinners.spinners--info:before {
    border-top-color: #36a3f7;
}

.spinners.spinners--warning:before {
    border-top-color: #ffb822;
}

.spinners.spinners--danger:before {
    border-top-color: #f4516c;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .spinners:before {
        animation: none !important;
    }
}

@-webkit-keyframes spinner-rotate {
    to {
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner-rotate {
    to {
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner-rotate {
    to {
        transform: rotate(360deg);
    }
}

@keyframes spinner-rotate {
    to {
        transform: rotate(360deg);
    }
}


/* ----------------------------------------------------------------
	Blockquotes
-----------------------------------------------------------------*/

.blockquote {
    border-left: 3px solid #1290c6;
    font-size: 20px;
    padding: 10px 20px;
    margin: 0 0 20px;
}

.blockquote-reverse {
    padding-right: 15px;
    padding-left: 0;
    text-align: right;
    border-right: 5px solid #eee;
    border-left: 0;
}

.blockquote-simple {
    border: 0 none !important;
}

.blockquote-fancy,
.blockquote-color,
.blockquote-dark {
    border: 0 none !important;
}

.blockquote-color {
    background-color: #1290c6;
}

.blockquote-color small {
    color: #ffffff;
}

.blockquote-fancy::before {
    color: rgba(0, 0, 0, 0.04);
    content: '"';
    display: block;
    font-size: 100px;
    left: 4px;
    position: absolute;
    top: 14px;
}

.blockquote-fancy>small {
    float: right;
}

.blockquote-dark {
    background-color: #333;
}

.blockquote-color,
.blockquote-dark {
    padding: 28px;
    color: #ffffff;
}


/* ----------------------------------------------------------------------
Forms
-------------------------------------------------------------------------*/

.form-control {
    border: 1px solid #e6e8eb;
    border-radius: 5px;
}

form .btn {
    padding: 12px 14px;
}

input:not([type="checkbox"]):not([type="radio"]),
select {
    line-height: 18px;
    padding: 10px 16px;
    min-height: 40px;
    font-size: .9rem;
}

.input-group-append {
    margin-left: -4px;
}

.input-group-append .btn,
.input-group-append button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    height: 40px;
}

.input-group-append .btn i,
.input-group-append button i {
    margin-right: 0px;
}

.input-group-prepend .btn,
.input-group-prepend button {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.input-group-prepend .btn i,
.input-group-prepend button i {
    margin-right: 0px;
}

.input-group-prepend+input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.input-group .input-group-btn {
    margin-left: -4px;
}

.input-group .input-group-btn .btn,
.input-group .input-group-btn button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.input-group-lg .btn {
    padding: 16px 20px;
    font-size: 14px;
}

.input-group-text {
    border: 0;
}

.input-group-text i {
    margin-left: 4px;
    margin-right: 4px;
    font-size: 1.2rem;
}

.form-group label:not(.error) {
    font-size: 13px;
    letter-spacing: 0.04em;
    font-weight: 400;
    margin-bottom: 4px;
    color: #777777;
}

.form-inline button {
    margin: 0px;
}

.form-group>label {
    display: block;
}

.has-feedback label~.form-control-feedback {
    top: 44px;
}

.is-invalid:not(.form-control) {
    font-size: .8rem;
    color: #CE041C;
}

.is-invalid,
.is-valid {
    display: block;
    clear: both;
    width: 100%;
}

input[type="checkbox"].form-control {
    float: left;
    width: auto;
    margin-right: 13px;
    height: 24px;
    cursor: pointer;
}

input[type="color"] {
    width: 100%;
    height: 100%;
    min-height: 3px;
    padding: 0;
    border: 0px;
    cursor: pointer;
}

input[type="color"]:focus {
    box-shadow: none;
}


/*form select*/

select.form-control:not([size]):not([multiple]) {
    height: 40px;
}

select {
    width: 100%;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    background-image: url(../images/dropdown-arrow.png) !important;
    background-repeat: no-repeat !important;
    background-position: right center !important;
    border: 1px solid #e6e8eb;
    border-radius: 5px;
}

select[multiple] {
    background-image: none !important;
}

.order-select>h6 {
    margin-bottom: 0;
}

.order-select p {
    font-size: 13px;
}

.button-search {
    background-color: #fff;
    border-color: #e6e8eb #e6e8eb #e6e8eb;
    border-style: solid;
    border-width: 1px 1px 1px 0 !important;
    box-shadow: none;
    margin-left: -6px !important;
    z-index: 2 !important;
}


/* ----------------------------------------------------------------------
 Reservation form
-------------------------------------------------------------------------*/

.reservation-form-over {
    top: -80px;
    z-index: 10;
    margin-bottom: -80px;
    overflow: inherit;
    background-color: transparent;
}

.reservation-form {
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #eee;
    min-height: 160px;
    padding: 30px;
    position: relative;
    z-index: 9999 !important;
}

.reservation-form label {
    color: #555;
}

.reservation-form input,
.reservation-form select {
    border: 0px;
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #e6e8eb;
    width: 100%;
}

.reservation-form .date input {
    border-radius: 4px 0 0 4px;
    border-width: 2px 0 2px 2px;
}

.reservation-form .input-group-addon {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #fff !important;
    border-color: #ddd;
    border-image: none;
    border-style: solid;
    border-width: 2px 2px 2px 0;
}

.reservation-form input,
.reservation-form select {
    color: #555;
    display: block;
    font-size: 15px;
    line-height: 1.42857;
    padding: 8px 14px;
    width: 100%;
}


/*reservation form style 2*/

#book {
    background-color: rgba(0, 0, 0, 0.4);
    border: 10px solid rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    padding: 40px;
}


/* Fixes */

.widget .input-group-text {
    height: 40px;
}

.list-group input[type="radio"] {
    display: none;
}

.list-group input[type="radio"]+.list-group-item {
    cursor: pointer;
}

.list-group input[type="radio"]:checked+.list-group-item {
    background-color: #f8f9fa;
}


/* ----------------------------------------------------------------------
Forms - Dark
-------------------------------------------------------------------------*/

.dark .form-control,
.dark input,
.dark select,
.dark textarea {
    background-color: #222;
    border-color: #333;
    color: #ffffff;
}

.spinner-loader-inside {
    margin-left: -30px;
    margin-top: 6px;
    z-index: 3;
}

.floating-div {
    position: fixed;
    /*       display: flex;
        flex-wrap: nowrap; */
    width: auto;
    background-color: rgba(255, 255, 255, 0.95);
    transition: bottom 0.3s;
    z-index: 9999;
    height: auto;
    padding: 10px;
    color: #333;
    border-top: 1px solid #eee;
    box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    transform: translateX(-50%);
    margin-left: 50%;
    /*    a {
       color: #333;
       width: auto;
       display: block;
       text-align: center;
       font-size: 1.5em;
     } */
    /* 
background-color: #fff;
  border-radius: 6px;
  width: auto;
  border-top: 0 solid;
  position: fixed;
  top: 62%;
  z-index: 99999;
  padding: 9px 12px;
  color: #5F5D6D;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  box-shadow: -4px 0 14px 2px rgba(0, 0, 0, 0.07);
border: 1px solid #efefef;
transition: right 0.3s;
right: -140px;
top: 30%;
width: 140px; */
    /*  a {
       color: #5F5D6D;
     } */
}


/* 
.floating-mobile-menu {
    position: fixed;
    bottom: -61px;
    display: flex;
    flex-wrap: nowrap;
    width: 56%;
    background-color: rgba(255,255,255, 0.95);
    transition: bottom 0.3s;
    z-index: 9999;
    height: 44px;
    padding: 12px;
    color: #333;
    border-top: 1px solid #eee;
    box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, .1);
    border-radius: 6px;
    transform: translateX(-50%);
    margin-left: 50%;
}

.floating-mobile-menu a {
  color: #333;
  width: 33.33%;
  display: block;
  text-align: center;
  font-size: 1.5em;
}

.floating-mobile-menu a span {
  display: none;
} */


/* ----------------------------------------------------------------
Lables & Badgets
-----------------------------------------------------------------*/

.badge {
    font-weight: 600;
}

.a.badge {
    color: #ffffff;
}

.badge-pill {
    padding: 0.4em .8em;
    border-radius: 10rem;
}

.badge-primary {
    background-color: #1290c6;
}


/* ----------------------------------------------------------------------
	Light Box
-------------------------------------------------------------------------*/

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.8;
    filter: alpha(opacity=80);
}

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden;
}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
    display: none;
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto;
}

.mfp-ajax-cur {
    cursor: progress;
}

.mfp-zoom-out-cur {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
}

.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
    cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.mfp-loading.mfp-figure {
    display: none;
}

.mfp-hide {
    display: none !important;
}

.mfp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044;
}

.mfp-preloader a {
    color: #CCC;
}

.mfp-preloader a:hover {
    color: #FFF;
}

.mfp-s-ready .mfp-preloader,
.mfp-s-error .mfp-content {
    display: none;
}

button.mfp-close,
button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    -webkit-box-shadow: none;
    box-shadow: none;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

.mfp-close {
    width: 36px !important;
    height: 36px !important;
    cursor: pointer;
    line-height: 36px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    filter: alpha(opacity=65);
    padding: 0 0 18px 10px;
    color: #FFF;
    font-style: normal;
    font-size: 22px;
}

.mfp-close:hover,
.mfp-close:focus {
    opacity: 1;
    filter: alpha(opacity=100);
}

.mfp-close:active {
    top: 1px;
}

.mfp-close-btn-in .mfp-close {
    color: #a3a3a3;
    font-size: 37px;
    font-weight: 300;
    top: 5px;
    right: 5px;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    color: #FFF;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #CCC;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
}

.mfp-arrow {
    position: absolute;
    opacity: 0.65;
    filter: alpha(opacity=65);
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mfp-arrow:active {
    margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
    opacity: 1;
    filter: alpha(opacity=100);
}

.mfp-arrow:before,
.mfp-arrow:after,
.mfp-arrow .mfp-b,
.mfp-arrow .mfp-a {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent;
}

.mfp-arrow:after,
.mfp-arrow .mfp-a {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px;
}

.mfp-arrow:before,
.mfp-arrow .mfp-b {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7;
}

.mfp-arrow-left {
    left: 0;
}

.mfp-arrow-left:after,
.mfp-arrow-left .mfp-a {
    border-right: 17px solid #FFF;
    margin-left: 31px;
}

.mfp-arrow-left:before,
.mfp-arrow-left .mfp-b {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
    right: 0;
}

.mfp-arrow-right:after,
.mfp-arrow-right .mfp-a {
    border-left: 17px solid #FFF;
    margin-left: 39px;
}

.mfp-arrow-right:before,
.mfp-arrow-right .mfp-b {
    border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
    top: -40px;
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000;
}


/* Main image in popup */

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto;
}


/* The shadow behind the image */

.mfp-figure {
    line-height: 0;
}

.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444;
}

.mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px;
}

.mfp-figure figure {
    margin: 0;
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #F3F3F3;
    word-wrap: break-word;
    padding-right: 36px;
}

.mfp-image-holder .mfp-content {
    max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape),
screen and (max-height: 300px) {
    /**
   * Remove all paddings around the image on small screen
   */
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0;
    }
    .mfp-img-mobile img.mfp-img {
        padding: 0;
    }
    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0;
    }
    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px;
    }
    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0;
    }
    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px;
    }
    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        text-align: center;
        padding: 0;
    }
}

@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
    }
    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0;
    }
    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%;
    }
    .mfp-container {
        padding-left: 6px;
        padding-right: 6px;
    }
}

.mfp-ie7 .mfp-img {
    padding: 0;
}

.mfp-ie7 .mfp-bottom-bar {
    width: 600px;
    left: 50%;
    margin-left: -300px;
    margin-top: 5px;
    padding-bottom: 5px;
}

.mfp-ie7 .mfp-container {
    padding: 0;
}

.mfp-ie7 .mfp-content {
    padding-top: 44px;
}

.mfp-ie7 .mfp-close {
    top: 0;
    right: 0;
    padding-top: 0;
}

.lightbox-open {
    position: relative;
    overflow: hidden !important;
}

.mfp-ajax-holder .mfp-content {
    background: #F8F8F8;
    max-width: 1000px;
}

.mfp-ajax-holder .ajax-quick-view {
    padding: 20px 30px 35px;
}

.mfp-ajax-holder .ajax-quick-view .quick-view-title {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 30px;
    position: relative;
}

.mfp-ajax-holder .ajax-quick-view .quick-view-title h2 {
    font-weight: 600;
}

.mfp-ajax-holder .mfp-close:hover {
    background: #1290c6;
    color: #ffffff;
}


/*settings*/

.portfolio-ajax-page iframe {
    width: 100%;
}

.portfolio-ajax-page .col-lg-8 {
    margin-bottom: -6px !important;
}

@media (max-width: 1024px) {
    .mfp-ajax-holder .mfp-close {
        margin-right: 0;
    }
    .mfp-close {
        font-size: 22px;
        height: 30px;
        line-height: 32px;
        width: 30px;
    }
}

.list {
    clear: both;
    display: block;
    position: relative;
}

.list li {
    line-height: 32px;
}

.list li a {
    color: #484848;
}

.list-lines,
.icon-list,
.list-simple,
.list-posts,
.list ul {
    padding: 0px;
}

.list,
.list-icon,
.list-lines,
.icon-list,
.list-simple,
.list-posts,
.list-tweets li {
    list-style: none;
}

.list a,
.list-icon a,
.list-lines a,
.icon-list a,
.list-simple a,
.list-posts a,
.list-tweets li a {
    color: #484848;
}

.list-colored li a {
    color: #1290c6;
    font-weight: 600;
}

.list-legend li {
    float: left;
    margin-right: 14px;
    margin-bottom: 10px;
}

.list-legend li span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 9px;
    background-color: #1290c6;
    float: left;
    margin-top: 6px;
}

.icon-list li,
.list-arrow-icons li,
.list-tweets li {
    padding-left: 0px;
}

.list-medium li {
    margin: 12px 0;
}

.list-large li {
    margin: 18px 0;
}

.list-large li:first-child,
.list-medium li:first-child {
    margin-top: 0;
}

.list-large li:last-child,
.list-medium li:last-child {
    margin-bottom: 0;
}

.list-posts small {
    display: block;
    font-style: italic;
    opacity: 0.7;
}

.list-lines {
    list-style: none;
}

.list-lines li {
    border-bottom: 1px solid #ddd;
    padding: 10px 0 10px 2px;
}

.list-lines li:last-child {
    border-bottom: 0px;
}

.list-icon {
    list-style: none;
    padding-left: 0;
}

.list-icon ul {
    list-style: none;
}

.list-icon ul {
    padding: 0;
}

.list-icon li {
    margin-bottom: 10px;
    margin-left: 20px;
}

.list-icon i {
    font-size: 15px;
    margin-right: 14px;
    margin-left: -20px;
}

.list-icon.list-icon-colored li:before,
.list-icon.icon-list-colored i {
    color: #1290c6;
}

.list-icon li:before {
    margin-left: -20px;
    position: absolute;
    font-size: 12px;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-weight: 900;
}

.list-icon.list-icon-arrow li:before {
    font-family: "Font Awesome 5 Free";
    content: "\f054";
}

.list-icon.list-icon-arrow-circle li:before {
    content: "\f0a9";
    font-family: "Font Awesome 5 Free";
}

.list-icon.list-icon-check li:before {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
}

.list-icon.list-icon-caret li:before {
    content: "\f0da";
    font-family: "Font Awesome 5 Free";
}

.list-icon.list-icon-uncheck li:before {
    content: "\f144";
    font-family: "Font Awesome 5 Free";
}

.list-icon.list-icon-plus li:before {
    content: "\f055";
    font-family: "Font Awesome 5 Free";
}

.list-icon.list-icon-info li:before {
    content: "\f05a";
    font-family: "Font Awesome 5 Free";
}

.list-icon.list-icon-circle li:before {
    content: "\f192";
    font-family: "Font Awesome 5 Free";
}

a.list-group-item.active {
    background-color: #1290c6;
    border: #1290c6;
}

a.list-group-item.active h1,
a.list-group-item.active h2,
a.list-group-item.active h3,
a.list-group-item.active h4,
a.list-group-item.active h5,
a.list-group-item.active h6,
a.list-group-item.active p {
    color: #ffffff;
}

a.list-group-item.active:hover,
a.list-group-item.active.active {
    color: #ffffff !important;
}


/* ----------------------------------------------------------------
    Maps
-----------------------------------------------------------------*/

.map {
    width: 100%;
    min-height: 460px;
    height: auto;
}

.fullscreen .map,
.fullscreen.map {
    min-height: 100vh;
}

@media (max-width: 1024px) {
    .map {
        min-height: 240px;
    }
    .fullscreen .map,
    .fullscreen.map {
        min-height: 100vh;
    }
}

.animated-dot {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #1290c6;
    position: relative;
    top: -10px;
    right: 8px;
}

.animated-dot:before,
.animated-dot:after {
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #1290c6;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: auto;
    transform: scale(0.5);
    transform-origin: center center;
    animation: pulse-me 3s linear infinite;
}

.animated-dot:after {
    animation-delay: 2.4s;
}

@keyframes pulse-me {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    50% {
        opacity: 0.1;
    }
    70% {
        opacity: 0.09;
    }
    100% {
        transform: scale(16);
        opacity: 0;
    }
}


/* ----------------------------------------------------------------------
	Pagination & Pager
-------------------------------------------------------------------------*/

.pagination-wrap {
    display: block;
    clear: both;
}

.pagination {
    clear: both;
}

.pagination .page-item:not(.disabled)>.page-link {
    color: #484848;
    background-color: #fff;
    border-color: #e6e8eb;
}

.pagination .page-item:not(.disabled).active>.page-link,
.pagination .page-item:not(.disabled):active>.page-link,
.pagination .page-item:not(.disabled):focus>.page-link,
.pagination .page-item:not(.disabled):hover>.page-link {
    background-color: #edf1f6;
    border-color: #dee0e4;
    color: #484848;
    box-shadow: none;
}

.pagination .page-item.disabled>.page-link {
    color: #a2a2a2;
}

.pagination.pagination-flat .page-item>.page-link {
    border: 0;
}

.pagination.pagination-flat .page-item>.page-link:hover {
    color: #484848;
    background-color: #F7F9FB;
}


/*Post Nav*/

.post-navigation {
    border-top: 1px solid #eee;
    display: inline-block;
    list-style: outside none none;
    min-height: 84px;
    padding: 20px 0 0;
    position: relative;
    width: 100%;
    background-color: #ffffff;
}

.post-navigation a {
    color: #484848;
}

.post-navigation .post-next,
.post-navigation .post-prev {
    position: absolute;
    font-size: 16px;
    max-width: 40%;
    height: 44px;
}

.post-navigation .post-next span,
.post-navigation .post-prev span {
    display: block;
    color: #484848;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 0.7;
    margin-bottom: -6px;
    margin-top: 3px;
}

.post-navigation a:hover {
    transition: all 0.3s ease;
    color: #1290c6;
}

.post-navigation .post-next {
    right: 25px;
    text-align: right;
}

.post-navigation .post-next:before {
    content: "\e930";
    font-family: "inspiro-icons";
    font-size: 20px;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    transition: all 0.3s ease;
    right: 10px;
    padding-top: 2px;
}

.post-navigation .post-next:hover:before {
    transform: translate3d(5px, -50%, 0);
}

.post-navigation .post-prev {
    left: 25px;
}

.post-navigation .post-prev:before {
    content: "\e92f";
    font-family: "inspiro-icons";
    font-size: 20px;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    transition: all 0.3s ease;
    padding-top: 2px;
    left: 10px;
}

.post-navigation .post-prev:hover:before {
    transform: translate3d(-5px, -50%, 0);
}

.post-navigation .post-prev-title {
    padding-left: 40px;
}

.post-navigation .post-next-title {
    padding-right: 40px;
}

.post-navigation .post-all {
    font-size: 24px;
    left: auto;
    position: absolute;
    right: 50%;
    text-align: center;
    top: 32px;
    width: 12px;
    opacity: 0.7;
}

.single-post .post-navigation .post-prev {
    left: 0px;
}

.single-post .post-navigation .post-next {
    right: 0px;
}

@media (max-width: 1024px) {
    .post-prev-title,
    .post-next-title {
        display: none;
    }
}


/* ----------------------------------------------------------------------
    Breadcrumbs
-------------------------------------------------------------------------*/

.breadcrumb {
    background-color: transparent;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    display: block;
}

.breadcrumb ul,
.breadcrumb ol {
    display: inline;
    margin: 0;
    padding: 0;
}

.breadcrumb ul .breadcrumb-item,
.breadcrumb ul li,
.breadcrumb ol .breadcrumb-item,
.breadcrumb ol li {
    display: inline;
    position: relative;
    opacity: .8;
}

.breadcrumb ul .breadcrumb-item+li:before,
.breadcrumb ul li+li:before,
.breadcrumb ol .breadcrumb-item+li:before,
.breadcrumb ol li+li:before {
    content: "\e930";
    font-family: "inspiro-icons";
    margin: 0 5px;
    background-color: transparent;
}

.breadcrumb ul .breadcrumb-item a,
.breadcrumb ul li a,
.breadcrumb ol .breadcrumb-item a,
.breadcrumb ol li a {
    color: #484848;
}

.breadcrumb ul .breadcrumb-item:hover,
.breadcrumb ul .breadcrumb-item.active,
.breadcrumb ul .breadcrumb-item:last-child,
.breadcrumb ul li:hover,
.breadcrumb ul li.active,
.breadcrumb ul li:last-child,
.breadcrumb ol .breadcrumb-item:hover,
.breadcrumb ol .breadcrumb-item.active,
.breadcrumb ol .breadcrumb-item:last-child,
.breadcrumb ol li:hover,
.breadcrumb ol li.active,
.breadcrumb ol li:last-child {
    opacity: 1;
}


/*
.navbar {
	.form-control {
		height: 34px;
        padding: 6px 12px;
	}
	.btn {
		border-radius: 0;
        padding: 5px 16px 7px;
	}
}*/


/* ----------------------------------------------------------------------
    call-to-action (call to action)
-------------------------------------------------------------------------*/

.call-to-action {
    position: relative;
    overflow: hidden;
    padding: 46px 50px 34px;
    margin-bottom: 40px;
}

.call-to-action .container {
    position: relative;
}

.call-to-action h3 {
    margin-top: 10px;
    font-weight: 600;
    margin-bottom: 10px;
}

.call-to-action p {
    margin-top: 0;
}

.call-to-action a.btn {
    margin-top: 20px;
}

.call-to-action.call-to-action-border {
    background: #fff;
    border: 1px solid #eee;
}

.call-to-action.call-to-action-colored {
    background-color: #1290c6;
}

.call-to-action.call-to-action-colored h3,
.call-to-action.call-to-action-colored p {
    color: #ffffff;
}

.call-to-action.call-to-action-colored h3 span {
    color: #ffffff !important;
}

.call-to-action.call-to-action-dark {
    background-color: #1f1f1f;
}

.call-to-action.call-to-action-dark h3,
.call-to-action.call-to-action-dark p {
    color: #ffffff;
}

.call-to-action.cta-center {
    text-align: center;
}

.call-to-action.cta-center [class*="col-"] {
    width: 100%;
    max-width: 100%;
    flex: none;
    clear: both;
    display: block;
}

.call-to-action.cta-right {
    text-align: right;
}

.call-to-action.cta-right [class*="col-"]:first-child {
    float: right;
}

@media (max-width: 1024px) {
    .call-to-action {
        padding: 26px 12px 18px;
    }
    [class*="col-"]>.call-to-action {
        padding: 26px 26px 18px;
    }
}

.popover {
    border: 1px solid #e6e8eb;
    border-radius: 6px;
    box-shadow: 0 6px 38px rgba(0, 0, 0, 0.05);
}

.popover-header {
    padding: 18px 20px;
    background-color: transparent;
    border-bottom: 1px solid #e6e8eb;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 0px !important;
}

.popover-body {
    padding: 18px 20px;
    color: #484848;
    font-size: .9rem;
}

a[data-toggle] {
    color: #484848;
}

.tooltip.left .tooltip-arrow {
    right: 1px;
}

.tooltip-inner {
    white-space: pre-wrap;
}

.modal .modal-content .modal-header {
    display: block;
}

.modal .modal-content .modal-header .modal-title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
}

.modal-header .close {
    position: absolute;
    right: 20px;
    top: 14px;
    font-weight: 100;
}

.modal-title {
    display: block;
    font-size: 28px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 20px;
}

.modal p {
    font-size: 15px;
    line-height: 26px;
    margin-top: 0;
    margin-bottom: 13px;
    font-weight: 300;
}


/*Strip*/

.modal-strip {
    background-color: #000;
    bottom: 0;
    color: #ffffff;
    left: 0;
    padding: 20px;
    position: fixed;
    width: 100%;
    display: inline-block;
    transform: translate3d(0px, 200px, 0px);
    transition: all 0.7s ease 0s;
    z-index: 999 !important;
}

.modal-strip .btn,
.modal-strip h5,
.modal-strip h4,
.modal-strip h3,
.modal-strip h2,
.modal-strip h1 {
    margin-bottom: 0 !important;
}

.modal-strip a,
.modal-strip a:hover,
.modal-strip a:active,
.modal-strip a:visited {
    color: #ffffff;
    text-decoration: underline;
}

.modal-strip.modal-top {
    bottom: auto;
    top: 0;
    transform: translate3d(0px, -200px, 0px);
}

.modal-strip.modal-left {
    bottom: auto;
    top: auto;
    left: 0;
    right: auto;
    width: 30%;
    transform: none;
}

.modal-strip.modal-right {
    transform: translate3d(0px, 0px, 0px);
    bottom: auto;
    top: 0;
}

.modal-strip.modal-active {
    transform: translate3d(0px, 0px, 0px);
}

@media (max-width: 1024px) {
    .modal-strip.modal-active .vertical-align {
        top: 0 !important;
        transform: translateY(0px) !important;
    }
}


/* Zoom-out effect*/

.mfp-zoom-out {
    /* start state */
    /* animate in */
    /* animate out */
}

.mfp-zoom-out .mfp-content {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(1.3);
}

.mfp-zoom-out.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
}

.mfp-zoom-out.mfp-ready .mfp-content {
    opacity: 1;
    transform: scale(1);
}

.mfp-zoom-out.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-zoom-out.mfp-removing .mfp-content {
    transform: scale(1.3);
    opacity: 0;
}

.mfp-zoom-out.mfp-removing.mfp-bg {
    opacity: 0;
}

.mfp-ready .modal {
    background: #fff;
    border-radius: 4px;
    display: block;
    margin: 0 auto;
    max-width: 780px;
    padding: 40px;
    position: relative;
    z-index: 1050;
}

.modal .video-wrap video,
.modal .iframe-wrap iframe,
.modal .audio-wrap audio {
    width: 100%;
}

.modal iframe {
    margin-bottom: -10px;
}

.dark .mfp-bg {
    background-color: #181818;
}

.dark .mfp-ready .modal {
    background-color: #181818;
}

.dark .modal-content {
    background-color: #181818;
}

.dark .modal-header {
    border-bottom-color: #444444;
}

.dark .modal-footer {
    border-top-color: #444444;
}


/* ----------------------------------------------------------------------
    Clients Logo
-------------------------------------------------------------------------*/

.client-logo {
    position: relative;
}

.client-logo a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .9;
    transition: all 0.3s ease;
}

.client-logo a>img {
    width: 100%;
    height: auto;
}

.client-logo a:hover {
    opacity: 1;
}

.grid li {
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    opacity: 0.9;
}

.clients-carousel a {
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    opacity: 0.9;
}

.clients-carousel a img {
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    opacity: 0.9;
}

.parallax .clients-carousel a {
    opacity: 1;
}

.grid li:hover {
    opacity: 1;
    cursor: pointer;
}

.clients-carousel a:hover {
    opacity: 1;
    cursor: pointer;
}

.clients-carousel a:hover img {
    opacity: 1;
    cursor: pointer;
}

.client-carousel .polo-carousel-item img,
.clients-logo .polo-carousel-item img {
    padding: 20px;
}

.grid {
    padding: 0;
    list-style: none;
    overflow: hidden;
}

.grid li {
    float: left;
    position: relative;
    width: 20%;
    padding: 40px;
}

.grid.grid-2-columns li,
.grid.grid-2-columns figure {
    width: 50%;
}

.grid.grid-3-columns li,
.grid.grid-3-columns figure {
    width: 33.333333%;
}

.grid.grid-4-columns li,
.grid.grid-4-columns figure {
    width: 25%;
}

.grid.grid-5-columns li,
.grid.grid-5-columns figure {
    width: 20%;
}

.grid.grid-6-columns li,
.grid.grid-6-columns figure {
    width: 16.6666666%;
}

.grid li:hover {
    cursor: pointer;
    background-color: #f8f8f8;
}

.grid li a,
.grid li img {
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.grid li a img {
    width: 100%;
}

.grid li:before,
.grid li:after {
    content: '';
    position: absolute;
}

.grid li:before {
    height: 100%;
    top: 0;
    left: -1px;
    border-left: 1px solid #eee;
}

.grid li:after {
    width: 100%;
    height: 0;
    top: auto;
    left: 0;
    bottom: -1px;
    border-bottom: 1px solid #eee;
}

.grid.clients-grey {
    border: 1px solid #ddd;
}

.grid.clients-grey li {
    background-color: #eee;
}

.grid.clients-grey li:before {
    border-left: 1px solid #ccc;
}

.grid.clients-grey li:after {
    border-bottom: 1px solid #ccc;
}

.grid.clients-border {
    border-width: 10px;
}


/*client options*/


/*carousel logo*/

.carousel-description-clients .flickity-page-dots {
    bottom: -40px !important;
}


/*Clients Responsive li*/

body.breakpoint-md .carousel-description-clients .flickity-button,
body.breakpoint-xs .carousel-description-clients .flickity-button {
    display: none;
}

body.breakpoint-md .grid li,
body.breakpoint-sm .grid li {
    width: 33.33%;
}

body.breakpoint-xs .grid li {
    width: 50%;
}

.client-logos .polo-carousel-item {
    padding: 20px 30px;
}


/* ----------------------------------------------------------------------
	Pricing Table
-------------------------------------------------------------------------*/

.pricing-table {
    padding-top: 50px;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.pricing-table .plan {
    margin-bottom: 60px;
    transition: all .3s ease;
}

.pricing-table .plan:hover {
    margin-top: -10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.pricing-table .plan .plan-header {
    position: relative;
    background-color: #ffffff;
    padding: 40px 22px 22px 22px;
    text-align: center;
}

.pricing-table .plan .plan-header {
    border-width: 1px;
    position: relative;
    text-align: center;
}

.pricing-table .plan .plan-header .plan-price {
    font-size: 70px;
    font-weight: 600;
    position: relative;
    text-align: center;
    padding: 20px 0;
}

.pricing-table .plan .plan-header .plan-price sup {
    font-size: 24px;
    position: relative;
    top: -30px;
    color: #bdc3c7;
}

.pricing-table .plan .plan-header .plan-price span {
    font-size: 16px;
    color: #bdc3c7;
}

.pricing-table .plan-header>h4 {
    margin-bottom: 0;
}

.pricing-table .plan-list {
    background-color: #ffffff;
    border-width: 0 1px 1px;
    padding: 20px 0 40px;
}

.pricing-table .plan-list ul {
    display: table;
    margin: 0 auto;
    padding: 0 40px;
}

.pricing-table .plan-list ul li {
    list-style: none;
    border-bottom: 1px solid #EAECEB;
    line-height: 42px;
}

.pricing-table .plan-list ul li:last-child {
    border: none;
}

.pricing-table .plan-list ul li i {
    margin-right: 12px;
}

.pricing-table .plan-list ul li span {
    color: #34495e;
}

.pricing-table .plan-list .plan-button {
    margin-top: 20px !important;
    text-align: center;
}

.pricing-table .plan.featured {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    margin-top: -30px;
    padding-bottom: 0;
}

.pricing-table .plan.featured .plan-list {
    padding: 20px 0 40px;
}

.pricing-table .plan .plan-featured-item {
    background-color: #f8f8f8;
    border: 1px solid #e3e3e3 !important;
    font-weight: 600;
    margin-top: 21px;
    padding: 0 20px;
}

.pricing-table .plan .btn,
.plan .button {
    margin: 0;
}

.pricing-table.colored .plan-header {
    background-color: #1290c6;
    border-color: rgba(0, 0, 0, 0.05);
    color: #fff;
}

.pricing-table.colored .text-muted {
    color: #fff !important;
}

.pricing-table.colored .plan-header h4,
.pricing-table.colored .plan-header .plan-price sup,
.pricing-table.colored .plan-header .plan-price span {
    color: #fff;
}

.pricing-table.transparent .plan-header,
.pricing-table.transparent .plan-list {
    background-color: rgba(0, 0, 0, 0.4);
    border-color: rgba(0, 0, 0, 0.6);
}

.pricing-table.transparent .plan-list ul li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.pricing-table .plan .plan-featured-item {
    background-color: transparent;
}

.section-grey .pricing-table .plan-list {
    background-color: #f8f8f8;
}

section .pricing-table.full-width {
    padding-left: 30px;
    padding-right: 30px;
}

body.breakpoint-xl .pricing-table .col-lg-2,
body.breakpoint-lg .pricing-table .col-lg-2 {
    width: 20%;
}

body.breakpoint-xl .pricing-table .col-lg-2 .plan-list ul li,
body.breakpoint-lg .pricing-table .col-lg-2 .plan-list ul li {
    font-size: 13px;
}

.dark .table,
.table.dark {
    color: #999999;
}


/* ----------------------------------------------------------------
    Testimonial
-----------------------------------------------------------------*/

.testimonial .testimonial-item {
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    padding: 46px;
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
}

.testimonial .testimonial-item>img {
    border-radius: 50%;
    display: block;
    float: none;
    height: 72px;
    margin: 0 auto 10px;
    width: 72px;
}

.testimonial .testimonial-item>p {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 0;
    padding-bottom: 20px;
    font-weight: 400;
    margin-top: 10px;
}

.testimonial .testimonial-item>span {
    font-weight: 600;
    font-size: 15px;
    display: block;
    line-height: 22px;
}

.testimonial .testimonial-item>span~span {
    font-weight: 500;
    color: #bbbbbb;
    display: block;
    font-size: 13px;
    font-style: italic;
}

.testimonial.testimonial-box .testimonial-item {
    padding: 46px;
    background-color: #ffffff;
    border-radius: 8px;
}

.testimonial.testimonial-box .testimonial-item p {
    font-size: 14px;
}

.testimonial.testimonial-single .testimonial-item {
    padding: 30px 80px;
    background-color: transparent;
    border: 0;
}

.testimonial.testimonial-single .testimonial-item>img {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
}

.testimonial.testimonial-single .testimonial-item p {
    font-size: 20px;
}

.testimonial.testimonial-left .testimonial-item {
    text-align: left;
}

.testimonial.testimonial-left .testimonial-item>img {
    display: inherit;
    float: left;
    margin: 0 0 40px 0;
    height: 160px;
    width: 160px;
}

.testimonial.testimonial-left .testimonial-item>p,
.testimonial.testimonial-left .testimonial-item>span {
    margin-left: 220px;
}

.testimonial.testimonial-blockquote .testimonial-item>p {
    font-style: italic;
    font-size: 24px;
    line-height: 32px;
    padding: 30px 10px;
}

[data-arrows="false"].testimonial-single.testimonial-blockquote .testimonial-item {
    padding: 0 !important;
}

[data-arrows="false"].testimonial-single.testimonial-blockquote .testimonial-item>p {
    padding: 10px !important;
}

.widget .testimonial .testimonial-item {
    text-align: left;
    padding: 0;
}

.widget .testimonial .testimonial-item>img {
    display: inherit;
    float: left;
    margin: 0 0 40px 0;
    height: 48px;
    width: 48px;
}

.widget .testimonial .testimonial-item>p {
    font-size: 15px;
    padding-bottom: 14px;
}

.widget .testimonial .testimonial-item>p,
.widget .testimonial .testimonial-item>span {
    margin-left: 68px;
}

.widget .testimonial .testimonial-item>span {
    font-size: 13px;
}


/* ----------------------------------------------------------------
    Testimonial - Responsive
-----------------------------------------------------------------*/

@media (max-width: 1024px) {
    .testimonial.testimonial-left .testimonial-item {
        text-align: left;
    }
    .testimonial.testimonial-left .testimonial-item>img {
        display: inherit;
        float: none;
        margin: 20px auto;
        height: 80px;
        width: 80px;
    }
    .testimonial.testimonial-left .testimonial-item>p,
    .testimonial.testimonial-left .testimonial-item>span {
        margin-left: 0;
        text-align: center;
    }
    .testimonial.testimonial-single .testimonial-item {
        padding: 10px 20px;
    }
    .testimonial.testimonial-single .testimonial-item>img {
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
    }
    .testimonial.testimonial-single .testimonial-item p {
        font-size: 14px;
    }
}

.timeline {
    list-style-type: none;
    position: relative;
}

.timeline:before {
    content: '';
    position: absolute;
    left: 16px;
    height: 100%;
    z-index: 2;
    top: 0;
    bottom: 0;
    border-left: 3px dashed #e3e6f0;
}

.timeline .timeline-item {
    margin: 3rem 2rem 3rem 5rem;
}

.timeline .timeline-item>h4 {
    margin-bottom: 0;
}

.timeline .timeline-item .timeline-item-date {
    font-size: .9rem;
    margin-bottom: 30px;
}

.timeline .timeline-item .timeline-item-image {
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 30px;
}

.timeline .timeline-item .timeline-item-image img {
    width: 100%;
    height: auto;
}

.timeline .timeline-item .timeline-icon {
    background-color: #1290c6;
    position: absolute;
    border-radius: 50%;
    left: 0px;
    width: 34px;
    height: 34px;
    z-index: 3;
    color: #ffffff;
    text-align: center;
    padding: 5px;
    font-size: 18px;
}

.timeline .timeline-item .timeline-icon i {
    margin-top: 1px;
}

#youtube-background-controls {
    display: none;
}

.youtube-background {
    background-color: #181818;
}

.ytplayer-container {
    background-color: #181818;
    position: absolute;
    z-index: -2;
    transform: scale(1.2);
}

.background-video {
    background-color: #181818;
    background-position: top center;
    background-repeat: no-repeat;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
}

.background-video video,
.background-video source {
    bottom: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
}

.loaded .ytplayer-container {
    display: block;
}

.loaded .placeholder-image {
    opacity: 0;
}

.ytplayer-container {
    bottom: 0;
    height: 100%;
    left: 0;
    min-width: 100%;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
}

.placeholder-image {
    height: 100%;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    position: fixed;
    top: 0;
    z-index: 1;
}

.ytplayer-shield {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
}

.ytplayer-player {
    position: absolute;
}

.html5vid {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    opacity: 0;
    transition: opacity .5s linear;
}

.html5vid:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-color: #181818;
}

.html5vid video {
    max-width: none !important;
    border: none;
    object-fit: cover;
    background-size: cover;
    opacity: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0%;
    display: block;
}

.html5vid.video-loaded {
    opacity: 1;
}

video {
    width: 100%;
}

.bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    z-index: 0;
    background: rgba(0, 0, 0, 0.59);
    opacity: .5;
}

.bg-overlay[data-style="1"] {
    background: #fff;
}

.bg-overlay[data-style="2"] {
    background: #1290c6;
    opacity: .9;
}

.bg-overlay[data-style="3"] {
    background: linear-gradient(140deg, #7c1bd6 0%, #31049b 100%);
    opacity: .9;
}

.bg-overlay[data-style="4"] {
    background: linear-gradient(140deg, rgba(236, 0, 83, 0.979) 0%, rgba(231, 46, 0, 0.71) 100%);
    opacity: .9;
}

.bg-overlay[data-style="5"] {
    background: radial-gradient(circle at left top, #09c8e6 20%, #1290c6 38%, #5839e4 66%);
    opacity: .9;
}

.bg-overlay[data-style="6"] {
    background: radial-gradient(circle at left top, #FE68BB 38%, #4294FA 66%);
    opacity: .9;
}

.bg-overlay[data-style="7"] {
    background: radial-gradient(circle at right top, #c4d622 20%, #93d839 38%, #4294FA 66%);
    opacity: .9;
}

.bg-overlay[data-style="8"] {
    background: linear-gradient(180deg, #e02b20 0%, #720462 100%);
    opacity: .9;
}

.bg-overlay[data-style="9"] {
    background: radial-gradient(circle at center, #00a6c0 0%, #0c71c3 100%);
    opacity: .9;
}

.bg-overlay[data-style="10"] {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.99) 100%);
    opacity: 1;
}

.bg-overlay[data-style="11"] {
    background: linear-gradient(90deg, #0090f0 0%, #5d08e6 100%);
    opacity: .9;
}

.bg-overlay[data-style="12"] {
    background: linear-gradient(to bottom, #ba01ff 0, #ff0092 100%);
    opacity: .8;
}

.bg-overlay~.container,
.bg-overlay~.container-fluid {
    position: relative;
    z-index: 2;
}

.box-fancy>.container {
    padding: 0 30px;
}

.box-fancy [class*="col-"] {
    padding: 5% !important;
}

.box-fancy.fancy-box-over {
    top: -100px;
}

body.breakpoint-xs .box-fancy [class*="col-"] {
    padding: 10% !important;
}

.over-slider-top {
    top: -100px;
}

.site-map i {
    margin-right: 8px;
}

.site-map ul li {
    padding-bottom: 2px;
}

.animated.rounded {
    animation-fill-mode: none;
}

.animated.infinite {
    animation-duration: 3s;
    animation-fill-mode: both;
}

[data-animate] {
    opacity: 0;
}

[data-animate].visible {
    opacity: 1;
}

.icon[data-animate] {
    opacity: 1;
}

.rateit,
.rateit-font .rateit-range {
    overflow: hidden !important;
}

.rateit,
.rateit-font .rateit-range>div,
.rateit>div {
    cursor: pointer !important;
}

.rateit-selected {
    color: #FFC400 !important;
}

.rateit-reset {
    display: none !important;
}


/* ----------------------------------------------------------------------
	Parallax
-------------------------------------------------------------------------*/

#page-title[data-bg-parallax],
[data-bg-parallax] {
    background-color: #181818;
}

.parallax-container {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -100;
    opacity: 0;
    transition: opacity .5s linear;
}

.parallax-container.parallax-responsive {
    background-size: cover !important;
    background-attachment: scroll !important;
    background-position: center center !important;
}

.img-loaded {
    opacity: 1;
}

[data-bg-image] {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

[data-bg-image]::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-color: #181818;
    transition: opacity .5s linear;
}

[data-bg-image].bg-loaded::before {
    opacity: 0;
}

body[data-bg-image] {
    background-attachment: fixed;
    background-position: top center;
}

.grid-aliment-wrap {
    margin-left: 30rem;
}

.gif-log {
    padding-top: 19rem;
    text-align: center;
}


.banner-gif {
    height: 10rem;
}

@media (max-width: 576px) {
    .wizard>.steps ul {
        flex-direction: column;
    }
    .wizard>.steps ul li {
        flex: 1;
        margin-bottom: 10px;
    }
    #topbar .top-menu {
        width: 100% !important;
        float: none !important;
        padding-top: 0px !important;
        margin-top: 0px !important;
        text-align: center !important;
        padding: 0px !important;
        margin: 0px !important;
    }
    #leaderboard {
        padding: 0px;
    }
}

.card .card-body .wizard {
    padding: 1rem;
}

.upper {
    text-transform: uppercase;
}

.divcenter {
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1020px) {
    .desktop {
        display: none;
    }
}

@media (min-width: 1024px) {
    .desktop {
        margin-left: 60px;
        color: #008dde;
        font-weight: 400;
    }
}