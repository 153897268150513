/* Codepen Specific */

* {
    margin: 0;
    padding: 0;
    font-family: Helvetica, Arial, sans-serif;
}

body {
    background: #232323;
}

input[type=text],
textarea,
input[type=email],
input[type=password],
input[type=tel],
input[type=url],
input[type=search],
input[type=date] {
    padding: 10px;
    width: 100%;
    font-size: 14px;
    font-family: inherit;
    line-height: 24px;
    color: #555;
    background-color: #f1f1f1;
    border: none;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
}

input[type=text],
textarea,
input[type=email],
input[type=password],
input[type=tel],
input[type=url],
input[type=search],
input[type=date],
.material.woocommerce-page[data-form-style="default"] input#coupon_code {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 2px solid rgba(0, 0, 0, 0);
}


/* /end Codepen Specific */


/* Ladder Navigation */

#leaderboard {
    padding: 50px;
    background-color: transparent;
}

.ladder-nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: #5055CB;
    padding: 10px 25px;
    border-radius: 10px;
    border: 1px solid #686EF9;
}

.ladder-title {
    flex: 2;
}

.ladder-search {
    flex: 1;
    padding-right: 25px;
}

.ladder-title {
    font-size: 20px;
    color: #888DFF!important;
    font-weight: bold;
    text-transform: uppercase;
}

.ladder-search input {
    color: white
}

.ladder-search input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #888DFF;
}

.ladder-search input::-moz-placeholder {
    /* Firefox 19+ */
    color: #888DFF;
}

.ladder-search input:-ms-input-placeholder {
    /* IE 10+ */
    color: #888DFF;
}

.ladder-search input:-moz-placeholder {
    /* Firefox 18- */
    color: #888DFF;
}


/* /end Ladder Navigation */


/* Leaderboard Results */

.leaderboard-results {
    text-align: center;
    border-collapse: collapse;
    opacity: .7;
    /* background-color: #191616; */
    background-color: #35092d;
}

.leaderboard-results thead th {
    padding: 25px;
    color: #e6e6e6;
    font-size: 14px;
    font-weight: bold;
}

.leaderboard-results tbody td {
    padding: 15px 25px;
    font-size: 16px;
    border-bottom: 1px solid #ffffff;
}

.leaderboard-results tbody td:nth-of-type(7) {
    font-weight: bold;
}

.leaderboard-results tbody tr:hover td {
    background: #35092d;
}

.leaderboard-results tbody tr {
    color: #ffffff;
}

.leaderboard-results tbody tr:first-child {
    color: #e7e7e7;
}

.leaderboard-results tbody span {
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 50%;
}

.leaderboard-results tbody tr:first-child span {
    background: #5055CB;
    color: white;
}

.leaderboard-results tbody tr span {
    background: #ebe9e9;
    color: #ffffff;
}

.tabble-wrap {
    border-bottom: 1px solid #fbfbfb;
}


/* /end Leaderboard Results */